import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PaymentScheduleEntry from "./PaymentScheduleEntry";
import { useAuthDataContext } from "../../../../authProvider";
import MobileNavBar from "../../../../components/mobile/MobileNavBar";

import "../scss/PaymentPlanMobile.css";

const PaymentPlanActivityMobile = () => {
  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;

  const PaymentSchedule = () => {
    let comps = [];
    let sorted = financials.amortizationActivity
      .slice(0)
      .sort((a: any, b: any) => {
        // @ts-ignore
        return new Date(b.eventDate) - new Date(a.eventDate);
      });
    for (let i = 0; i < sorted.length; i++) {
      comps.push(
        <PaymentScheduleEntry key={`Entry-${i}`} index={i} entry={sorted[i]} />
      );
    }
    return comps;
  };

  return (
    <>
      <MobileNavBar />
      <Container className="payment-plan-mobile-container2">
        <Row className="py-3">
          <Col>
            <h1>Payment Activity</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{financials.paymentPlanName}</h2>
            <p className="app-id">{user.applicationId}</p>
          </Col>
        </Row>
        <Row>
          <Col className="scroll-me">{PaymentSchedule()}</Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentPlanActivityMobile;
