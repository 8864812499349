import React from "react";
import VisaLogo from "../Wallet/VisaLogo";
import MasterCardLogo from "../Wallet/MasterCardLogo";
import AmexLogo from "../Wallet/AmexLogo";
import DiscoverLogo from "../Wallet/DiscoverLogo";

const CcLogo = ({ cardType }: any) => {

  const displayCardLogo = (cardName: string) => {
    if (cardName === "VISA") {
      return <VisaLogo/>;
    } else if (cardName === "MASTER") {
      return <MasterCardLogo/>;
    } else if (cardName === "DISC") {
      return <DiscoverLogo/>;
    } else {
      return <AmexLogo/>;
    }
  };

  return displayCardLogo(cardType);
};

export default CcLogo;
