import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  classes: string;
  size: string;
}

const Sidebar = ({
  children,
  size = "medium",
  classes = "hfd-sidebar",
}: IProps) => {
  return <div className={classes + " " + size}>{children}</div>;
};

export default Sidebar;
