import React from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Button, Card, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getCardType } from "@hfd/lib/helpers/cardUtilities";
import VisaLogo from "@hfd/components/Wallet/VisaLogo";
import AmexLogo from "@hfd/components/Wallet/AmexLogo";
import DiscoverLogo from "@hfd/components/Wallet/DiscoverLogo";
import MasterCardLogo from "@hfd/components/Wallet/MasterCardLogo";

const PaymentConfirmationWidget = (props: any) => {
  const SpecificPaymentMessage = () => {
    if (props.paymentStatus === "ACT") {
      return (
        <div>
          <IntlMessages id="payment.TowardsPrincipal" />
        </div>
      );
    }
    if (props.amountPastDue < props.paymentAmountSelected) {
      return (
        <div>
          <IntlMessages id="payment.Satsifies" />
        </div>
      );
    } else {
      return (
        <div>
          <IntlMessages id="payment.DoesNotSatisfy" />
        </div>
      );
    }
  };

  const getCardLogo = () => {
    let cardType = getCardType(props.selectedCard.cardNumber);
    switch (cardType) {
      case "VISA":
        return <VisaLogo />;
      case "AMEX":
        return <AmexLogo />;
      case "DISCOVERY":
        return <DiscoverLogo />;
      case "MASTER":
        return <MasterCardLogo />;
      default:
        return <div>{cardType}</div>;
    }
  };

  return (
    <div>
      <Card className="hfd-widget-card padding-1">
        <Row>
          <Col md={12}>
            <h6 className="card-widget-title">
              <div className="span blueme">
                <IntlMessages id="makePayment.StepThree" />
              </div>
              <IntlMessages id="payment.PaymentConfirmation" />
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="payment-confirmation-row">
              <div className="pcr-heading">
                <span className="pcr-h-icon">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <span className="pcr-h-text">Amount</span>
              </div>
              <div className="pcr-detail no-detail">
                <span className="pcrd-title">
                  ${props.paymentAmountSelected}
                </span>
                <span className="pcrd-message">
                  <SpecificPaymentMessage />
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="payment-confirmation-row">
              <div className="pcr-heading">
                <span className="pcr-h-icon">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <span className="pcr-h-text"> Method</span>
              </div>
              <div className="pcr-detail no-detail">
                <span className="pcrd-title">
                  <span className="pcrd-title-cardlogo">{getCardLogo()}</span>{" "}
                  <IntlMessages id="payment.EndingIn" />{" "}
                  {props.selectedCard.cardNumber.substring(
                    props.selectedCard.cardNumber.length - 4
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="payment-confirmation-row">
              <div className="pcr-heading">
                <span className="pcr-h-icon">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <span className="pcr-h-text"> Date</span>
              </div>
              <div className="pcr-detail no-detail">
                <span className="pcrd-title">
                  <Moment format="MMM Do, YYYY">{new Date()}</Moment>
                </span>
                <span className="pcrd-message">
                  <IntlMessages id="payment.SubmitPaymentBy" />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="buttons-container">
        <Button
          className="right success"
          onClick={() => props.processPayment(true)}
        >
          Process Payment Success
        </Button>
        <Button
          className="right fail"
          onClick={() => props.processPayment(false)}
        >
          Process Payment Fail
        </Button>
        <Button
          className="right hollow back"
          onClick={() => props.onBackClick()}
        >
          <IntlMessages id="generic.Back" />
        </Button>
      </div>
    </div>
  );
};

export default PaymentConfirmationWidget;
