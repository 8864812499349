import React from "react";
import { Config } from '../../ClientConfig';

import "../styles/navigation/desktopNavBar.css";

const OTPSingleNavBar = () => {

  const goHome = () => {
    window.location.href = Config.Integrations.CustomerPortalUrl;
  };

  return (
      <div className="mobile-box desktop-nav">
        <div className="mobile-logo" onClick={goHome}>
              <img alt="HFD Logo" src="https://global-content-cf.s3.us-west-2.amazonaws.com/Logos/Web_Logos/PNG/HFD-Logo-Full-white.png" />
        </div>
      </div>
  );
};

export default OTPSingleNavBar;
