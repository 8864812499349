import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAuthDataContext } from "../../../../authProvider";
import MobileNavBar from "../../../../components/mobile/MobileNavBar";

import "../scss/PaymentPlanMobile.css";

const PaymentDocumentMobile = () => {
  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;

  return (
    <>
      <MobileNavBar />
      <Container className="payment-plan-mobile-container2">
        <Row className="py-3">
          <Col>
            <h1>View Documents</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{financials.paymentPlanName}</h2>
            <p className="app-id">{user.applicationId}</p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="outline-link-button">
              <div className="olb-title">Credit Report</div>
              <div className="olb-icon-button">
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="outline-link-button">
              <div className="olb-title">Installment Agreement</div>
              <div className="olb-icon-button">
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentDocumentMobile;
