import Styled from 'styled-components';

const Styles = Styled.div`
  .navbar {
    background-color: var(--primary);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 2rem 2rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }
  .navbar-nav {
    width: 100%;
  }
  .navbar-nav hr {
    border: 1px solid rgba(255, 255, 255, 0.14);
    width: 100%;
  }
  .nav-link {
    color: white !important;
    display: flex;
    width: 100%;
    border-radius: 20px;
    padding: 1rem 1.5rem !important;
    align-items: center;
  }
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.14);
  }
  .nav-link ion-icon {
    margin-right: 1rem;
    font-size: 1.3rem;
  }
`;

export default Styles;
