import React, { MouseEventHandler, useState } from "react";
import "./SlideOutSidebar.css";

interface IProps {
  children: JSX.Element;
  show: Boolean;
  handleDrawer: MouseEventHandler;
}

const SlideOutSidebar = ({ children, show, handleDrawer }: IProps) => {
  let drawerClasses = "side-bar";
  let containterClasses = "side-bar-container";
  if (show) {
    containterClasses = "side-bar-container open";
    drawerClasses = "side-bar open";
  }

  return (
    <>
      <div className={containterClasses} onClick={handleDrawer}></div>
      <div className={drawerClasses}>
        <div className="side-bar-header d-flex align-items-center">
          <div className="side-bar-title">
            <h2>Update Password</h2>
          </div>
          <div>
            <button
              type="button"
              onClick={handleDrawer}
              className="side-bar-close-button d-flex align-items-center"
            >
              <ion-icon name="close"></ion-icon>
            </button>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default SlideOutSidebar;
