import React from "react";

type Props = {
  size: string;
};

const HFDBarLogo = ({ size = "full" }: Props) => (
  <div
    className={
      size === "full"
        ? "hfd-bar-loader-container full"
        : "hfd-bar-loader-container"
    }
  >
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
  </div>
);

export default HFDBarLogo;
