import React, { lazy } from 'react';

import { Nav, Row, Col, Container, Button } from 'react-bootstrap';
import DashboardRoutes from './DashboardRoutes';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { LOCAL_APP_STATE } from '../../localState/queries';
import { useAuthDataContext } from '../../authProvider';

const Navigation = lazy(() => import('@hfd/components/Navigation/Navigation'));

export default function AuthenticatedUser() {
  const { loading, data } = useQuery(LOCAL_APP_STATE);
  const { onLogout } = useAuthDataContext();
  const isMobile = matchMedia('(max-width: 620px)').matches;

  if (loading) {
    return <div>Loading </div>;
  }

  if (data.localAppState.isMobile) {
    return (
      <>
        <DashboardRoutes />
      </>
    );
  }

  return (
    <Container fluid>
      <Row noGutters>
        <Col md={2}>
          {!isMobile && (
            <Navigation>
              <Nav.Item>
                <Nav.Link as={Link} to="/user/dashboard">
                  <ion-icon name="home" />
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/user/wallet">
                  <ion-icon name="wallet" />
                  Wallet
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/user/paymentplan">
                  <ion-icon name="list-circle" />
                  Payment Plan
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/user/profile">
                  <ion-icon name="person-circle" />
                  Profile
                </Nav.Link>
              </Nav.Item>
              <hr />
              <Nav.Item>
                <Nav.Link as={Link} to="/user/settings">
                  <ion-icon name="settings"></ion-icon>
                  Settings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/user/support">
                  <ion-icon name="headset"></ion-icon>
                  Support
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Button onClick={onLogout}>Sign Out</Button>
              </Nav.Item>
            </Navigation>
          )}
        </Col>
        <Col md={10} className="main-body-content">
          <DashboardRoutes />
        </Col>
      </Row>
    </Container>
  );
}
