import { useQuery } from "@apollo/react-hooks";
import HfdDropdownCard from "@hfd/components/DropdownCard/HfdDropdownCard";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import OrdinalSuffix from "@hfd/components/utility/OrdinalSuffix";
import React, { useEffect, useState } from "react";
import { useAuthDataContext } from "../../../../authProvider";
import { LOCAL_APP_STATE } from "../../../../localState/queries";
import OneTimePaymentPromoBanner from "./OneTimePaymentPromoBanner";

const OneTimePaymentAccountDetails = (props: any) => {
  const { user } = useAuthDataContext();
  const { data } = useQuery(LOCAL_APP_STATE);
  const [paymentStatus, setPaymentStatus] = useState('current');
  const [isActive] = useState(!!(props.isDashboard));

  useEffect(() => {
    switch (user.paymentStatus) {
      case 'PCOL': setPaymentStatus('pastDueRed'); break;
      case 'DEL': setPaymentStatus('pastDueRed'); break;
      case 'LATE': setPaymentStatus('pastDueOrange'); break;
    }
  }, [user.paymentStatus]);

  if (!user) {
    props.cancel();
    return <></>;
  }

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case 'current': return (<IntlMessages id="otp.detailsHeader" />);
      case 'pastDueRed': return (<IntlMessages id="otp.PaymentWillBeAppliedPastDue" />);
      case 'pastDueOrange': return (<IntlMessages id="otp.PaymentWillBeAppliedPastDue" />);
    }
  };

  const renderUhohMessage = () => {
    if ((parseFloat(data.localAppState.userData.paymentAmount) < parseFloat(user.totalAmountDue)) && paymentStatus !== 'current') {
      return (
          <div className={'not-enough'}>
            <IntlMessages id="otp.paymentNotEnough" />
          </div>
      );
    }
    else if (parseFloat(data.localAppState.userData.paymentAmount) > parseFloat(user.amountFinanced)) {
      return (
          <div className={'not-enough'}>
            <IntlMessages id="otp.paymentTooMuch" />
          </div>
      );
    }
    else {
      return ( <></> );
    }
  };

  return (
      <HfdDropdownCard status={paymentStatus} isActive={isActive} footer={{content: <OneTimePaymentPromoBanner/>}}>
        {renderUhohMessage()}
        <div className="ddc-body-details">
          <ul>
            <li>
              <span className="left-side" style={{textAlign: 'center', fontSize: '14px'}}>
                {renderPaymentStatus()}
              </span>
            </li>
          </ul>
          <hr/>
          <ul className={'payment-detail-row'}>
            <li>
              <IntlMessages id="otp.TotalAmountDue" />{" "}
              <FormattedCurrency amount={user.totalAmountDue}/>
            </li>
            {(user.dayOfPayment) > 0 && <li>
              <IntlMessages id="otp.NextPaymentDue" />{" "}
                <OrdinalSuffix numberToFormat={user.dayOfPayment}/>
            </li>}
            {(user.monthlyPayment > 0) && <li>
              <IntlMessages id="otp.MonthlyPayment" />{" "}
                <FormattedCurrency amount={user.monthlyPayment}/>
            </li>}
          </ul>
          <hr/>
          <ul className={'payment-detail-row'}>
            <li>
              <IntlMessages id="otp.AmountFinanced" />{" "}
              <FormattedCurrency amount={user.amountFinanced}/>
            </li>
            <li>
              <IntlMessages id="otp.PrincipalBalance" />{" "}
              <FormattedCurrency amount={user.principalBalance}/>
            </li>
            <li>
              <IntlMessages id="otp.PaymentsRemaining" /> {user.paymentsRemaining}
            </li>
          </ul>
        </div>
      </HfdDropdownCard>
  );
};

export default OneTimePaymentAccountDetails;
