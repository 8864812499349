import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MobileNav from "../../../components/mobile/MobileNavBar";
import IntlMessages from "@hfd/components/utility/IntlMessages";

import "./scss/walletMobile.css";

const WalletMobile = ({ DisplayCards }: any) => {
  return (
    <Container className="wallet-mobile-container">
      <MobileNav />
      <Row className="mt-4">
        <Col className="mt-4">
          <h2>
            <IntlMessages id="wallet.Title" />
          </h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>{DisplayCards()}</Col>
      </Row>
    </Container>
  );
};

export default WalletMobile;
