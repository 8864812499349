import React from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Title from "@hfd/components/utility/pageTitle";

import { Button, Col, Container, Row } from "react-bootstrap";

const WalletDesktop = ({ DisplayCards, DisplayAch }: any) => {
  return (
    <Container fluid className="no-padding px-5">
      <Row noGutters className="mb-4">
        <Col sm={12} xs={12} md={10} lg={10}>
          <Title>
            <h1 className="page-title">
              <IntlMessages id="wallet.Title" />
            </h1>
            <p className="page-subtitle">
              <IntlMessages id="wallet.TitleDescription" />
            </p>
          </Title>
        </Col>
        <Col sm={12} xs={12} md={2} lg={2}>
          <Button className="hfd-outline-button">
            <IntlMessages id="wallet.Add" />
            &nbsp; +
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={12}>
          <p className="hfd-row-title">
            <IntlMessages id="wallet.CardsTitle" />
          </p>
        </Col>
      </Row>

      <Row className="horizontalScroll" xs={1} sm={2} md={3}>
        {DisplayCards()}
      </Row>
      <br />
      <Row>
        <Col sm={12}>
          <p className="hfd-row-title">
            <IntlMessages id="wallet.BankAccountTitle" />
          </p>
        </Col>
      </Row>

      <Row>{DisplayAch()}</Row>
    </Container>
  );
};

export default WalletDesktop;
