import React from "react";
import Card from "@hfd/components/utility/card";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DashboardQuickActions = () => {
  return (
    <Card title="Quick Actions">
      <Row noGutters sm={1}>
        <Col className="quick-actions">
          <NavLink to="/">
            <p>Add Payment Method</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </NavLink>
        </Col>
        <Col className="quick-actions">
          <NavLink to="/">
            <p>Reduce Term</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </NavLink>
        </Col>
        <Col className="quick-actions">
          <NavLink to="/">
            <p>Change Payment Date</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </NavLink>
        </Col>
        <Col className="quick-actions">
          <NavLink to="/">
            <p>Edit Contact Info</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </NavLink>
        </Col>
      </Row>
    </Card>
  );
};

export default DashboardQuickActions;
