// @ts-ignore
import React from "react";
import "./scss/radio.css";

interface IProps {
  id: string;
  label: string;
  onChange: any;
  name: string;
  value: any;
}

const HfdRadio = ({ id, label, onChange, name, value }: IProps) => {
  return (
    <>
      <div className="radio">
        <input
          type="radio"
          id={id}
          onChange={onChange}
          name={name}
          value={value}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </>
  );
};

export default HfdRadio;
