import React, { useState } from "react";
import { useAuthDataContext } from "../../../authProvider";
import { LOCAL_APP_STATE } from "../../../localState/queries";
import { useQuery } from "@apollo/react-hooks";

import PaymentScheduleEntry from "./Components/PaymentScheduleEntry";
import PaymentPlanMobile from "./PaymentPlanMobile";
import PaymentPlanDesktop from "./PaymentPlanDesktop";

import "./scss/PaymentPlan.css";

const PaymentPlan = (props: any) => {
  const { data } = useQuery(LOCAL_APP_STATE);

  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;
  const [isScrollDownActive, setScrolldown] = useState(false);

  const PaymentSchedule = () => {
    let comps = [];
    let sorted = financials.amortizationActivity
      .slice(0)
      .sort((a: any, b: any) => {
        // @ts-ignore
        return new Date(b.eventDate) - new Date(a.eventDate);
      });
    for (let i = 0; i < sorted.length; i++) {
      comps.push(
        <PaymentScheduleEntry key={`Entry-${i}`} index={i} entry={sorted[i]} />
      );
    }
    return comps;
  };

  const handleScroll = () => {
    setScrolldown((prevState) => !prevState);
  };
  return data.localAppState.isMobile ? (
    <PaymentPlanMobile financials={financials} user={user} />
  ) : (
    <PaymentPlanDesktop
      financials={financials}
      user={user}
      PaymentSchedule={PaymentSchedule}
      isScrollDownActive={isScrollDownActive}
      handleScroll={handleScroll}
    />
  );
};

export default PaymentPlan;
