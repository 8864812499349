import React, { useState } from "react";
import Card from "@hfd/components/utility/card";
import { Container, Row, Col } from "react-bootstrap";
//import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import DashboardPaymentStatus from "./Components/DashboardPaymentStatus";
import DashboardMobilePaymentWidget from "./Components/DashboardMobilePaymentWidget";
import DashboardDonutChart from "./Components/DashboardDonutChart";
import "./scss/dashboard.css";
import { useAuthDataContext } from "../../authProvider";
import {
  faDollarSign,
  faList,
  faUser,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import MobileHeader from "../../components/mobile/MobileHeader";

export default function DashboardMobile() {
  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;
  const [dataScroll, setDataScroll] = useState(0);
  document.addEventListener("scroll", () => setDataScroll(window.scrollY));

  const links = [
    {
      icon: faDollarSign,
      iconLabel: "Make Payment",
      link: "/user/makeapayment",
    },
    { icon: faList, iconLabel: "Account Details", link: "/user/paymentplan" },
    { icon: faUser, iconLabel: "Manage Profile", link: "/user/profile" },
    {
      icon: faComments,
      iconLabel: "Need Help? Let's Chat!",
      link: "/user/support",
    },
  ];

  return (
    <>
      <MobileHeader
        user={user}
        link={links}
        title={"home.Title"}
        titleDescription={"home.MobileTitleDescription"}
      />
      <Container fluid className="no-padding" data-scroll={dataScroll}>
        <Row noGutters className="d-flex">
          <Col md={6} className="pr-3">
            <div className={"mainHeader"}>Fake Nick Name</div>
            <div className={"mainSubHeader"}>#750516</div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DashboardMobilePaymentWidget financials={financials} />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6}>
            <div className={"overview"}>
              <Card>
                <Row noGutters md={1}>
                  <Col className="align-items-center justify-content-center">
                    <div>
                      Your next
                      <br />
                      payment of
                      <br />
                      $79.79 will be due on
                    </div>
                    <br />
                    <div>{/* <ion-icon name="calendar" /> */}</div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
          <Col xs={6} sm={6} md={6}>
            <div className={"overview"}>
              <Card title="Overview">
                <Row noGutters md={1}>
                  <Col className="d-flex align-items-center justify-content-center">
                    <DashboardDonutChart financials={financials} />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
        <Row noGutters md={2} className="d-flex">
          <Col md={8} className="pr-3">
            <Card title="Payments">
              <DashboardPaymentStatus financials={financials} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
