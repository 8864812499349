import React, { useEffect, useRef, useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { useAuthDataContext } from "../../../../authProvider";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUnlockAlt,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import HfdTextInput from "@hfd/components/Input/HfdTextInput";

interface FormValues {
  email: string;
  password: string;
  appId: string | undefined;
}

const SignInFormMobile = () => {
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const emailErrors = "";
  const passwordErrors = "";

  const { onLogin } = useAuthDataContext();
  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  async function doSubmit(values: FormValues) {
    await setFormSubmitting(true);
    onLogin({
      email: values.email,
      password: values.password,
      appId: values.appId,
    });
    if (componentIsMounted.current) {
      setFormSubmitting(false);
    }
  }

  async function clearValidationErrors() {
    if (emailErrors || passwordErrors) {
      console.log("hook into apollo");
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          email: "",
          password: "",
          appId: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().required(),
          password: Yup.string()
            .min(8, "A password must be at least 8 characters")
            .required("Required"),
          appId: Yup.number(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await doSubmit(values);
        }}
      >
        {({ values, errors }) => (
          <Form className="hfd-sign-in-form">
            <div className="sign-in-form-mobile mt-5">
              <div className="sign-in-text-mobile">
                <FontAwesomeIcon
                  className="sign-in-icon-mobile"
                  icon={faEnvelope}
                />
                <HfdTextInput
                  intlid="signin.EmailAddress"
                  name="email"
                  type="email"
                  placeholder="Email"
                  id="emailAddress"
                  disabled={isFormSubmitting}
                  onKeyUp={clearValidationErrors}
                  autoComplete="username"
                  autoFocus={true}
                  showError={false}
                />
                {errors.email ? (
                  <FontAwesomeIcon
                    className="sign-in-icon-mobile"
                    color={"red"}
                    icon={faTimes}
                  />
                ) : values.email.length > 0 ? (
                  <FontAwesomeIcon
                    className="sign-in-icon-mobile"
                    color={"#00b189"}
                    icon={faCheck}
                  />
                ) : null}
              </div>
              <hr />
              <div className="sign-in-text-mobile">
                <FontAwesomeIcon icon={faUnlockAlt} />
                <HfdTextInput
                  intlid="signin.Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  id="password"
                  disabled={isFormSubmitting}
                  onKeyUp={clearValidationErrors}
                  autoComplete="password"
                  showError={false}
                />
                {errors.password ? (
                  <FontAwesomeIcon
                    className="sign-in-icon-mobile"
                    color={"red"}
                    icon={faTimes}
                  />
                ) : values.password.length > 0 ? (
                  <FontAwesomeIcon
                    className="sign-in-icon-mobile"
                    color={"#00b189"}
                    icon={faCheck}
                  />
                ) : null}
              </div>
            </div>
            <Button
              disabled={isFormSubmitting}
              variant="primary"
              type="submit"
              className="sign-in-login-button-mobile mt-3"
              id="login-button"
            >
              {isFormSubmitting && (
                <Spinner size="sm" animation="grow" variant="light" />
              )}
              {!isFormSubmitting && (
                <span>
                  <IntlMessages id="signin.Login" />
                </span>
              )}
            </Button>
          </Form>
        )}
      </Formik>
      <div className="sign-in-links mt-3">
        <a href="#!">Forgot your password?</a>
        <a href="#!">Sign Up</a>
      </div>
    </Container>
  );
};

export default SignInFormMobile;
