import React, { MouseEventHandler, useState } from "react";
import { Row, Col } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";
import HFDInputEditable from "@hfd/components/InputEditable/HFDInputEditable";
import EllipsisLoading from "@hfd/components/EllipsisLoading/Ellipsis";

interface IProps {
  handleDrawer: MouseEventHandler;
  isDrawerOpen: Boolean;
  userInfo: any;
}

const ProfileBasicInfo = ({ handleDrawer, isDrawerOpen, userInfo }: IProps) => {
  // const [phoneNumber, setPhoneNumber] = useState({
  //   value: "(661) 343-1254",
  //   isInEditMode: false,
  // });

  const [language, setLanguage] = useState({
    value: "English",
    isInEditMode: false,
  });

  // const changePhoneEditMode = () => {
  //   setPhoneNumber((prevState) => ({
  //     ...prevState,
  //     isInEditMode: !prevState.isInEditMode,
  //   }));
  // };

  const changeLangEditMode = () => {
    setLanguage((prevState) => ({
      ...prevState,
      isInEditMode: !prevState.isInEditMode,
    }));
  };

  return (
    <Card title="">
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.FirstName" />
          </p>
        </Col>
        <Col>
          <p>{userInfo.firstName}</p>
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.LastName" />
          </p>
        </Col>
        <Col>
          <p>{userInfo.lastName}</p>
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.EmailAddress" />
          </p>
        </Col>
        <Col>
          <p>{userInfo.email}</p>
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.PhoneNumber" />
          </p>
        </Col>
        <Col>
          {userInfo.primaryPhone}
          {/* <HFDInputEditable
            type="text"
            textEditable={userInfo.primaryPhone}
            changeEditMode={changePhoneEditMode}
          /> */}
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.LanguagePreference" />
          </p>
        </Col>
        <Col>
          <HFDInputEditable
            as="select"
            textEditable={language}
            changeEditMode={changeLangEditMode}
          >
            <option>English</option>
            <option>French</option>
          </HFDInputEditable>
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.Password" />
          </p>
        </Col>
        <Col>
          {isDrawerOpen ? (
            <p className="profile-updating">
              Updating <EllipsisLoading />
            </p>
          ) : (
            <HFDInputEditable
              type="password"
              textEditable={isDrawerOpen}
              changeEditMode={handleDrawer}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileBasicInfo;
