import { useQuery, useMutation } from "@apollo/react-hooks";
import { useAuthDataContext } from "../../authProvider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import React from "react";
import { useHistory } from "react-router";
import {
  LOCAL_APP_STATE,
  UPDATE_NAVIGATION_TOGGLED,
} from "../../localState/queries";

import {
  faBars,
  faChevronLeft,
  faHome,
  faCommentDollar,
  faListAlt,
  faWallet,
  faUserCircle,
  faCommentDots,
  faCog,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import "../styles/navigation/mobileNavBar.css";

const MobileNavBar = (props: any) => {
  const { loading, data } = useQuery(LOCAL_APP_STATE);
  const [toggleNavigation] = useMutation(UPDATE_NAVIGATION_TOGGLED);
  const { onLogout } = useAuthDataContext();

  let history = useHistory();
  if (loading) {
    return <div>Loading </div>;
  }

  const toggleNav = () => {
    let currentlyToggled = data.localAppState.navigationToggled;
    let nexttoggle = !currentlyToggled;
    toggleNavigation({
      variables: { navigationToggled: nexttoggle },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const goBack = () => {
    if (props.customGoBack) {
      props.customGoBack();
    } else {
      history.goBack();
    }
  };
  const goHome = () => {
    history.push("/user/dashboard");
  };

  return (
    <>
      <div className="mobile-box mobile-nav">
        <div className="mobile-box mobile-back" onClick={goBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="mobile-logo" onClick={goHome}>
          <HFDLogoWithText color="white" size="small" />
        </div>

        <div className="mobile-box mobile-menu-toggle" onClick={toggleNav}>
          {data.localAppState.navigationToggled ? (
            <>
              <div className="mobile-menu-container" onClick={toggleNav}></div>
              <div className="mobile-menu-header">
                <NavLink to="/user/dashboard">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  <div className="link-txt">Home</div>
                </NavLink>
                <NavLink to="/user/makeapayment">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faCommentDollar} />
                  </div>
                  <div className="link-txt">Make A Payment</div>
                </NavLink>
                <NavLink to="/user/paymentplan">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faListAlt} />
                  </div>
                  <div className="link-txt">Account Details</div>
                </NavLink>
                <NavLink to="/user/wallet">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faWallet} />
                  </div>
                  <div className="link-txt">Manage Wallet</div>
                </NavLink>
                <NavLink to="/user/profile">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faUserCircle} />
                  </div>
                  <div className="link-txt">Manage Profile</div>
                </NavLink>
                <NavLink to="/user/contact">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faCommentDots} />
                  </div>
                  <div className="link-txt">Contact Us</div>
                </NavLink>
                <NavLink to="/user/settings">
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                  <div className="link-txt">Settings</div>
                </NavLink>
                <button onClick={onLogout}>
                  <div className="button-icon">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                  </div>
                  <div className="link-txt">Logout</div>
                </button>
              </div>
            </>
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
      </div>
    </>
  );
};

export default MobileNavBar;
