import React, {useEffect, useState} from "react";
import {useQuery, useMutation} from "@apollo/react-hooks";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import {Row, Col, Form, Button} from "react-bootstrap";
import ErrorDisplay from "@hfd/components/ErrorDisplay/ErrorDisplay";
import {Formik} from "formik";
import {
  LOCAL_APP_STATE,
  UPDATE_CARDADDRESSONE,
  UPDATE_CARDADDRESSTWO,
  UPDATE_CARDCITY,
  UPDATE_CARDSTATE,
  UPDATE_CARDCOUNTRY,
  UPDATE_CARDZIP,
} from "../../../../localState/queries";

const OneTimePaymentAddressForm = (props: any) => {
  const {data} = useQuery(LOCAL_APP_STATE);
  const [updateCardAddressOne] = useMutation(UPDATE_CARDADDRESSONE);
  const [updateCardAddressTwo] = useMutation(UPDATE_CARDADDRESSTWO);
  const [updateCardCity] = useMutation(UPDATE_CARDCITY);
  const [updateCardState] = useMutation(UPDATE_CARDSTATE);
  const [updateCardCountry] = useMutation(UPDATE_CARDCOUNTRY);
  const [updateCardZip] = useMutation(UPDATE_CARDZIP);
  const {setSubmitting, continueClicked} = props;

  const [isAddressOneValid, setAddressOneValid] = useState(false);
  const [isAddressTwoValid, setAddressTwoValid] = useState(false);
  const [isCardCityValid, setCardCityValid] = useState(false);
  const [isCardStateValid, setCardStateValid] = useState(false);
  const [isCardCountryValid, setCardCountryValid] = useState(false);
  const [isCardZipValid, setCardZipValid] = useState(false);
  const [errors, setErrors] = useState("");
  const zipregex = /\d{5}/g;

  const sleep = (ms: Number) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    const {street, city, statoid, countryCode, zip} = data.localAppState.userData.address;
    if (street) {
      validateAddressOne(street);
    }

    if (city) {
      validateCity(city);
    }

    if (statoid) {
      validateState(statoid);
    }

    if (countryCode) {
      validateCountry(countryCode);
    }

    if (zip) {
      validateZip(zip);
    }
    // eslint-disable-next-line
  }, [data]);

  const doSubmit = async ({values}: any) => {
    setErrors("");
    if (
        !isAddressOneValid ||
        !isCardCityValid ||
        !isCardStateValid ||
        !isCardCountryValid ||
        !isCardZipValid
    ) {
      return setErrors("Oops! Missing/Invalid Required fields.");
    }

    setSubmitting(true);

    await sleep(500);
    setSubmitting(false);
    continueClicked();
  };

  const validateAddressOne = (sc: string) => {
    setAddressOneValid(sc.length > 0);
  };

  const validateAddressTwo = (sc: string) => {
    setAddressTwoValid(sc.length > 0);
  };

  const validateCity = (sc: string) => {
    setCardCityValid(sc.length > 0);
  };

  const validateState = (sc: string) => {
    setCardStateValid(sc.length > 0);
  };

  const validateCountry = (sc: string) => {
    setCardCountryValid(sc.length > 0);
  };

  const validateZip = (zipcode: string) => {
    if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
    const isValid = zipregex.test(zipcode);

    setCardZipValid(isValid);
  };

  const handleAddressOne = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.value;
    validateAddressOne(sc);

    setFieldValue("addressOne", sc, true);
    updateCardAddressOne({
      variables: {addressOne: sc},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  const handleAddressTwo = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.value;
    validateAddressTwo(sc);

    setFieldValue("addressTwo", sc, true);
    updateCardAddressTwo({
      variables: {addressTwo: sc},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  const handleCardCity = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.value;
    validateCity(sc);

    setFieldValue("cardCity", sc, true);
    updateCardCity({
      variables: {cardCity: sc},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  const handleCardState = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.value;
    validateState(sc);

    setFieldValue("cardState", sc, true);
    updateCardState({
      variables: {cardState: sc},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  const handleCardCountry = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.value;
    validateCountry(sc);

    setFieldValue("cardCountry", sc, true);
    updateCardCountry({
      variables: {cardCountry: sc},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  const handleCardZip = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let zipcode = ev.target.value;
    validateZip(zipcode);

    setFieldValue("cardZip", zipcode, true);
    updateCardZip({
      variables: {cardZip: zipcode},
      refetchQueries: [{query: LOCAL_APP_STATE}],
    });
  };

  return (
      <Formik
          initialValues={{
            addressOne: data.localAppState.userData.address.street || "",
            addressTwo: "",
            cardCity: data.localAppState.userData.address.city || "",
            cardState: data.localAppState.userData.address.statoid || "",
            cardCountry: data.localAppState.userData.address.countryCode || "",
            cardZip: data.localAppState.userData.address.zip || ""
          }}
          validate={async (values) => {
          }}
          onSubmit={async (values, {resetForm}) => {
            setErrors("");
            await doSubmit(values);
          }}
      >
        {({values, handleSubmit, handleChange, setFieldValue}) => (
            <Form onSubmit={handleSubmit} className="application-form-mobile">
              <Row xs={12} sm={12} md={6} lg={6}>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="addressOne"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.AddressOne"/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={values.addressOne}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                            handleAddressOne(ev, setFieldValue)
                        }
                        isValid={isAddressOneValid}
                        isInvalid={!isAddressOneValid && values.addressOne.length > 0}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="addressTwo"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.AddressTwo"/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={values.addressTwo}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                            handleAddressTwo(ev, setFieldValue)
                        }
                        isValid={isAddressTwoValid}
                        isInvalid={!isAddressTwoValid && values.addressTwo.length > 0}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row xs={12} sm={12} md={6} lg={6}>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="cardCity"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.City"/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={values.cardCity}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                            handleCardCity(ev, setFieldValue)
                        }
                        isValid={isCardCityValid}
                        isInvalid={!isCardCityValid && values.cardCity.length > 0}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="cardZip"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.Zip"/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={values.cardZip}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                            handleCardZip(ev, setFieldValue)
                        }
                        isValid={isCardZipValid}
                        isInvalid={!isCardZipValid && values.cardZip.length > 0}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row xs={12} sm={12} md={6} lg={6}>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="cardCountry"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.Country"/>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={values.cardCountry}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          handleCardCountry(ev, setFieldValue)
                        }}
                        isValid={isCardCountryValid}
                        isInvalid={
                          !isCardCountryValid && values.cardCountry.length > 0
                        }
                    >
                      <option>Select...</option>
                      <option value={'US'}>USA</option>
                      <option value={'CA'}>Canada</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group
                      controlId="cardState"
                      className="application-form-field"
                  >
                    <Form.Label>
                      <IntlMessages id="otp.StateProvince"/>
                    </Form.Label>

                    <Form.Control
                        as="select"
                        value={values.cardState}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                            handleCardState(ev, setFieldValue)
                        }
                        isValid={isCardStateValid}
                        isInvalid={!isCardStateValid && values.cardState.length > 0}
                    >
                      <option>Select...</option>

                      {values.cardCountry === 'CA' && <>
                          <option value="AB">Alberta</option>
                          <option value="BC">British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="NT">Northwest Territories</option>
                          <option value="NU">Nunavut</option>
                          <option value="YT">Yukon</option>
                      </>}

                      {values.cardCountry === 'US' && <>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                      </>}
                    </Form.Control>
                  </Form.Group>
                </Col>

              </Row>

              <div className="error-container otp-error">
                {errors && <ErrorDisplay errorMessage={errors} small={true}/>}
              </div>
              <div className="d-flex justify-content-center my-4">
                <Button
                    className="makepayment-cancel-button"
                    onClick={props.cancelClicked}
                >
                  <IntlMessages id={`otp.Back`}/>
                </Button>
                <Button className="makepayment-continue-button" type="submit">
                  <IntlMessages id="otp.Continue"/>
                </Button>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default OneTimePaymentAddressForm;
