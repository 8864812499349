// @ts-ignore
import React from "react";

interface IProps {
    label: string;
    action: any;
}

const HfdEditIcon = ({ label, action }: IProps) => {
    return (
        <>
            <ion-icon name="pencil" width="60" onClick={action} />
        </>
    );
};

export default HfdEditIcon;
