import React, { MouseEventHandler } from "react";

import "./scss/HfdMobileButtonLink.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntlMessages from "@hfd/components/utility/IntlMessages";

interface IProps {
  icon: any;
  text: String;
  onClick: MouseEventHandler;
}

const HfdMobileButtonLink = ({ icon, text, onClick }: IProps) => {
  return (
    <button className="hfd-mobile-button-link" onClick={onClick}>
      <div className="button-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="button-text">
        <IntlMessages id={text} />
      </div>
    </button>
  );
};

export default HfdMobileButtonLink;
