import React, { Suspense, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import Loader from '@hfd/components/utility/loader';
import Wallet from '../Pages/Wallet/Wallet';
import Settings from '../Pages/Settings/Settings';
import Dashboard from './Dashboard';
import Payment from '../Pages/Payment/Payment';
import PaymentPlan from '../Pages/PaymentPlan/PaymentPlan';
import PaymentPlanActivity from '../Pages/PaymentPlan/Components/PaymentPlanAcitivityMobile';
import PaymentPlanDocument from '../Pages/PaymentPlan/Components/PaymentDocumentMobile';
import PaymentPlanEdit from '../Pages/PaymentPlan/Components/PaymentEditPlanMobile';
import Profile from '../Pages/Profile/Profile';
import TestDemo from '../TestDemoPage/TestDemo';
import { useAuthDataContext } from '../../authProvider';

export default function AppRouter() {
  const user = useAuthDataContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkMyAuthStatus() {
      setLoading(false);
    }

    checkMyAuthStatus();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: `/signin/`,
          state: { from: 'Dashboard' },
        }}
      />
    );
  }
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/user/dashboard" component={Dashboard} />
        <Route path="/user/wallet" component={Wallet} />
        <Route path="/user/settings" component={Settings} />
        <Route path="/user/makeapayment" component={Payment} />
        <Route path="/user/profile" component={Profile} />
        <Route
          path="/user/paymentplan/activity"
          component={PaymentPlanActivity}
        />
        <Route
          path="/user/paymentplan/document"
          component={PaymentPlanDocument}
        />
        <Route path="/user/paymentplan/edit" component={PaymentPlanEdit} />
        <Route path="/user/paymentplan/" component={PaymentPlan} />
        <Route path="/user/testdemo" component={TestDemo} />
      </Switch>
    </Suspense>
  );
}
