// @ts-ignore
import React from "react";
import "./scss/icon-button.css";

interface IProps {
    name: string;
    action: any;
    color: string;
    icon: string;
}

const HfdIconButton = ({ name, action, color, icon }: IProps) => {
    return (
        <>
            <div className={'icon-button'}>
                <button
                    className={color}
                    name={name}
                    onClick={action}
                >
                    <ion-icon name={icon} size="large" />
                </button>
            </div>
        </>
    );
};

export default HfdIconButton;

