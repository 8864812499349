import React, { useState } from "react";
import "./scss/settings.css";
import { useAuthDataContext } from "../../../authProvider";
import { LOCAL_APP_STATE } from "../../../localState/queries";
import { useQuery } from "@apollo/react-hooks";

import SettingsMobile from "./SettingsMobile";
import SettingsDesktop from "./SettingsDesktop";

const Settings = () => {
  const { user } = useAuthDataContext().user;
  const { data } = useQuery(LOCAL_APP_STATE);

  const [textEditable, setTextEditable] = useState({
    value: user.financeInfo.paymentPlanName,
    isInEditMode: false,
    isDefault: false,
  });

  const changeEditMode = () => {
    setTextEditable((prevState) => ({
      ...prevState,
      isInEditMode: !prevState.isInEditMode,
    }));
  };

  const changeSetDefault = () => {
    setTextEditable((prevState) => ({
      ...prevState,
      isDefault: !prevState.isDefault,
    }));
  };

  const handleSaveValue = () => {
    setTextEditable((prevState) => ({
      ...prevState,
      value: "test",
      isInEditMode: !prevState.isInEditMode,
    }));
  };

  return data.localAppState.isMobile ? (
    <SettingsMobile
      user={user}
      textEditable={textEditable}
      changeSetDefault={changeSetDefault}
      changeEditMode={changeEditMode}
      handleSaveValue={handleSaveValue}
    />
  ) : (
    <SettingsDesktop
      user={user}
      textEditable={textEditable}
      changeSetDefault={changeSetDefault}
      changeEditMode={changeEditMode}
      handleSaveValue={handleSaveValue}
    />
  );
};

export default Settings;
