import React from "react";
import {Card, Row, Col, Container} from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";

const OneTimePaymentInfo = (props: any) => {
  return (
      <Container className="mb-4">
        <Card className="otp-card-info">
          {props.current && (
              <Row xs={2} noGutters>
                <Col xs={3} className="otp-card-icon green">
                  <div className={'icon ok'} />
                </Col>
                <Col xs={9} className="otp-card-icon-desc">
                  <p className="green">
                    <IntlMessages id="otp.IfAccountIsCurrent"/>
                  </p>
                  <p className="grey">
                    <IntlMessages id="otp.PaymentWillBeAppliedPrincipal"/>
                  </p>
                </Col>
              </Row>
          )}
          {props.pastDue && (
              <Row xs={2} noGutters className="py-2">
                <Col xs={3} className="otp-card-icon orange">
                  <div className={'icon warning'} />
                </Col>
                <Col xs={9} className="otp-card-icon-desc">
                  <p className="orange">
                    <IntlMessages id="otp.IfAccountIsPastDue"/>
                  </p>
                  <p className="grey">
                    <IntlMessages id="otp.PaymentWillBeAppliedPastDue"/>
                  </p>
                </Col>
              </Row>
          )}
          {props.makeOTP && (
              <Row xs={2} noGutters className="py-2">
                <Col xs={3} className="otp-card-icon grey">
                  <div className={'icon hand'} />
                </Col>
                <Col xs={9} className="otp-card-icon-desc">
                  <p className="grey">
                    <IntlMessages id="otp.MakingOtp"/>
                  </p>
                </Col>
              </Row>
          )}
          {props.contact && (
              <Row xs={2} noGutters className="py-2">
                <Col xs={3} className="otp-card-icon grey">
                  <div className={'icon phone'} />
                </Col>
                <Col xs={9} className="otp-card-icon-desc">
                  <p className="grey">
                    <IntlMessages id="otp.NotSureWhatToDo"/>
                  </p>
                  <p className="grey">
                    <IntlMessages id="otp.ContactHFD"/>{" "}
                    <span className="light-blue">(877) 874-3877</span>
                  </p>
                </Col>
              </Row>
          )}
        </Card>
      </Container>
  );
};

export default OneTimePaymentInfo;
