import gql from "graphql-tag";

const typeDefs = gql`
  type LocalAppState {
    id: Int!
    navigationToggled: Boolean!
    isMobile: Boolean
  }

  type Query {
    localAppState: LocalAppState!
  }

  type Mutation {
    resetModel(applicationId: String!): LocalAppState!
  }

  type Mutation {
    updateNavigationToggled(navigationToggled: Boolean!): LocalAppState!
  }

  type Mutation {
    updateIsMobileProperty(isMobile: Boolean!): LocalAppState!
  }

  type Mutation {
    updateUserPaymentAmount(paymentAmount: String!): LocalAppState!
  }

  type Mutation {
    updateUserZipCode(zipCode: String!): LocalAppState!
  }

  type Mutation {
    updateUserApplicationId(applicationId: String!): LocalAppState!
  }
  
  type Mutation {
    updateUserPaymentType(cardType: String!): LocalAppState!
  }

  type Mutation {
    updateUserNameOnCard(nameOnCard: String!): LocalAppState!
  }

  type Mutation {
    updateUserPaymentCardNumber(cardNumber: String!): LocalAppState!
  }

  type Mutation {
    updateUserPaymentCardMonth(expirationMonth: String!): LocalAppState!
  }

  type Mutation {
    updateUserPaymentCardYear(expirationYear: String!): LocalAppState!
  }

  type Mutation {
    updateUserPaymentCardCode(securityCode: String!): LocalAppState!
  }

  type Mutation {
    updateSavePayment(savePayment: String!): LocalAppState!
  }
`;

export default typeDefs;
