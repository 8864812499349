import React from "react";
import { Row, Col } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";
import HfdButton from "@hfd/components/Button/HfdButton";

const SettingsTOS = () => {
  return (
    <Card>
      <Row noGutters md={2} className="mb-2">
        <Col className="d-flex align-items-center">
          <p className="hfd-primary2">
            <IntlMessages id="settings.TermsOfUse" />
          </p>
        </Col>
        <Col className="d-flex justify-content-end pr-2">
          <HfdButton label={"View"} />
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col className="d-flex align-items-center">
          <p className="hfd-primary2">
            <IntlMessages id="settings.PrivacyPolicy" />
          </p>
        </Col>
        <Col className="d-flex justify-content-end pr-2">
          <HfdButton label={"View"} />
        </Col>
      </Row>
      <Row noGutters md={2} className="mb-2">
        <Col className="d-flex align-items-center">
          <p className="hfd-primary2">
            <IntlMessages id="settings.StatusPage" />
          </p>
        </Col>
        <Col className="d-flex justify-content-end pr-2">
          <HfdButton label={"View"} />
        </Col>
      </Row>
    </Card>
  );
};

export default SettingsTOS;
