import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

import VisaLogo from "@hfd/components/Wallet/VisaLogo";
import MasterCardLogo from "@hfd/components/Wallet/MasterCardLogo";
import AmexLogo from "@hfd/components/Wallet/AmexLogo";

const CheckName = (props: any) => {
  return (
    <>
      <Col className="d-flex">
        <div className="check-container">
          <div className="check-icon">
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
          <div className="check-name">
            <IntlMessages id={`makePayment.${props.checkName}`} />
          </div>
        </div>
      </Col>
      <Col>{props.children}</Col>
    </>
  );
};

const displayCardLogo = (cardName: string) => {
  if (cardName === "VISA") {
    return <VisaLogo />;
  } else if (cardName === "MASTER") {
    return <MasterCardLogo />;
  } else {
    return <AmexLogo />;
  }
};

const PaymentConfirmationWidgetMobile = (props: any) => {
  return (
    <>
      <Row sm={2}>
        <CheckName checkName="Amount">
          <div className="check-title">$80.00</div>

          <div className="check-subtitle">
            <IntlMessages id={`makePayment.AmountSubtitle`} />
          </div>
        </CheckName>
      </Row>
      <Row sm={2}>
        <CheckName checkName="Method">
          <div className="check-title">
            <div className="check-card-img">{displayCardLogo("VISA")}</div>
            <div className="check-card-img-text"> Ending in 7777</div>
          </div>

          <div className="check-subtitle"></div>
        </CheckName>
      </Row>
      <Row sm={2}>
        <CheckName checkName="Date">
          <div className="check-title">Sept 7th, 2020</div>

          <div className="check-subtitle">
            <IntlMessages id={`makePayment.DateSubtitle`} />
          </div>
        </CheckName>
      </Row>
      <Row className="my-4">
        <Col className="d-flex justify-content-center">
          <Button
            className="makepayment-submit-button"
            onClick={props.submitClicked}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PaymentConfirmationWidgetMobile;
