// @ts-ignore
import React from "react";
import "./scss/checkbox.css";

interface IProps {
    id: string;
    checked: boolean;
    onChange: any;
}

const HfdCheckbox = ({id, checked, onChange}: IProps) => {
    return (
        <>
            <div className="checkbox">
                <input
                    type="checkbox"
                    id={id}
                    onChange={onChange}
                    checked={checked}
                />
                <label htmlFor={id}></label>
            </div>
        </>
    );
};

export default HfdCheckbox;
