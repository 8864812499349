import Styled from "styled-components";

const CardStyle = Styled.div`
   height: 100%;
   .card {
      border: 1px solid #D3D3D3;
      border-radius: 30px;
      background: #FFFFFF;
      padding: 1.2rem 1.5rem;
      height: 100%;
   }
   .card .card-head {
      margin-bottom: 1rem;
   }
   .card .card-title {
      font-size: 18pt;
      color: #333333;
   }
   .hfd-card-body {
      color: #8C96AB;
      height: 100%;
      align-items: center;
      display: flex;
   }
   .hfd-card-body-child {
      width: 100%;
   }
   .card p {
      margin: 0;
      padding: 0;
      font-family: "CircularMedium", "Open Sans", sans-serif;
   }
`;

export default CardStyle;
