import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import StepBarNavigator from "@hfd/ui/StepBarNavigator/StepBarNavigator";
import OTPMobileNavBar from "../../../components/mobile/OTPMobileNavBar";

import "./scss/OneTimePaymentMobile.css";

const OneTimePaymentMobile = ({
  getPaymentSteps,
  getMobileStepText,
  currentStep,
  getCurrentStep,
  cancelClicked
}: any) => {
  return (
    <>
      <OTPMobileNavBar currentStep={currentStep} cancelClicked={cancelClicked} />
      <Container className="temp-container-height">
        <Row>
          {currentStep === 4 ? null : (
            <Col sm={12} md={12} lg={12} xs={12}>
              <h4 className="page-title mobile">
                <IntlMessages id="makePayment.TitleMobile" />
                {getMobileStepText()}
              </h4>
            </Col>
          )}
        </Row>
        <Row>
          {currentStep === 4 ? null : (
            <Col className="step-nav-padding" sm={12} md={12} lg={12} xs={12}>
              <StepBarNavigator steps={getPaymentSteps()} />
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Col sm={12} md={12} lg={12} xs={12}>
            {getCurrentStep()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OneTimePaymentMobile;
