import React from "react";
import "../styles/footer/mobileFooter.css";
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileFooter = () => {
  return (
    <footer>
      <div className="terms-and-disclosure">
        <a href="/#">Terms and Disclosures</a>
      </div>
      <div className="social-icon-links">
        <a href="https://www.facebook.com/HealthcareFinanceDirect">
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a href="https://www.linkedin.com/company/healthcare-finance-direct/mycompany/">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
      <div className="footer-rights">
        <p>
          {new Date().getFullYear()} © Healthcare Finance Direct, LLC. All
          Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default MobileFooter;
