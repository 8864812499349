// @ts-ignore
import React from "react";
// @ts-ignore
import { Form } from "react-bootstrap";
// @ts-ignore
import { useField } from "formik";
import IntlMessages from "../utility/IntlMessages";

interface IProps {
  autoComplete: string;
  disabled: boolean;
  id: string;
  intlid: string;
  label?: string;
  name: string;
  onKeyUp: any;
  placeholder: string;
  type: string;
  className?: string;
  showError?: boolean;
  autoFocus?: boolean;
}

const HfdTextInput = ({ showError, ...props }: IProps) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group>
        {props.label ? (
          <Form.Label htmlFor={props.id} className="text-muted">
            <IntlMessages id={props.intlid} />
          </Form.Label>
        ) : null}
        <Form.Control {...field} {...props} />
      </Form.Group>
      {meta.touched && meta.error && showError ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default HfdTextInput;
