import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import Navigation from "@hfd/components/Navigation/Navigation";

import ProfilePasswordError from "./ProfilePassworError";
import { history } from "../../../../router";

const ProfileNewPasswordPage = () => {
  const [isEmailValid, setEmail] = useState(false);
  const [isPasswordValid, setPassword] = useState(false);
  const [errors, setErrors] = useState("");

  const doSubmit = async ({ values }: any) => {
    setErrors("");
    if (!isEmailValid || !isPasswordValid) {
      return setErrors("Oops! Invalid Email or Password.");
    }

    if (errors.length === 0) {
      return history.push("/user/dashboard");
    }
  };

  return (
    <>
      <Navigation>
        <></>
      </Navigation>
      <div className="has-new-password-container">
        <div className="new-password-form-container">
          <div className="new-password-form-head">
            <div className="new-password-head-icon">
              <ion-icon name="checkmark-circle-outline" />
            </div>

            <p className="d-flex align-items-center">Password Updated</p>
          </div>
          <div className="new-password-form-body">
            <div className="new-password-body-icon">
              <ion-icon name="lock-open"></ion-icon>
            </div>
            <div className="new-password-title">
              <p>You have a new password!</p>
            </div>
            <div className="new-password-subtitle">
              <p>In order to continue, please enter your new credentials</p>
            </div>

            <div className="new-password-form">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validate={async (values) => {
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                    ? setEmail(false)
                    : setEmail(true);
                  values.password === "test123"
                    ? setPassword(true)
                    : setPassword(false);
                }}
                onSubmit={async (values, { resetForm }) => {
                  setErrors("");
                  await doSubmit(values);
                }}
              >
                {({ values, handleSubmit, handleChange }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                      <Form.Label className="profile-password-label">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="profile-password"
                        onChange={handleChange}
                        value={values.email}
                        isValid={isEmailValid}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label className="profile-password-label">
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="profile-password"
                        onChange={handleChange}
                        value={values.password}
                        isValid={isPasswordValid}
                        required
                      />
                    </Form.Group>
                    <div className="error-container">
                      {errors && (
                        <ProfilePasswordError
                          errorMessage={errors}
                          small={true}
                        />
                      )}
                    </div>
                    <div className="form-button-container">
                      <button
                        type="button"
                        value="Cancel"
                        className="new-password-cancel"
                      >
                        Exit to Login Screen
                      </button>
                      <button
                        type="submit"
                        value="Submit"
                        className="new-password-submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileNewPasswordPage;
