import React, { useEffect } from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import CurrencyInput from "@hfd/ui/CurrencyInput/CurrencyInput";
import HfdCheckbox from "@hfd/components/Checkbox/HfdCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";

export const paymentOptions = {
  PAST_DUE_AMOUNT: "PAST_DUE_AMOUNT",
  PAYOFF_AMOUNT: "PAYOFF_AMOUNT",
  CUSTOM_AMOUNT: "CUSTOM_AMOUNT",
  MONTHLY_PAYMENT_AMOUNT: "MONTHLY_PAYMENT_AMOUNT",
};

const PaymentAmountWidget = (props: any) => {
  const financials = props.financials;
  const [customAmount, setCustomAmount] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const [paymentOptionSelected, setPaymentOption] = useState(
    paymentOptions.PAST_DUE_AMOUNT
  );

  useEffect(() => {
    const initialPaymentOption = () => {
      // TODO: Configure Initial Load of this
      // Component based on prior selected values
      // This will ensure that navigating the steps
      // doesn't lose data

      if (financials.paymentStatus === "ACT") {
        setPaymentOption(paymentOptions.MONTHLY_PAYMENT_AMOUNT);
        console.log("setting to act");
      } else {
        setPaymentOption(paymentOptions.PAST_DUE_AMOUNT);
      }
    };

    initialPaymentOption();
  }, [financials.paymentStatus]);

  const paymentOptionChanged = (option: any) => {
    setPaymentOption(option);
    if (option === paymentOptions.CUSTOM_AMOUNT) {
      if (customAmount === undefined) {
        setNextButtonDisabled(true);
      } else if (customAmount < financials.minPaymentAllowed) {
        setNextButtonDisabled(true);
      } else if (customAmount > financials.payoffAmount) {
        setNextButtonDisabled(true);
      } else {
        setNextButtonDisabled(false);
      }
    } else {
      setNextButtonDisabled(false);
    }
  };

  const selectCustomAmount = (e: any) => {
    let value = e.target.value;
    if (value.length === 0) {
      setCustomAmount(0);
      setNextButtonDisabled(true);
      return;
    }
    if (value.length > 0) {
      value = value.replace("$", "");
      value = value.replace(",", "");
    } else {
      setCustomAmount(0);
    }

    if (isNaN(value)) {
      setCustomAmount(0);
      return;
    }
    let parsedValue = parseFloat(value);
    // validate
    setCustomAmount(parsedValue);
    if (parsedValue < financials.minPaymentAllowed) {
      setNextButtonDisabled(true);
    } else if (parsedValue > financials.payoffAmount) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  };

  const nextClicked = () => {
    // Get the Value Selected
    // Tell parent the amount selected
    // and that validation passed
    // so it can move on to the next step
    let valueSelected = 0;
    if (paymentOptionSelected === paymentOptions.PAST_DUE_AMOUNT) {
      valueSelected = financials.currentAmountPastDue;
    } else if (paymentOptionSelected === paymentOptions.PAYOFF_AMOUNT) {
      valueSelected = financials.payoffAmount;
    } else if (
      paymentOptionSelected === paymentOptions.MONTHLY_PAYMENT_AMOUNT
    ) {
      // Hardcoding until we get Apollo in here
      valueSelected = 80.0;
    } else {
      valueSelected = customAmount;
    }
    props.onNextClick(valueSelected);
  };

  const invalidCustomAmountSelectedIcon = () => {
    if (paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT) {
      if (
        customAmount === undefined ||
        customAmount < financials.minPaymentAllowed ||
        customAmount > financials.payoffAmount
      ) {
        return <FontAwesomeIcon className="error" icon={faTimes} />;
      } else {
        return <FontAwesomeIcon icon={faCheck} />;
      }
    } else {
      return <></>;
    }
  };

  const invalidCustomAmountSelected = () => {
    if (paymentOptionSelected !== paymentOptions.CUSTOM_AMOUNT) {
      return (
        <p className="input-information">
          <IntlMessages id="makePayment.MinimumAmount" /> $
          {financials.minPaymentAllowed}
        </p>
      );
    }
    if (paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT) {
      if (customAmount < financials.minPaymentAllowed) {
        return (
          <p className="input-information error">
            <IntlMessages id="makePayment.MinimumAmountNotSatisfied" /> $
            {financials.minPaymentAllowed}
          </p>
        );
      } else if (customAmount > financials.payoffAmount) {
        return (
          <p className="input-information error">
            <IntlMessages id="makePayment.CustomAmountTooBig" />{" "}
            <FormattedCurrency amount={financials.payoffAmount} />
          </p>
        );
      } else {
        return (
          <p className="input-information">
            <IntlMessages id="makePayment.MinimumAmount" /> $
            <FormattedCurrency amount={financials.minPaymentAllowed} />
          </p>
        );
      }
    }
  };

  const renderPastDueAmount = () => {
    if (financials.paymentStatus !== "ACT") {
      return (
        <div>
          <Row className="marginRow">
            <Col md={1} sm={12}>
              <HfdCheckbox
                id={"pastDueCheckbox"}
                onChange={() =>
                  paymentOptionChanged(paymentOptions.PAST_DUE_AMOUNT)
                }
                checked={
                  paymentOptionSelected === paymentOptions.PAST_DUE_AMOUNT
                }
              />
            </Col>
            <Col md={11} sm={12}>
              <Row>
                <Col md={8} sm={12}>
                  <p className="paymentOption-title">
                    <IntlMessages id="makePayment.PastDueAmount" />
                  </p>
                  <p className="paymentOption-description">
                    <IntlMessages id="makePayment.PastDueAmountDescription" />
                  </p>
                </Col>
                <Col md={4}>
                  <div className="payment-status-widget-amount-display">
                    <FormattedCurrency
                      amount={financials.currentAmountPastDue}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row className="marginRow">
            <Col md={1} sm={12}>
              <HfdCheckbox
                id={"pastDueCheckbox"}
                onChange={() =>
                  paymentOptionChanged(paymentOptions.MONTHLY_PAYMENT_AMOUNT)
                }
                checked={
                  paymentOptionSelected ===
                  paymentOptions.MONTHLY_PAYMENT_AMOUNT
                }
              />
            </Col>
            <Col md={11} sm={12}>
              <Row>
                <Col md={8} sm={12}>
                  <p className="paymentOption-title">
                    <IntlMessages id="makePayment.MonthlyPaymentAmount" />
                  </p>
                  <p className="paymentOption-description">
                    <IntlMessages id="makePayment.MonthlyPaymentAmountDescription" />
                  </p>
                </Col>
                <Col md={4}>
                  <div className="payment-status-widget-amount-display">
                    <FormattedCurrency amount={financials.nextPayment} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  };
  return (
    <div>
      <Card className="hfd-widget-card padding-1 padding-right-3">
        <Row>
          <Col md={12}>
            <h6 className="card-widget-title">
              <div className="span blueme">
                <IntlMessages id="makePayment.StepOne" />
              </div>
              <IntlMessages id="makePayment.StepOneTitle" />
            </h6>
          </Col>
        </Row>
        {renderPastDueAmount()}
        <Row className="marginRow">
          <Col md={1} sm={12}>
            <div className="checkbox">
              <input
                id="payoffChkBox"
                type="checkbox"
                checked={paymentOptionSelected === paymentOptions.PAYOFF_AMOUNT}
                onChange={() =>
                  paymentOptionChanged(paymentOptions.PAYOFF_AMOUNT)
                }
              />
              <label htmlFor="payoffChkBox"></label>
            </div>
          </Col>
          <Col md={11} sm={12}>
            <Row>
              <Col md={8} sm={12}>
                <p className="paymentOption-title">
                  <IntlMessages id="makePayment.PayoffAmount" />
                </p>
                <p className="paymentOption-description">
                  <IntlMessages id="makePayment.PayoffAmountDescription" />
                </p>
              </Col>
              <Col md={4}>
                <div className="payment-status-widget-amount-display">
                  <FormattedCurrency amount={financials.payoffAmount} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="marginRow">
          <Col md={1} sm={12}>
            <div className="checkbox">
              <input
                key={1}
                id="customAmtChkBox"
                type="checkbox"
                checked={paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT}
                onChange={() =>
                  paymentOptionChanged(paymentOptions.CUSTOM_AMOUNT)
                }
              />
              <label htmlFor="customAmtChkBox"></label>
            </div>
          </Col>
          <Col md={11} sm={12}>
            <Row>
              <Col md={8} sm={12}>
                <p className="paymentOption-title">
                  <IntlMessages id="makePayment.CustomAmount" />
                </p>
                <p className="paymentOption-description">
                  <IntlMessages id="makePayment.CustomAmountDescription" />
                </p>
              </Col>
              <Col md={4}>
                <div className="cw-input">
                  <CurrencyInput
                    type="text"
                    value={customAmount ? customAmount : ""}
                    onChange={selectCustomAmount}
                    className="currencyInput pull-right"
                    placeholder="$0.00"
                    maskOptions={{}}
                    disabled={
                      paymentOptionSelected !== paymentOptions.CUSTOM_AMOUNT
                    }
                  />
                  {paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT && (
                    <>
                      <span className="cw-input-marking">
                        {invalidCustomAmountSelectedIcon()}
                      </span>
                      <span className="cw-input-error">
                        {invalidCustomAmountSelected()}
                      </span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Button
        disabled={nextButtonDisabled}
        className="right"
        onClick={() => nextClicked()}
      >
        Continue <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </div>
  );
};

export default PaymentAmountWidget;
