// @ts-ignore
import React from "react";

// @ts-ignore
import HFDLogo from "@hfd/assets/images/cp-hfdlogo-icon-with-text.svg";
import HFDLogoColored from "@hfd/assets/images/hfdLogoBlue.png";

interface IProps {
  color: string;
  size: string;
}

const HFDLogoWithText = ({ color = "white", size = "large" }: IProps) => {
  return (
    <div className={"logo white " + size}>
      <img
        src={color === "white" ? HFDLogo : HFDLogoColored}
        alt="Healthcare Finance Direct"
      />
    </div>
  );
};

export default HFDLogoWithText;
