import React from "react";

interface IProps {
  errorMessage: String;
  small: Boolean;
}

const ErrorDisplay = ({ errorMessage, small }: IProps) => {
  return (
    <div className={small ? "error-message small" : "error-message"}>
      <p>{errorMessage}</p>
    </div>
  );
};

export default ErrorDisplay;
