import React, { useEffect } from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import CurrencyInput from "@hfd/ui/CurrencyInput/CurrencyInput";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import HFDCalendarDollarSign from "@hfd/ui/UI/HFDIcons/HFDCalendarDollarSign";
import HFDPastDueWarning from "@hfd/ui/UI/HFDIcons/HFDPastDueWarning";
import HFDCircleDollarSign from "@hfd/ui/UI/HFDIcons/HFDCircleDollarSign";
import HFDHoveringDollarBill from "@hfd/ui/UI/HFDIcons/HFDHoveringDollarBill";
import HFDActionButton from "@hfd/ui/UI/HFDIcons/HFDActionButton";

export const paymentOptions = {
  PAST_DUE_AMOUNT: "PAST_DUE_AMOUNT",
  PAYOFF_AMOUNT: "PAYOFF_AMOUNT",
  CUSTOM_AMOUNT: "CUSTOM_AMOUNT",
  MONTHLY_PAYMENT_AMOUNT: "MONTHLY_PAYMENT_AMOUNT",
};

const PaymentAmountWidgetMobile = (props: any) => {
  const financials = props.financials;
  const [customAmount, setCustomAmount] = useState(0);

  const [paymentOptionSelected, setPaymentOption] = useState(
    paymentOptions.PAST_DUE_AMOUNT
  );

  useEffect(() => {
    const initialPaymentOption = () => {
      // TODO: Configure Initial Load of this
      // Component based on prior selected values
      // This will ensure that navigating the steps
      // doesn't lose data
      if (!financials) {
        return;
      }
      if (financials.paymentStatus === "ACT") {
        setPaymentOption(paymentOptions.MONTHLY_PAYMENT_AMOUNT);
      } else {
        setPaymentOption(paymentOptions.PAST_DUE_AMOUNT);
      }
    };

    initialPaymentOption();
  }, [financials]);

  if (!financials) {
    console.log("no financials");
    return <div></div>;
  }

  const paymentOptionChanged = (option: any) => {
    setPaymentOption(option);
    if (option === paymentOptions.CUSTOM_AMOUNT) {
      if (customAmount === undefined) {
      } else if (customAmount < financials.minPaymentAllowed) {
      } else if (customAmount > financials.payoffAmount) {
      } else {
      }
    } else {
    }
  };

  const selectCustomAmount = (e: any) => {
    let value = e.target.value;
    if (value.length === 0) {
      setCustomAmount(0);
      return;
    }
    if (value.length > 0) {
      value = value.replace("$", "");
      value = value.replace(",", "");
    } else {
      setCustomAmount(0);
    }

    if (isNaN(value)) {
      setCustomAmount(0);
      return;
    }
    let parsedValue = parseFloat(value);
    // validate
    setCustomAmount(parsedValue);
    if (parsedValue < financials.minPaymentAllowed) {
    } else if (parsedValue > financials.payoffAmount) {
      console.log(financials.payoffAmount);
      setCustomAmount(financials.payoffAmount);
    } else {
    }
  };

  const nextClicked = () => {
    // Get the Value Selected
    // Tell parent the amount selected
    // and that validation passed
    // so it can move on to the next step
    let valueSelected = 0;
    if (paymentOptionSelected === paymentOptions.PAST_DUE_AMOUNT) {
      valueSelected = financials.currentAmountPastDue;
    } else if (paymentOptionSelected === paymentOptions.PAYOFF_AMOUNT) {
      valueSelected = financials.payoffAmount;
    } else if (
      paymentOptionSelected === paymentOptions.MONTHLY_PAYMENT_AMOUNT
    ) {
      // Hardcoding until we get Apollo in here
      valueSelected = financials.monthlyPaymentAmount;
    } else {
      valueSelected = customAmount;
    }
    props.onNextClick(valueSelected);
    props.showHeaderDetails();
  };

  const chooseCustomAmount = () => {
    props.hideHeaderDetails();
  };
  // const cancelChooseCustomAmount = () => {
  //   props.showHeaderDetails();
  // };

  const invalidCustomAmountSelected = () => {
    if (paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT) {
      if (customAmount < financials.minPaymentAllowed) {
        return (
          <p className="input-information">
            <IntlMessages id="makePayment.MinimumAmount" /> $
            {financials.minPaymentAllowed}
          </p>
        );
      } else if (customAmount > financials.payoffAmount) {
        return (
          <p className="input-information">
            <IntlMessages id="makePayment.CustomAmountTooBig" />{" "}
            <FormattedCurrency amount={financials.payoffAmount} />
          </p>
        );
      } else {
        return (
          <p className="input-information">
            <IntlMessages id="makePayment.MinimumAmount" /> $
            <FormattedCurrency amount={financials.minPaymentAllowed} />
          </p>
        );
      }
    }
  };

  const renderPastDueAmount = () => {
    if (financials.paymentStatus !== "ACT") {
      return (
        <div>
          <Row>
            <Col md={12}>
              <div
                className={
                  paymentOptionSelected === paymentOptions.PAST_DUE_AMOUNT
                    ? "small-card mobile-payment late selected"
                    : "small-card mobile-payment"
                }
                onClick={() =>
                  paymentOptionChanged(paymentOptions.PAST_DUE_AMOUNT)
                }
              >
                <div className="con-left">
                  <div className="upper">
                    <div className="upper-left">
                      <HFDPastDueWarning selected={true} />
                    </div>
                    <div className="upper-right">
                      <h6>Total Amount Due</h6>
                      <h6>
                        {" "}
                        <FormattedCurrency
                          amount={financials.currentAmountPastDue}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="lower">
                    Includes past due amount plus additional fees balance. This
                    ensures no additional fees will be applied to your account.
                  </div>
                </div>
                <div className="con-right">
                  <div className="next-button" onClick={() => nextClicked()}>
                    <HFDActionButton selected={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row>
            <Col md={12}>
              <div
                className={
                  paymentOptionSelected ===
                  paymentOptions.MONTHLY_PAYMENT_AMOUNT
                    ? "small-card mobile-payment selected"
                    : "small-card mobile-payment"
                }
                onClick={() =>
                  paymentOptionChanged(paymentOptions.MONTHLY_PAYMENT_AMOUNT)
                }
              >
                <div className="con-left">
                  <div className="upper">
                    <div className="upper-left act">
                      <HFDCalendarDollarSign
                        selected={
                          paymentOptionSelected ===
                          paymentOptions.MONTHLY_PAYMENT_AMOUNT
                        }
                      />
                    </div>
                    <div className="upper-right act">
                      <h6>Monthly Payment</h6>
                      <h6>
                        {" "}
                        <FormattedCurrency
                          amount={financials.currentAmountPastDue}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="lower">
                    The monthly payment amount will be applied to your principal
                    balance.
                  </div>
                </div>
                <div className="con-right">
                  <div className="next-button" onClick={() => nextClicked()}>
                    <HFDActionButton selected={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  };

  if (props.choosingCustomAmount) {
    return (
      <div>
        <Row>
          <Col md={12}>
            <div className="small-card flex-col mobile-payment selected indepedent">
              <div
                onClick={() =>
                  paymentOptionChanged(paymentOptions.CUSTOM_AMOUNT)
                }
              >
                <div className="con-left">
                  <div className="upper">
                    <div className="upper-left act">
                      <HFDHoveringDollarBill selected={true} />
                    </div>
                    <div className="upper-right act">
                      <h6>Enter Custom Amount</h6>
                    </div>
                  </div>
                  <div className="lower">
                    <CurrencyInput
                      type="text"
                      value={customAmount ? customAmount : ""}
                      onChange={selectCustomAmount}
                      className="currencyInput pull-right mobile"
                      placeholder="$0.00"
                      maskOptions={{}}
                      disabled={
                        paymentOptionSelected !== paymentOptions.CUSTOM_AMOUNT
                      }
                    />
                  </div>
                </div>
                <div className="con-right" style={{ marginLeft: "auto" }}>
                  <div
                    className={customAmount > 0 ? "next-button" : "hideme"}
                    onClick={() => nextClicked()}
                  >
                    <HFDActionButton selected={false} />
                  </div>
                </div>
              </div>
              <div className="mobile-custom-amount message">
                {paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT && (
                  <>
                    <span className="cw-input-error">
                      {invalidCustomAmountSelected()}
                    </span>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <div>
        {renderPastDueAmount()}
        <div>
          <Row>
            <Col md={12}>
              <div
                className={
                  paymentOptionSelected === paymentOptions.PAYOFF_AMOUNT
                    ? "small-card mobile-payment selected"
                    : "small-card mobile-payment"
                }
                onClick={() =>
                  paymentOptionChanged(paymentOptions.PAYOFF_AMOUNT)
                }
              >
                <div className="con-left">
                  <div className="upper">
                    <div className="upper-left act">
                      <HFDCircleDollarSign
                        selected={
                          paymentOptionSelected === paymentOptions.PAYOFF_AMOUNT
                        }
                      />
                    </div>
                    <div className="upper-right act">
                      <h6>Payoff Amount</h6>
                      <h6>
                        {" "}
                        <FormattedCurrency amount={financials.payoffAmount} />
                      </h6>
                    </div>
                  </div>
                  <div className="lower">
                    The total amount necessary to pay off your account,
                    including principal and accured interest to date.
                  </div>
                </div>
                <div className="con-right">
                  <div className="next-button" onClick={() => nextClicked()}>
                    <HFDActionButton selected={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col md={12}>
              <div
                className={
                  paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT
                    ? "small-card mobile-payment selected"
                    : "small-card mobile-payment"
                }
                onClick={() =>
                  paymentOptionChanged(paymentOptions.CUSTOM_AMOUNT)
                }
              >
                <div className="con-left">
                  <div className="upper">
                    <div className="upper-left act">
                      <HFDHoveringDollarBill
                        selected={
                          paymentOptionSelected === paymentOptions.CUSTOM_AMOUNT
                        }
                      />
                    </div>
                    <div className="upper-right act">
                      <h6>Custom Amount</h6>
                    </div>
                  </div>
                  <div className="lower">
                    Specify the exact amount you want to pay. If your account is
                    late and the amount does not bring the amount current, you
                    may still be subject to a late fee.
                  </div>
                </div>
                <div className="con-right">
                  <div
                    className="next-button"
                    onClick={() => chooseCustomAmount()}
                  >
                    <HFDActionButton selected={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PaymentAmountWidgetMobile;
