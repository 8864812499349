import gql from "graphql-tag";

export const LOCAL_APP_STATE = gql`
  query localAppState {
    localAppState @client {
      id
      navigationToggled
      isMobile
      userData
      cardType
    }
  }
`;

export const RESET_MODEL = gql`
  mutation resetModel($applicationId: String!, $zipOnFile: String!) {
    resetModel(applicationId: $applicationId, zipOnFile: $zipOnFile) @client
  }
`;

export const UPDATE_NAVIGATION_TOGGLED = gql`
  mutation updateNavigationToggled($navigationToggled: Boolean!) {
    updateNavigationToggled(navigationToggled: $navigationToggled) @client
  }
`;

export const UPDATE_ISMOBILEPROPERTY = gql`
  mutation updateIsMobileProperty($isMobile: Boolean!) {
    updateIsMobileProperty(isMobile: $isMobile) @client
  }
`;

export const UPDATE_USERAPPLICATIONID = gql`
  mutation updateUserApplicationId($applicationId: String!) {
    updateUserApplicationId(applicationId: $applicationId) @client
  }
`;

export const UPDATE_USERZIPCODE = gql`
  mutation updateUserZipCode($zipCode: String!) {
    updateUserZipCode(zipCode: $zipCode) @client
  }
`;

export const UPDATE_USERPAYMENTAMOUNT = gql`
  mutation updateUserPaymentAmount($paymentAmount: String!) {
    updateUserPaymentAmount(paymentAmount: $paymentAmount) @client
  }
`;

export const UPDATE_NAMEONCARD = gql`
  mutation updateUserNameOnCard($nameOnCard: String!) {
    updateUserNameOnCard(nameOnCard: $nameOnCard) @client
  }
`;

export const UPDATE_CARDTYPE = gql`
  mutation updateUserPaymentType($cardType: String!) {
    updateUserPaymentType(cardType: $cardType) @client
  }
`;

export const UPDATE_CARDNUMBER = gql`
  mutation updateUserPaymentCardNumber($cardNumber: String!) {
    updateUserPaymentCardNumber(cardNumber: $cardNumber) @client
  }
`;

export const UPDATE_CARDMONTH = gql`
  mutation updateUserPaymentCardMonth($expirationMonth: String!) {
    updateUserPaymentCardMonth(expirationMonth: $expirationMonth) @client
  }
`;

export const UPDATE_CARDYEAR = gql`
  mutation updateUserPaymentCardYear($expirationYear: String!) {
    updateUserPaymentCardYear(expirationYear: $expirationYear) @client
  }
`;

export const UPDATE_CARDCODE = gql`
  mutation updateUserPaymentCardCode($securityCode: String!) {
    updateUserPaymentCardCode(securityCode: $securityCode) @client
  }
`;

export const UPDATE_CARDADDRESSONE = gql`
  mutation updateUserPaymentCardAddressOne($addressOne: String!) {
    updateUserPaymentCardAddressOne(addressOne: $addressOne) @client
  }
`;

export const UPDATE_CARDADDRESSTWO = gql`
  mutation updateUserPaymentCardAddressTwo($addressTwo: String!) {
    updateUserPaymentCardAddressTwo(addressTwo: $addressTwo) @client
  }
`;

export const UPDATE_CARDCITY = gql`
  mutation updateUserPaymentCardCity($cardCity: String!) {
    updateUserPaymentCardCity(cardCity: $cardCity) @client
  }
`;

export const UPDATE_CARDSTATE = gql`
  mutation updateUserPaymentCardState($cardState: String!) {
    updateUserPaymentCardState(cardState: $cardState) @client
  }
`;

export const UPDATE_CARDCOUNTRY = gql`
  mutation updateUserPaymentCardCountry($cardCountry: String!) {
    updateUserPaymentCardCountry(cardCountry: $cardCountry) @client
  }
`;

export const UPDATE_CARDZIP = gql`
  mutation updateUserPaymentCardZip($cardZip: String!) {
    updateUserPaymentCardZip(cardZip: $cardZip) @client
  }
`;

export const UPDATE_SAVEPAYMENT = gql`
  mutation updateSavePayment($savePayment: String!) {
    updateSavePayment(savePayment: $savePayment) @client
  }
`;
