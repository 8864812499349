import React from "react";
import { Card } from "react-bootstrap";
import { LOCAL_APP_STATE } from "../../../../localState/queries";
import { useQuery } from "@apollo/react-hooks";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import OrdinalSuffix from "@hfd/components/utility/OrdinalSuffix";
import PaymentDocuments from "./PaymentDocuments";

const PaymentSummary = ({ financials, user }: any) => {
  const { data } = useQuery(LOCAL_APP_STATE);

  return (
    <>
      <Card className="payment-card mb-5">
        <div className="card-title-wrapper">
          {data.localAppState.isMobile ? (
            <h6>Payment Plan Summary</h6>
          ) : (
            <>
              <h3>{financials.paymentPlanName}</h3>
              <p className="subtext">Plan ID: {user.applicationId}</p>
            </>
          )}
        </div>
        <div className="payment-card-body">
          <h4>Payment Plan Summary</h4>

          <div className="ppd-wrapper ppd-highlight d-flex">
            <div className="ppd-title">Total Amount Financed</div>
            <div className="ppd-content">
              <FormattedCurrency amount="2000" />
            </div>
          </div>
          <div className="ppd-wrapper d-flex">
            <div className="ppd-title">Monthly Payment Amount</div>
            <div className="ppd-content">
              <FormattedCurrency amount={financials.nextPayment} />
            </div>
          </div>
          <div className="ppd-wrapper">
            <div className="ppd-title">Count of Payments</div>
            <div className="ppd-content">
              {financials.amortizationActivity.length}
            </div>
          </div>
          <div className="ppd-wrapper">
            <div className="ppd-title">Principal Amount</div>
            <div className="ppd-content">$1600.00</div>
          </div>
          <div className="ppd-wrapper">
            <div className="ppd-title">Finance Charge</div>
            <div className="ppd-content">
              {" "}
              <FormattedCurrency amount={financials.totalAmountOwed - 1600} />
            </div>
          </div>
          <div className="ppd-wrapper">
            <div className="ppd-title">Day of Month For Payment</div>
            <div className="ppd-content">
              <OrdinalSuffix numberToFormat={financials.dayOfPayment} />
            </div>
          </div>
        </div>
      </Card>
      {data.localAppState.isMobile ? null : <PaymentDocuments />}
    </>
  );
};

export default PaymentSummary;
