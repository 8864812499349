// @ts-ignore
import appLocaleData from "react-intl/locale-data/es";
// @ts-ignore
import saMessages from "../locales/es_ES.json";

interface ISaLang {
  messages: object;
  locale: string;
  data: object;
}

const saLang: ISaLang = {
  messages: {
    ...saMessages,
  },
  locale: "es",
  data: appLocaleData,
};
export default saLang;
