/* eslint-disable import/prefer-default-export */
const Config = {
  Integrations: {
    StandardizedAPI: '',
    CustomerPortalUrl: '',
  },
};

switch (window.location.origin) {
  case 'http://localhost:3002':
  case 'https://localhost:3002':
    Config.Integrations.StandardizedAPI =
      'https://dev-clientapi.healthcarefinancedirect.com/api/';
    Config.Integrations.CustomerPortalUrl =
      'https://dev-customer.healthcarefinancedirect.com/';
    break;
  case 'http://stage-otp.healthcarefinancedirect.com':
  case 'https://stage-otp.healthcarefinancedirect.com':
    Config.Integrations.StandardizedAPI =
      'https://stage-clientapi.healthcarefinancedirect.com/api/';
    Config.Integrations.CustomerPortalUrl =
      'https://stage-customer.healthcarefinancedirect.com/';
    break;
  case 'https://otp.healthcarefinancedirect.com':
    Config.Integrations.StandardizedAPI =
      'https://clientapi.healthcarefinancedirect.com/api/';
    Config.Integrations.CustomerPortalUrl =
      'https://customer.healthcarefinancedirect.com/';
    break;
  case 'https://sandbox-otp.healthcarefinancedirect.com':
    Config.Integrations.StandardizedAPI =
      'https://sandbox-clientapi.healthcarefinancedirect.com/api/';
    Config.Integrations.CustomerPortalUrl =
      'https://sandbox-customer.healthcarefinancedirect.com/';
    break;
  default:
    Config.Integrations.StandardizedAPI =
      'https://dev-clientapi.healthcarefinancedirect.com/api/';
    Config.Integrations.CustomerPortalUrl =
      'https://dev-customer.healthcarefinancedirect.com/';
    break;
}

export { Config };
