import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// @ts-ignore
import HfdTextInput from "@hfd/components/Input/HfdTextInput";
import { useEffect } from "react";
import { useRef } from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { useAuthDataContext } from "../../../../authProvider";

// Remove App Id once we have auth
interface FormValues {
  email: string;
  password: string;
  appId: string | undefined;
}

const SignInForm = () => {
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const emailErrors = "";
  const passwordErrors = "";

  const { onLogin } = useAuthDataContext();
  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  async function doSubmit(values: FormValues) {
    await setFormSubmitting(true);
    onLogin({
      email: values.email,
      password: values.password,
      appId: values.appId,
    });
    if (componentIsMounted.current) {
      setFormSubmitting(false);
    }
  }

  async function clearValidationErrors() {
    if (emailErrors || passwordErrors) {
      console.log("hook into apollo");
    }
  }
  return (
    <div className="hfd-customer-sign-in-form">
      <Formik
        initialValues={{
          email: "",
          password: "",
          appId: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email addresss")
            .required("Email Address is Required"),
          password: Yup.string()
            .min(8, "A password must be at least 8 characters")
            .required("Required"),
          appId: Yup.number(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await doSubmit(values);
        }}
      >
        <Form className="hfd-sign-in-form">
          <HfdTextInput
            intlid="signin.EmailAddress"
            label="Email"
            name="email"
            type="email"
            placeholder="soandso@somedomain.com"
            id="emailAddress"
            disabled={isFormSubmitting}
            onKeyUp={clearValidationErrors}
            autoComplete="username"
          />

          <HfdTextInput
            intlid="signin.Password"
            label="Password"
            name="password"
            type="password"
            placeholder="********"
            id="password"
            disabled={isFormSubmitting}
            onKeyUp={clearValidationErrors}
            autoComplete="password"
          />
          <HfdTextInput
            intlid="signin.AppId"
            label="AppId"
            name="appId"
            type="number"
            placeholder="0"
            id="appId"
            disabled={isFormSubmitting}
            onKeyUp={clearValidationErrors}
            autoComplete="appId"
          />
          <br />
          <a href="#!">Forgot password?</a>
          <br />
          {emailErrors && (
            <span className="error full-width">
              <IntlMessages id="signin.EmailNotFound" />
            </span>
          )}
          {passwordErrors && (
            <span className="error full-width">
              <IntlMessages id="signin.PasswordDoesNotMatch" />
            </span>
          )}

          <Button
            disabled={isFormSubmitting}
            variant="primary"
            type="submit"
            className="right login-button"
            id="login-button"
          >
            {isFormSubmitting && (
              <Spinner size="sm" animation="grow" variant="light" />
            )}
            {!isFormSubmitting && (
              <span>
                <IntlMessages id="signin.Login" />
              </span>
            )}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default SignInForm;
