import { useMutation, useQuery } from "@apollo/react-hooks";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorDisplay from "@hfd/components/ErrorDisplay/ErrorDisplay";
import AmexLogo from "@hfd/components/Wallet/AmexLogo";
import DiscoverLogo from "@hfd/components/Wallet/DiscoverLogo";
import MasterLogo from "@hfd/components/Wallet/MasterCardLogo";
import VisaLogo from "@hfd/components/Wallet/VisaLogo";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useAuthDataContext } from "../../../../authProvider";
import {
  LOCAL_APP_STATE,
  UPDATE_CARDCODE,
  UPDATE_CARDMONTH,
  UPDATE_CARDNUMBER,
  UPDATE_CARDTYPE,
  UPDATE_CARDYEAR,
  UPDATE_NAMEONCARD,
  UPDATE_SAVEPAYMENT,
  UPDATE_USERPAYMENTAMOUNT
} from "../../../../localState/queries";
import ClientApiService from "../../../../services/clientApiService";

const OneTimePaymentCreditCardForm = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const { data } = useQuery(LOCAL_APP_STATE);
  const { user } = useAuthDataContext();
  const [setPaymentAmount] = useMutation(UPDATE_USERPAYMENTAMOUNT);
  const [updateNameOnCard] = useMutation(UPDATE_NAMEONCARD);
  const [updateCardType] = useMutation(UPDATE_CARDTYPE);
  const [updateCardNumber] = useMutation(UPDATE_CARDNUMBER);
  const [updateCardMonth] = useMutation(UPDATE_CARDMONTH);
  const [updateCardYear] = useMutation(UPDATE_CARDYEAR);
  const [updateCardCode] = useMutation(UPDATE_CARDCODE);
  const [updateSavePayment] = useMutation(UPDATE_SAVEPAYMENT);

  const [isAmountValid, setAmountValid] = useState(false);
  const [isAmountEnough, setAmountEnough] = useState(false);
  const [amountTooMuch, setAmountTooMuch] = useState(false);
  const { setSubmitting, continueClicked } = props;
  const [hasSubmit, setHasSubmit] = useState(false);
  const [isCardNameValid, setCardNameValid] = useState(false);
  const [isCardNumberValid, setCardNumberValid] = useState(false);
  const [isExpYearValid, setExpYearValid] = useState(false);
  const [isExpMonthValid, setExpMonthValid] = useState(false);
  const [isSecurityCodeValid, setSecurityCodeValid] = useState(false);
  const [errors, setErrors] = useState("");
  const [cardType, setCardType] = useState("");

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    const { paymentAmount, card } = data.localAppState.userData;
    if (paymentAmount) {
      validatePaymentAmount(paymentAmount);
    }

    if (card.nameOnCard) {
      validateCardName(card.nameOnCard);
    }

    if (card.cardNumber) {
      validateCardNumber(card.cardNumber);
    }

    if (card.expirationMonth) {
      validateExpMonth(card.expirationMonth);
    }

    if (card.expirationYear) {
      validateExpYear(card.expirationYear);
    }

    if (card.securityCode) {
      validateCardCode(card.securityCode);
    }
    // eslint-disable-next-line
  }, [data]);

  const doSubmit = async (values: any) => {
    setErrors("");
    setHasSubmit(true);
    validatePaymentAmount(values?.paymentAmount || "");

    if (amountTooMuch) {
      return setErrors("Oops, The payment amount can not exceed the amount financed.");
    }

    if (
      !isCardNumberValid ||
      !isCardNumberValid ||
      !isExpYearValid ||
      !isExpMonthValid ||
      !isSecurityCodeValid
    ) {
      return setErrors("Oops! Missing/Invalid Required fields.");
    }

    if (!isAmountValid) {
      return setErrors("Oops! Missing/Invalid Required fields.");
    }

    if (!isAmountEnough) {
      return setErrors("Oops, Minimum of $50 is required.");
    }

    const { applicationId, card } = data.localAppState.userData;
    var cardValidation = await new ClientApiService().validateCardBin(applicationId, card.cardNumber);
    if(!cardValidation.valid){
      return setErrors(cardValidation.message);
    }

    setSubmitting(true);

    if (cardType) {
      updateCardType({
        variables: { cardType: cardType },
        refetchQueries: [{ query: LOCAL_APP_STATE }],
      });
    }

    await sleep(500);
    setSubmitting(false);
    continueClicked();
  };

  const stripCurrency = (amount: string) => {
    return amount.replace("$", "");
  };

  const validatePaymentAmount = (amount: string) => {
    const numberAmount = stripCurrency(amount);
    const isValid = (numberAmount !== "" && (Number(numberAmount) >= 50 && Number(numberAmount) <= parseFloat(user.amountFinanced)));
    
    setAmountValid(isValid);
    setAmountEnough(Number(numberAmount) >= 50);
    setAmountTooMuch(Number(numberAmount) > parseFloat(user.amountFinanced));
  };

  const validateCardName = (cardName: string) => {
    const isValid = cardName.length > 0;
    setCardNameValid(isValid);
  };

  const validateCardNumber = (cardNumber: string) => {
    setCardType("");

    let formatted = cardNumber.split(" ").join("").slice(0, 16);
    if (formatted === undefined) {
      setCardNumberValid(false);
      return '';
    }

    if (formatted && formatted.length > 0) {
      // @ts-ignore
      formatted = formatted.match(/.{1,4}/g).join(" ");
    }

    const format = formatted.split(" ").join("");
    const visaPattern = /^(?:4[0-9]{15}(?:[0-9]{3})?)$/;
    const mastPattern = /^(?:5[1-5][0-9]{14})$/;
    const amexPattern = /^(?:3[47][0-9]{13})$/;
    const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

    const isVisa = visaPattern.test(format);
    const isMast = mastPattern.test(format);
    const isAmex = amexPattern.test(format);
    const isDisc = discPattern.test(format);

    if (isVisa) {
      setCardType("VISA");
    } else if (isMast) {
      setCardType("MASTER");
    } else if (isAmex) {
      setCardType("AMEX");
    } else if (isDisc) {
      setCardType("DISC");
    }

    if (isVisa || isMast || isAmex || isDisc) {
      setCardNumberValid(true);
    } else {
      setCardNumberValid(false);
    }

    return formatted;
  };

  const validateExpMonth = (expMonth: string) => {
    const isValid = expMonth.length > 0;
    setExpMonthValid(isValid);
  };

  const validateExpYear = (expYear: string) => {
    const isValid = expYear.length > 0;
    setExpYearValid(isValid);
  };

  const validateCardCode = (cardCode: string) => {
    const isValid = cardCode.length > 0;
    setSecurityCodeValid(isValid);
  };

  const formatAmount = (amount: string) => {
    const currency = "$";
    const newAmount = stripCurrency(amount);
    let formattedAmount = (
      parseInt(newAmount.split(".").join(""), 10) / 100
    ).toFixed(2);

    return currency + formattedAmount;
  };

  const handlePaymentAmount = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let paymentAmount = ev.target.value;

    if (isNaN(parseInt(stripCurrency(paymentAmount), 10))) {
      return;
    }

    validatePaymentAmount(paymentAmount);

    let formattedValue = formatAmount(paymentAmount);

    setFieldValue("paymentAmount", formattedValue, true);

    formattedValue = formattedValue.replace("$", "");
    setPaymentAmount({
      variables: { paymentAmount: formattedValue },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleCardName = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let cardName = ev.target.value;
    validateCardName(cardName);

    setFieldValue("cardName", cardName, true);

    updateNameOnCard({
      variables: { nameOnCard: cardName },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleCardNumber = (ev: any, setFieldValue: any) => {
    let cardNumber = ev.target.value;
    const formatted = validateCardNumber(cardNumber);
    setFieldValue("cardNumber", formatted, true);
    updateCardNumber({
      variables: { cardNumber: formatted },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const hideCardNumber = (ev: any, setFieldValue: any) => {

  };


  const handleExpMonth = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let month = ev.target.value.slice(0, 2);
    let nMonth = Number(month);

    if (nMonth > 12) {
      nMonth = 12;
    }
    setExpMonthValid(nMonth <= 12);
    setFieldValue("expMonth", month, true);
    updateCardMonth({
      variables: { expirationMonth: month },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleExpYear = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let year = ev.target.value.slice(0, 4);
    const date = new Date();
    const fullYear = date.getFullYear();
    setExpYearValid(Number(year) >= fullYear);

    setFieldValue("expYear", year, true);
    updateCardYear({
      variables: { expirationYear: year },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleSecurityCode = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const cardCodeLength = (cardType === 'AMEX') ? 4 : 3;
    let sc = ev.target.value.slice(0, cardCodeLength);
    setSecurityCodeValid(sc.length > 0);

    setFieldValue("securityCode", sc, true);
    updateCardCode({
      variables: { securityCode: sc },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleSavePayment = (
      ev: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: any
  ) => {
    let sc = ev.target.checked;
    setFieldValue("savePayment", sc, true);
    updateSavePayment({
      variables: { savePayment: sc },
      refetchQueries: [{ query: LOCAL_APP_STATE }]
    });
  };

  const handleBackClicked = () => {
    const params = new URLSearchParams(location.search);
    const lang = params.get("lang") || "";
    let makeOtpUrl = "makeotp";

    if (lang.length > 0){
      makeOtpUrl = `${makeOtpUrl}?lang=${lang}`;
    }
    
    props.cancelClearClicked();

    history.push(makeOtpUrl);
  };

  return (
    <Formik
      initialValues={{
        paymentAmount: data.localAppState.userData.paymentAmount || "",
        cardName:  data.localAppState.userData.card.nameOnCard || "",
        cardNumber:  data.localAppState.userData.card.cardNumber || "",
        expMonth:  data.localAppState.userData.card.expirationMonth || "",
        expYear:  data.localAppState.userData.card.expirationYear || "",
        securityCode:  data.localAppState.userData.card.securityCode || "",
        savePayment: data.localAppState.userData.savePayment || false
      }}
      validate={async (values) => {}}
      onSubmit={doSubmit}
    >
      {({ values, handleSubmit, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="application-form-mobile">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group
                controlId="paymentAmount"
                className="application-form-field"
              >
                <Form.Label>
                  <IntlMessages id="otp.EnterPaymentAmount" />
                </Form.Label>
                <Form.Control
                  placeholder="$0.00"
                  style={{ textAlign: "right" }}
                  className="placeholder-grey"
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    handlePaymentAmount(ev, setFieldValue)
                  }
                  isValid={isAmountValid && isAmountEnough}
                  isInvalid={
                    (!isAmountValid || !isAmountEnough) &&
                    (values.paymentAmount.length > 0 || hasSubmit)
                  }
                  value={values.paymentAmount}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
            <>
              <p className="ml-1 application-form">
                <IntlMessages id="otp.CardAccepted" />{" "}
              </p>
              <div className="cards-accepted">
                {
                  cardType === "VISA" ? <VisaLogo /> :
                  cardType === "MASTER" ? <MasterLogo /> :
                  cardType === "AMEX" ? <AmexLogo /> :
                  cardType === "DISC" ? <DiscoverLogo /> :
                    <>
                      <VisaLogo />
                      <MasterLogo />
                      <AmexLogo />
                      <DiscoverLogo />
                    </>
                }
              </div>
            </>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group
                controlId="cardName"
                className="application-form-field"
              >
                <Form.Label>
                  <IntlMessages id="otp.NameOnCard" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={values.cardName}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    handleCardName(ev, setFieldValue)
                  }
                  isValid={isCardNameValid}
                  isInvalid={!isCardNameValid && values.cardName.length > 0}
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group
                controlId="cardNumber"
                className="application-form-field card-number"
              >
                <Form.Label>
                  <IntlMessages id="otp.CardNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={values.cardNumber}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    handleCardNumber(ev, setFieldValue)
                  }
                  onBlur={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    hideCardNumber(ev, setFieldValue)
                  }
                  isValid={isCardNumberValid}
                  isInvalid={!isCardNumberValid && values.cardNumber.length > 0}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Label className="application-form-label">
                <IntlMessages id="otp.ExpDate" />
              </Form.Label>
              <Row xs={2}>
                <Col>
                  <Form.Control
                    id="expMonth"
                    type="number"
                    placeholder="Month"
                    className="application-form-field"
                    value={values.expMonth}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleExpMonth(ev, setFieldValue)
                    }
                    isValid={isExpMonthValid}
                    isInvalid={!isExpMonthValid && values.expMonth.length > 0}
                  />
                </Col>
                <Col>
                  <Form.Control
                    id="expYear"
                    type="number"
                    placeholder="Year"
                    className="application-form-field"
                    value={values.expYear}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleExpYear(ev, setFieldValue)
                    }
                    isValid={isExpYearValid}
                    isInvalid={!isExpYearValid && values.expYear.length > 0}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={12}>
              <Form.Label className="application-form-label">
                <IntlMessages id="otp.SecurityCode" />
              </Form.Label>
              <Row xs={2}>
                <Col xs={4}>
                  <Form.Control
                    type="number"
                    value={values.securityCode}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleSecurityCode(ev, setFieldValue)
                    }
                    isValid={isSecurityCodeValid}
                    isInvalid={
                      !isSecurityCodeValid && values.securityCode.length > 0
                    }
                  />
                </Col>
                <Col xs={5} className="security-info">
                  <FontAwesomeIcon icon={faCreditCard} />
                  <p>
                    <IntlMessages id="otp.LastThree" />
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="save-checkbox mt-2">
            <input
              id="savePayment"
              name="savePayment"
              type="checkbox"
              value={values.savePayment}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                  handleSavePayment(ev, setFieldValue)
              }
            />
            <label htmlFor="savePayment">
              <IntlMessages id="otp.StoreCard" />
            </label>
          </div>

          <div className="error-container otp-error">
            {errors && <ErrorDisplay errorMessage={errors} small={true} />}
          </div>

          <div className="d-flex justify-content-center my-4">
            <Button
              className="makepayment-cancel-button"
              onClick={handleBackClicked}
            >
              <IntlMessages id={`otp.Back`} />
            </Button>
            <Button className="makepayment-continue-button" type="submit">
              <IntlMessages id="otp.Continue" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OneTimePaymentCreditCardForm;
