import React from "react";

type ach = {
  bankName: string;
  accountNumber: string;
  accountName: string;
};

type WalletBankAccountProps = {
  ach: ach;
};

const WalletBankAccount = (props: WalletBankAccountProps) => {
  const { ach } = props;
  return (
    <div className={`hfd-card hfd-ach`}>
      {/* {ach.isPrimary && (
        <div className="priority-indicator">
          <span className="primary">Primary</span>
        </div>
      )} */}
      {/* {!props.card.isPrimary && props.card.priority === 2 && (
            <span className="secondary">Secondary</span>
          )}{" "}
        </div> */}
      <div className="card-logo">{ach.bankName}</div>
      <div className="card-number">{ach.accountNumber}</div>
      <div className="card-name">{ach.accountName}</div>
    </div>
  );
};

export default WalletBankAccount;
