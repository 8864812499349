import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "@hfd/components/utility/pageTitle";
import IntlMessages from "@hfd/components/utility/IntlMessages";

import SettingsAccount from "./Components/SettingsAccount";
import SettingsPaymentPlan from "./Components/SettingsPaymentPlan";
import SettingsSecurity from "./Components/SettingsSecurity";
import SettingsTOS from "./Components/SettingsTOS";

const SettingsDesktop = ({
  user,
  textEditable,
  changeSetDefault,
  changeEditMode,
  handleSaveValue,
}: any) => {
  return (
    <Container fluid className="no-padding px-5">
      <Row noGutters className="mb-4">
        <Col>
          <Title>
            <h1 className="page-title">
              <IntlMessages id="settings.Title" />
            </h1>
          </Title>
        </Col>
      </Row>
      <Row noGutters xs={1} sm={1} md={1}>
        <Col className="mb-4">
          <SettingsAccount user={user} />
        </Col>
        <Col className="mb-4">
          <SettingsPaymentPlan
            user={user}
            textEditable={textEditable}
            changeSetDefault={changeSetDefault}
            changeEditMode={changeEditMode}
            handleSaveValue={handleSaveValue}
          />
        </Col>
        <Col className="mb-4">
          <SettingsSecurity />
        </Col>
        <Col>
          <SettingsTOS />
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsDesktop;
