import React from "react";
//import { ConfigProvider } from "antd";
// @ts-ignore
import { IntlProvider } from "react-intl";
// @ts-ignore
import AppLocale from "@hfd/config/translation";

export default function AppProvider(props: any) {
  let locale = "en";
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let lang = params.get("lang");

  if (lang) {
    locale = lang;
  }

  // TODO Refactor this once more Typescript has been converted
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      {props.children}
    </IntlProvider>
  );
}
