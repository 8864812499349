import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {Row, Col, Button} from "react-bootstrap";
import Moment from "react-moment";
import FormattedCcNumber from "@hfd/components/utility/FormattedCcNumber";
import CcLogo from "@hfd/components/utility/CcLogo";
import OneTimePaymentInfo from "./OneTimePaymentInfo";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import OneTimePaymentAmountDetails from "./OneTimePaymentAmountDetails";
import {LOCAL_APP_STATE} from "../../../../localState/queries";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";

const OneTimePaymentConfirmMobile = (props: any) => {
  const {data} = useQuery(LOCAL_APP_STATE);

  const CheckName = (props: any) => {
    return (
        <>
          <br/>
          <Col className="d-flex">
            <div className="check-container">
              <div className="check-icon">
                <div className={'icon ok'}/>
              </div>
              <div className="check-name">
                <IntlMessages id={`makePayment.${props.checkName}`}/>
              </div>
            </div>
          </Col>
          <Col>{props.children}</Col>
        </>
    );
  };

  const renderDate = () => {
    return <Moment format="MMM Do, YYYY">{new Date()}</Moment>;
  };

  return (props.isSubmitting) ? (
      <div className="loader">
        <br/>
        <HFDBarLoader size="full"/>
      </div>
  ) : (
      <>
        <div className="my-3">
          <div className="px-2">
            <OneTimePaymentAmountDetails cancel={props.cancelClicked}/>
          </div>
        </div>

        <Row sm={2} className="px-3">
          <CheckName checkName="Method">
            <div className="check-title">
              <IntlMessages id={`payment.methodCardOrDebit`}/>
            </div>

            <div className="check-subtitle"/>
          </CheckName>
        </Row>
        <Row sm={2} className="px-3">
          <CheckName checkName="Details">
            <div className="check-title">
              <div className="check-card-img"><CcLogo cardType={data.localAppState.cardType}/></div>
              <div className="check-card-img-text"><IntlMessages id={`payment.EndingIn`}/> <FormattedCcNumber
                  number={data.localAppState.userData.card.cardNumber}/></div>
            </div>

            <div className="check-subtitle"/>
          </CheckName>
        </Row>
        <Row sm={2} className="px-3">
          <CheckName checkName="Date">
            <div className="check-title">{renderDate()}</div>

            <div className="check-subtitle">
              <IntlMessages id={`makePayment.DateSubtitle`}/>
            </div>
          </CheckName>
        </Row>
        <Row className="my-4">
          <Col className="d-flex justify-content-center">
            <Button
                className="makepayment-another-button"
                onClick={props.sendPayment}
            >
              <IntlMessages id={`otp.Submit`}/>
            </Button>
          </Col>
        </Row>

        <OneTimePaymentInfo current={true} makeOTP={true} contact={true}/>
      </>
  );
};

export default OneTimePaymentConfirmMobile;
