import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const PaymentDocument = () => {
  return (
    <Card className="payment-card pb2">
      <div className="payment-card-body p-4">
        <h6>Documents</h6>
        <div className="outline-link-button">
          <div className="olb-title">Credit Report</div>
          <div className="olb-icon-button">
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </div>
        <div className="outline-link-button">
          <div className="olb-title">Installment Agreement</div>
          <div className="olb-icon-button">
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PaymentDocument;
