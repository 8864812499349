import React, {createContext, useState, useEffect, useContext} from "react";
import axios from 'axios';
import {getLoggedIn} from "./managers/AuthManager";
import {history} from "./router";
import {GET_USER_QUERY} from "./queries/userQuery";
import {useApolloClient} from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import { Config } from './ClientConfig';
import {
  UPDATE_USERAPPLICATIONID,
  UPDATE_USERZIPCODE,
  LOCAL_APP_STATE,
  RESET_MODEL
} from "./localState/queries";

type User = any;

export const AuthDataContext = createContext<User>({});

const initialAuthData = {};

const serviceUrl = `${Config.Integrations.StandardizedAPI}AnonymousOtp`;
const forgotAppIdUrl = `${Config.Integrations.StandardizedAPI}ForgotAppId`;

const AuthDataProvider = (props: any) => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [user, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setApplicationId] = useMutation(UPDATE_USERAPPLICATIONID);
  const [setUserZip] = useMutation(UPDATE_USERZIPCODE);
  const [resetModel] = useMutation(RESET_MODEL);
  
  let client = useApolloClient();
  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect(() => {
    const currentAuthData = getLoggedIn();

    async function getLoggedInUser() {
      let userQuery = await axios.get(serviceUrl, {
        params: {applicationid: currentAuthData.appId, zipCode: currentAuthData.zipCode}
      });

      setLoggedInUser(userQuery.data);
      setAuthData(currentAuthData);

      setApplicationId({
        variables: { applicationId: currentAuthData.appId },
        refetchQueries: [{ query: LOCAL_APP_STATE }],
      });

      setUserZip({
        variables: { zipCode: currentAuthData.zipCode },
        refetchQueries: [{ query: LOCAL_APP_STATE }],
      });
    }

    if (currentAuthData) {
      setLoading(true);
      getLoggedInUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authData, client]);

  const onLogout = () => {
    resetModel({
      variables: {},
      refetchQueries: [{query: LOCAL_APP_STATE}]
    });

    setAuthData(initialAuthData);
    setLoggedInUser(null);
  };

  const onForgotAppId = async (data: any, callback: any) => {
    await axios.post(`${forgotAppIdUrl}?contact=${data.contact}&type=${data.type}`, {});

    callback();
  };

  const onSendPayment = async (data: any, callback: any) => {
    try {
      const response = await axios.post(serviceUrl, data);
      callback(response.data);
    }
    catch (err:any) {
      callback({ approved: false });
    }
  };

  const onOtpLogin = async (newAuthData: any, callback: any) => {
    sessionStorage.setItem("user", JSON.stringify(newAuthData));

    try {
      let userQuery = await axios.get(serviceUrl, {
        params: {applicationid: newAuthData.appId, zipCode: newAuthData.zipCode}
      });
      setLoggedInUser(userQuery.data);
      setAuthData(newAuthData);
      callback(true);
    }
    catch (err) {
      callback(false);
    }
  };

  const onLogin = async (newAuthData: any) => {
    // UPDATE AFTER APOLLO
    sessionStorage.setItem("user", JSON.stringify(newAuthData));
    let userQuery = await client.query({
      query: GET_USER_QUERY,
      variables: {
        appId: newAuthData.appId,
      },
    });
    setLoggedInUser(userQuery.data);
    setAuthData(newAuthData);
    history.push("/user/dashboard");
  };

  return (
      <AuthDataContext.Provider
          value={{authData, onLogin, onOtpLogin, onForgotAppId, onSendPayment, onLogout, user, loading}}
          {...props}
      />
  );
};

export const useAuthDataContext = () => {
  return useContext(AuthDataContext);
};

export default AuthDataProvider;
