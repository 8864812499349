import React, { ReactNode } from "react";
import { getCardType } from "../../library/helpers/cardUtilities";
import IntlMessages from "../utility/IntlMessages.tsx";

import VisaLogo from "./VisaLogo";
import MasterCardLogo from "./MasterCardLogo";
import AmexLogo from "./AmexLogo";

type CardProps = {
  card: any;
  children: ReactNode;
};

const WalletCard = (props: any) => {
  // TODO - Need to get icons/colors/etc
  // Need to Add Tests, especially around
  // The Substring in here.
  let cardType = getCardType(props.card.cardNumber);

  const displayCardLogo = (cardName: string) => {
    if (cardName === "VISA") {
      return <VisaLogo />;
    } else if (cardName === "MASTER") {
      return <MasterCardLogo />;
    } else {
      return <AmexLogo />;
    }
  };

  return (
    <div className={`hfd-card ${cardType}`}>
      <div className="card-type">
        <div
          className={
            props.card.isPrimary ? "card-logo has-priority" : "card-logo"
          }
        >
          {displayCardLogo(cardType)}
        </div>
        {props.card.isPrimary && (
          <div className="priority-indicator">
            <span className="primary">
              <IntlMessages id="wallet.PrimaryIndicator" />
            </span>
          </div>
        )}
      </div>

      {/* {!props.card.isPrimary && props.card.priority === 2 && (
          <span className="secondary">Secondary</span>
        )}{" "}
      </div> */}
      <div className="card-info">
        <div className="card-name">{props.card.cardName}</div>
        <div className="card-number">
          **** **** **** {props.card.cardNumber.substr(12, 4)}
        </div>
        <div className="card-exp">{props.card.expiration}</div>
      </div>

      {props.children}
    </div>
  );
};

export default WalletCard;
