import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "@hfd/components/utility/pageTitle";
import IntlMessages from "@hfd/components/utility/IntlMessages";

import ProfileBasicInfo from "./Components/ProfileBasicInfo";
import ProfileNotificationPreferences from "./Components/ProfileNotifiicationPreferences";
import ProfileUpdatePassword from "./Components/ProfileUpdatePassword";
import ProfileNewPasswordForm from "./Components/ProfileNewPasswordForm";
import SlideOutSidebar from "@hfd/components/SlideOutSidebar/SlideOutSidebar";

import "./scss/Profile.css";


const ProfileDesktop = ({ user }:any) => {
  const [isPasswordDrawerOpen, setPasswordDrawer] = useState(false);
  const [hasNewPassword, setHasNewPassword] = useState(false);

  const handleDrawer = () => {
    setPasswordDrawer((prevState) => !prevState);
  };

  if (hasNewPassword) {
    return <ProfileNewPasswordForm />;
  }

  return (
    <Container fluid className="no-padding px-5">
      <SlideOutSidebar show={isPasswordDrawerOpen} handleDrawer={handleDrawer}>
        <>
          <ProfileUpdatePassword
            handleDrawer={handleDrawer}
            setHasNewPassword={setHasNewPassword}
          />
        </>
      </SlideOutSidebar>
      <Row noGutters className="mb-4">
        <Col>
          <Title>
            <h1 className="page-title">
              <IntlMessages id="profile.Title" />
            </h1>
          </Title>
        </Col>
      </Row>
      <Row noGutters xs={1} sm={1} md={2}>
        <Col className="mb-4">
          <ProfileBasicInfo
            userInfo={user}
            handleDrawer={handleDrawer}
            isDrawerOpen={isPasswordDrawerOpen}
          />
        </Col>
      </Row>
      <Row noGutters xs={1} sm={1} md={2}>
        <Col className="mb-4">
          <ProfileNotificationPreferences />
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileDesktop;
