import React, { useState } from "react";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import OneTimePaymentCreditCardForm from "./OneTimePaymentCreditCardForm";

const OneTimePaymentMethodDesktop = (props: any) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return isSubmitting ? (
    <div className="loader">
      <br/>
      <HFDBarLoader size="full" />
    </div>
  ) : (
    <div className="my-3">
      <br/>
      <OneTimePaymentCreditCardForm
        setSubmitting={setSubmitting}
        continueClicked={props.continueClicked}
        cancelClearClicked={props.cancelClearClicked}
      />
    </div>
  );
};

export default OneTimePaymentMethodDesktop;
