import React from "react";

import { InMemoryCache } from "apollo-boost";
import ApolloClient from "apollo-boost";
import AppProvider from "./AppProvider";
import AuthDataProvider from "./authProvider";
import { ApolloProvider } from "@apollo/react-hooks";
import SizeWrapper from "./wrappingComponents/sizeWrapper";
import resolvers from "./localState/resolvers";
import typeDefs from "./localState/lsTypeDefs";

const cache = new InMemoryCache({
  freezeResults: true,
});
//http://dev-clientapi.hfd.com/graphql
//  uri: process.env.REACT_APP_GRAPHQL_URI,
const client = new ApolloClient({
  uri: "https://dev-clientapi.healthcarefinancedirect.com/graphql",
  cache,
  typeDefs,
  resolvers,
});

export default (props: any) => {
  return (
    <>
      <ApolloProvider client={client}>
        <SizeWrapper>
          <AuthDataProvider
            authData={props.mockAuthData ? props.mockAuthData : null}
          >
            <AppProvider>{props.children}</AppProvider>
          </AuthDataProvider>
        </SizeWrapper>
      </ApolloProvider>
    </>
  );
};
