import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import Card from "@hfd/components/utility/card";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import FormattedIntlDate from "@hfd/components/utility/FormattedIntlDate";
import DashboardAlertIcon from "./DashboardAlertIcon";

const ACTStatus = () => {
  return (
    <>
      <Row noGutters xs={1} sm={1} md={2}>
        <Col>
          <div style={{ textAlign: "center" }}>
            <DashboardAlertIcon pastDue={false} />
            <p className="mb-3 circular-book" style={{ fontSize: "1.2rem" }}>
              No payment is required at this time.
            </p>
          </div>
        </Col>
        <Col className="d-flex justify-content-center">
          <Image
            src="https://global-content-cf.s3-us-west-2.amazonaws.com/HFD.Customer/images/Treating+Patients.png"
            width="198px"
            fluid
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <Button variant="outline-primary hfd-primary-button">
            <IntlMessages id="home.ButtonPayDownPrincipal" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

const LATEStatus = ({ amountPastDue, nextPaymentDate }: any) => {
  return (
    <>
      <Row noGutters xs={1} sm={1} md={2}>
        <Col className="mb-3">
          <div style={{ textAlign: "center" }}>
            <DashboardAlertIcon pastDue={true} />
          </div>
        </Col>
        <Col className="mb-3">
          <div className="text-center">
            <p className="hfd-past-due" style={{ fontSize: "1.4rem" }}>
              Your account is Past Due!
            </p>
            <p className="hfd-missed circular-book">
              Amount Due: <FormattedCurrency amount={amountPastDue} />
            </p>
            <p className="circular-book">
              Pay past due amount before{" "}
              {nextPaymentDate && <FormattedIntlDate date={nextPaymentDate} />}
              {!nextPaymentDate && (
                <FormattedIntlDate date={`${new Date()}`} />
              )}{" "}
              to avoid fees.
            </p>
          </div>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <div className="text-center">
            <button className="hfd-past-due-button" tabIndex={0}>
              Pay Past Due Balance
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const DashboardPaymentWidget = ({ financials }: any) => {
  return (
    <Card title="Payment Status">
      {financials.paymentStatus !== "ACT" ? (
        <LATEStatus
          amountPastDue={financials.currentAmountPastDue}
          nextPaymentDate={financials.nextPaymentDate}
        />
      ) : (
        <ACTStatus />
      )}
    </Card>
  );
};

export default DashboardPaymentWidget;
