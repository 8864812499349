import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";
import HfdButton from "@hfd/components/Button/HfdButton";
import HfdRadio from "@hfd/components/Radio/HfdRadio";
import HfdEditIcon from "@hfd/components/EditIcon/HfdEditIcon";
import HfdIconButton from "@hfd/components/IconButton/HfdIconButton";
import { LOCAL_APP_STATE } from "../../../../localState/queries";
import { useQuery } from "@apollo/react-hooks";

const SettingsPaymentPlan = ({
  user,
  textEditable,
  changeSetDefault,
  changeEditMode,
  handleSaveValue,
}: any) => {
  const { data } = useQuery(LOCAL_APP_STATE);

  const renderEditView = () => {
    return (
      <Row>
        <Col xs={12} sm={5} md={5}>
          <Form.Control type="text" placeholder={textEditable.value} />
        </Col>

        <Col xs={12} sm={5} md={5} className={"radio-item"}>
          <HfdRadio
            id={"set-default"}
            label={"Set as Default"}
            name={"set-default"}
            onChange={changeSetDefault}
            value={textEditable.isDefault}
          />
        </Col>

        <Col xs={2} sm={2} md={2}>
          <HfdIconButton
            action={changeEditMode}
            color={"grey"}
            name={"close-sharp"}
            icon={"close-sharp"}
          />
        </Col>

        <Col xs={2} sm={2} md={2}>
          <HfdIconButton
            action={handleSaveValue}
            color={"blue"}
            name={"checkmark-sharp"}
            icon={"checkmark-sharp"}
          />
        </Col>
      </Row>
    );
  };

  const renderDefaultView = () => {
    return (
      <>
        <p>
          {textEditable.value}{" "}
          {data.localAppState.isMobile ? null : (
            <HfdEditIcon label="edit-mode" action={changeEditMode} />
          )}
        </p>
      </>
    );
  };

  const planDesktop = () => {
    return (
      <Row noGutters className="mb-4">
        <Col xs={5} sm={5} md={3} className="d-flex align-items-center">
          <p className="hfd-primary2">Plan #{user.applicationId}</p>
        </Col>
        <Col xs={5} sm={5} md={4}>
          <Row>
            {textEditable.isInEditMode ? renderEditView() : renderDefaultView()}
          </Row>
        </Col>
      </Row>
    );
  };

  const planMobile = () => {
    return (
      <Row xs={12} className="mb-4">
        <Col xs={10}>
          {textEditable.isInEditMode ? renderEditView() : renderDefaultView()}
          <p
            className={data.localAppState.isMobile ? "plan-id" : "hfd-primary2"}
          >
            Plan #{user.applicationId}
          </p>
        </Col>
        <Col>
          <HfdEditIcon label="edit-mode" action={changeEditMode} />
        </Col>
      </Row>
    );
  };

  return (
    <Form className="settings-payment-plan">
      <Card>
        <Row noGutters xs={12} sm={12} md={12} className="mb-2">
          <Col>
            <p className="hfd-primary1">
              <IntlMessages id="settings.PaymentPlans" />
            </p>
          </Col>
        </Row>
        {data.localAppState.isMobile ? planMobile() : planDesktop()}
        <Row>
          <Col className="d-flex justify-content-center">
            <HfdButton label={"Add Existing Payment Plan"} />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default SettingsPaymentPlan;
