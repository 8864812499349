import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PaymentStatusAlert from "./PaymentStatusAlert";
import PaymentSummary from "./PaymentSummary";

const PaymentPlanDetailsMobile = ({ financials, user }: any) => {
  return (
    <Container className="payment-plan-inner">
      <Row className="mt-4 pt-2">
        <Col>
          <h2>{financials.paymentPlanName}</h2>
          <p className="app-id">{user.applicationId}</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <PaymentStatusAlert financials={financials} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <PaymentSummary financials={financials} user={user} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPlanDetailsMobile;
