import React, { useState } from "react";
import "../styles/header/mobileHeader.css";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Title from "@hfd/components/utility/pageTitle";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileHeader = ({ user, link, title, titleDescription }: any) => {
  const [dataScroll, setDataScroll] = useState(0);
  document.addEventListener("scroll", () => setDataScroll(window.scrollY));

  return (
    <Container fluid className="no-padding heading" data-scroll={dataScroll}>
      <Row noGutters className="mb-4">
        <Col>
          <div className="toolbar">
            <div className="logo">
              <HFDLogoWithText color="white" size="small" />
            </div>
            <div className="menu">
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
          <Title>
            <div className="page-title">
              <IntlMessages id={title} />
              {user && (
                <>
                  {user.firstName} {user.lastName}!
                </>
              )}
            </div>
            <div className="page-subtitle">
              <IntlMessages id={titleDescription} />
            </div>
          </Title>

          <div className="mobile-nav-container">
            <NavLink to={link[0].link}>
              <div className="button-icon">
                <FontAwesomeIcon icon={link[0].icon} />
              </div>
              <div className="icon-txt">{link[0].iconLabel} </div>
            </NavLink>

            <NavLink to={link[1].link}>
              <div className="button-icon">
                <FontAwesomeIcon icon={link[1].icon} />
              </div>
              <div className="icon-txt">{link[1].iconLabel} </div>
            </NavLink>

            <NavLink to={link[2].link}>
              <div className="button-icon">
                <FontAwesomeIcon icon={link[2].icon} />
              </div>
              <div className="icon-txt">{link[2].iconLabel} </div>
            </NavLink>

            <NavLink to={link[3].link}>
              <div className="button-icon">
                <FontAwesomeIcon icon={link[3].icon} />
              </div>
              <div className="icon-txt">{link[3].iconLabel}</div>
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MobileHeader;
