import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import StepBarNavigator from "@hfd/ui/StepBarNavigator/StepBarNavigator";

import "./scss/OneTimePaymentDesktop.css";
import OTPSingleNavBar from "../../../components/desktop/OTPSingleNavBar";
import Card from "react-bootstrap/Card";
import OneTimePaymentInfo from "./components/OneTimePaymentInfo";
import OneTimePaymentAccountDetails from "./components/OneTimePaymentAccountDetails";

const OneTimePaymentDesktop = ({
  getPaymentSteps,
  getMobileStepText,
  currentStep,
  getCurrentStep,
  cancelClicked,
}: any) => {
  return (
    <Container style={{ paddingTop: "100px" }}>
      <Row>
        <Col xs={currentStep === 4 ? 12 : 8} sm={currentStep === 4 ? 12 : 8} md={currentStep === 4 ? 12 : 8}>
          <Card className="desktop-header-round">
            <OTPSingleNavBar />
            <Container className="desktop-header-round">
              <Row
                className={"desktop-header desktop-header-round square-bottom"}
              >
                {currentStep === 4 ? null : (
                  <>
                    <Col
                      sm={6}
                      md={6}
                      lg={6}
                      xs={6}
                      className={"desktop-header-round"}
                    >
                      <h4 className="page-title desktop">
                        <IntlMessages id="makePayment.TitleDesktop" />
                      </h4>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                {currentStep === 4 ? null : (
                  <Col sm={12} md={12} lg={12} xs={12}>
                    <h4 className="page-title mobile">{getMobileStepText()}</h4>
                  </Col>
                )}
              </Row>
              <Row>
                {currentStep === 4 ? null : (
                  <Col className="px-5" sm={12} md={12} lg={12} xs={12}>
                    <StepBarNavigator steps={getPaymentSteps()} />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} xs={12} className={'no-padding'}>
                  {getCurrentStep()}
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
        {currentStep !== 4 && <Col xs={4} sm={4} md={4}>
          <Row>
            <Col>
              {currentStep === 0 ? null : (
                <div className="px-2">
                  <OneTimePaymentAccountDetails
                      cancel={cancelClicked}
                      currentStep={currentStep}
                      isDashboard={true}
                  />
                  <br />
                  <br />
                </div>
              )}
              {currentStep !== 0 ? null : (
                <OneTimePaymentInfo
                  current={true}
                  pastDue={true}
                  makeOTP={false}
                  contact={false}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <OneTimePaymentInfo
                current={false}
                pastDue={false}
                makeOTP={true}
                contact={true}
              />
            </Col>
          </Row>
        </Col>}
      </Row>
    </Container>
  );
};

export default OneTimePaymentDesktop;
