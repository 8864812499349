import React from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HFDBarLogo from "@hfd/components/utility/HFDBarLoader";
import HFDActionButton from "@hfd/ui/UI/HFDIcons/HFDActionButton";
import WalletCard from "@hfd/components/Wallet/WalletCard";

const PaymentMethodWidgetMobile = (props: any) => {
  console.log("Payment Method Widget Mobile");
  const walletLoading = false;
  const cards = [
    {
      cardNumber: "4111111111111111",
      cardName: "My Primary Card",
      isPrimary: true,
      priority: 1,
      cardId: 1,
      expiration: "04/25",
    },
    {
      cardNumber: "5111111111111111",
      cardName: "My Secondary Card",
      isPrimary: false,
      priority: 2,
      cardId: 2,
      expiration: "01/22",
    },
    {
      cardNumber: "347111111111111",
      cardName: "My Amex Card",
      isPrimary: false,
      priority: 3,
      cardId: 3,
      expiration: "10/24",
    },
    {
      cardNumber: "6011000000000004",
      cardName: "My Discover Card",
      isPrimary: false,
      priority: 3,
      cardId: 4,
      expiration: "10/24",
    },
  ];

  const nextClicked = () => {
    props.onNextClick(cards[0]);
  };

  const AllCards = () => {
    console.log(cards);
    return cards.map((card: any, index: number) => {
      return (
        // <li key={card.cardId} className="mobile-wallet-card">
        //   <div
        //     onClick={() => setSelectedCardIndex(index)}
        //     className={
        //       index === selectedCardIndex
        //         ? "active mobile-payment-wallet-card"
        //         : "mobile-payment-wallet-card"
        //     }
        //   >
        //     <div className="card-type">
        //       <div className="card-logo">{getCardLogo(cardType)}</div>
        //       {card.priority === 1 && (
        //         <div className="primary-card">Primary</div>
        //       )}
        //     </div>
        //     <div className="card-entry-details">
        //       <span> {card.cardName}</span>
        //       <span>
        //         **** **** ****{" "}
        //         {card.cardNumber.substring(card.cardNumber.length - 4)}
        //       </span>
        //       <span>{card.expiration}</span>
        //     </div>
        //   </div>
        //   <div className="next-mobile">
        //     <div className="next-button" onClick={() => nextClicked()}>
        //       <HFDActionButton selected={false} />
        //     </div>
        //   </div>
        // </li>
        <li key={card.cardId} className="mobile-wallet-card">
          <WalletCard card={card}>
            <div className="next-mobile">
              <div className="next-button" onClick={() => nextClicked()}>
                <HFDActionButton selected={false} />
              </div>
            </div>
          </WalletCard>
        </li>
      );
    });
  };

  if (walletLoading) {
    return <HFDBarLogo size="large" />;
  }
  console.log("wtf");
  return (
    <div>
      <div className="my-cards">
        <ul className="mobile-cards">{AllCards()}</ul>

        <div className="under-widget-link-container">
          <div className="btn btn-grey">
            <FontAwesomeIcon icon={faPlus} />{" "}
            <IntlMessages id="makePayment.AddPaymentMethod" />
          </div>
        </div>
      </div>

      {/* <div className="buttons-container">
        <Button className="right continue" onClick={() => nextClicked()}>
          <IntlMessages id="generic.Continue" />{" "}
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        <Button
          className="right hollow back"
          onClick={() => props.onBackClick()}
        >
          <IntlMessages id="generic.Back" />
        </Button>
      </div> */}
    </div>
  );
};

export default PaymentMethodWidgetMobile;
