import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import FormattedIntlDate from "@hfd/components/utility/FormattedIntlDate";
import React from "react";
//import IntlMessages from "@hfd/components/utility/intlMessages";
import { Col, Row } from "react-bootstrap";

const statusColor = {
  paid: "#00B189",
  missed: "#DD4026",
};

const DashboardPaymentStatusItem = ({
  date,
  status,
  paymentType,
  amount,
}: any) => {
  return (
    <Row
      md={4}
      className="mb-3"
      style={{
        color: status === "Paid" ? "" : statusColor.missed,
      }}
    >
      <Col>
        <FormattedIntlDate date={date} />
      </Col>
      <Col
        style={{
          color: status === "Paid" ? statusColor.paid : statusColor.missed,
        }}
      >
        {status}
      </Col>
      <Col>{paymentType}</Col>
      <Col>
        <FormattedCurrency amount={amount} />
      </Col>
    </Row>
  );
};

const DashboardPaymentStatus = (props: any) => {
  const financials = props.financials;
  const paymentActivity = financials.ledgerActivity
    .filter((ld: any) => {
      if (ld.feeCode === "7300") {
        return ld;
      }
      return null;
    })
    .sort((a: any, b: any) => {
      // @ts-ignore
      return new Date(b.distroDate) - new Date(a.distroDate);
    })
    .slice(0, 4);
  return (
    <>
      {paymentActivity.map((ld: any) => (
        <DashboardPaymentStatusItem
          date={ld.distroDate}
          status={"Paid"}
          paymentType={"Automatic Payment"}
          amount={ld.totalAmount}
          key={ld.distroDate}
        />
      ))}
    </>
  );
};

export default DashboardPaymentStatus;
