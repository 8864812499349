import React from "react";
import SignInMobile from "./SignInMobile";
import SignInDesktop from "./SignInDesktop";
import { useQuery } from "@apollo/react-hooks";

import { LOCAL_APP_STATE } from "../../../localState/queries";

export default function () {
  const { loading, data } = useQuery(LOCAL_APP_STATE);
  const isMobile = data.localAppState.isMobile;
  if (loading) return <div>Loading</div>;
  return isMobile ? <SignInMobile /> : <SignInDesktop />;
}
