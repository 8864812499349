import axios from "axios";

interface BasicAccountDetails {
  applicationId: Number;
  firstName: String;
  lastName: String;
  payoffAmount: Number;
  totalAmountDue: Number;
  paymentStatus: String;
  amountFinanced: Number;
  paidToDate: Number;
  principalBalance: Number;
  paymentsRemaining: Number;
  estimatedPayoffDate: Date;
  authorized: Boolean;
  message: String;
  promotionPlanDate: Date | null;
  promotionPayOffAmount: Number;
  promotionFees: Number;
}

class AnonymoustOTPService {
  validateAppAndZipCode = async (
    applicationId: number,
    zipCode: number
  ): Promise<BasicAccountDetails> => {
    try {
      var resp = await axios.get<BasicAccountDetails>(
        `${process.env.REACT_APP_API_URL}AnonymousOtp/?applicationId=${applicationId}&zipCode=${zipCode}`
      );
      const { data } = resp;
      return {
        applicationId: data.applicationId,
        firstName: data.firstName,
        lastName: data.lastName,
        payoffAmount: data.payoffAmount,
        totalAmountDue: data.totalAmountDue,
        paymentStatus: data.paymentStatus,
        amountFinanced: data.amountFinanced,
        paidToDate: data.paidToDate,
        principalBalance: data.principalBalance,
        paymentsRemaining: data.paymentsRemaining,
        estimatedPayoffDate: data.estimatedPayoffDate,
        authorized: data.authorized,
        message: data.message,
        promotionPlanDate: data.promotionPlanDate,
        promotionPayOffAmount: data.promotionPayOffAmount,
        promotionFees: data.promotionFees,
      };
    } catch (err) {
      const errorResponse = err.response;
      if (!errorResponse) {
        throw err;
      }
      var returnObject = {
        applicationId: 0,
        firstName: "",
        lastName: "",
        payoffAmount: 0,
        totalAmountDue: 0,
        paymentStatus: "",
        amountFinanced: 0,
        paidToDate: 0,
        principalBalance: 0,
        paymentsRemaining: 0,
        estimatedPayoffDate: new Date(),
        authorized: false,
        message: "",
        promotionPlanDate: null,
        promotionPayOffAmount: 0,
        promotionFees: 0,
      };
      if (errorResponse.status >= 400 && errorResponse.status < 500) {
        returnObject.message = errorResponse.data.message;
      } else if (errorResponse.status >= 500) {
        returnObject.message =
          "Internal Server Error has been logged. Please try again later";
        // Add Sentry Here
      }
      return returnObject;
    }
  };
}

export default AnonymoustOTPService;
