import React from "react";
import {Button} from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/react-hooks";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import {LOCAL_APP_STATE, RESET_MODEL} from "../../../../localState/queries";
import Moment from "react-moment";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import FormattedCcNumber from "@hfd/components/utility/FormattedCcNumber";
import {useAuthDataContext} from "../../../../authProvider";
import CcLogo from "@hfd/components/utility/CcLogo";
import { Config } from '../../../../ClientConfig';

const sleep = (ms: Number) => new Promise((r) => setTimeout(r, ms));

const OneTimePaymentResultsMobile = (props: any) => {
  const {data} = useQuery(LOCAL_APP_STATE);
  const {user} = useAuthDataContext();
  const [resetModel] = useMutation(RESET_MODEL);

  const getCard = () => {
    if (props.paymentResponse.approved) {
      return (
          <div
              className={"desktop-header-round hfd-widget-card pcf pcf-success"}
          >
            <div className="payment-status-header">
              <FontAwesomeIcon icon={faCheckCircle}/>
              <IntlMessages id="makePayment.Success"/>
            </div>
            <div className="payment-status-body container">
              <h2 className="body-title success">
                <IntlMessages id="makePayment.ThanksForPayment"/>
              </h2>
              <p className="body-subtitle">
                <IntlMessages id="makePayment.Receipt"/>
              </p>
              <div className="body-image">
                <HFDLogoWithText color="black" size="medium"/>
              </div>
            </div>
            <div className="body-details">
              <ul>
                <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.HFDAccount"/>
                </span>
                  <span className="right-side">
                  {user.firstName} {user.lastName}
                </span>
                </li>
                <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.IDNumber"/>
                </span>
                  <span className="right-side">
                  {data.localAppState.userData.applicationId}
                </span>
                </li>
                <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.Date"/>
                </span>
                  <span className="right-side">
                  <Moment format="MMM Do, YYYY">{new Date()}</Moment>
                </span>
                </li>
                <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.TotalPayment"/>
                </span>
                  <span className="right-side">
                  <FormattedCurrency
                      amount={data.localAppState.userData.paymentAmount}
                  />
                </span>
                </li>
                <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.PaymentMethod"/>
                </span>
                  <div className="check-title">
                    <div className="check-card-img"><CcLogo cardType={data.localAppState.cardType}/></div>
                    <span className="right-side"><IntlMessages id={`payment.EndingIn`}/> <FormattedCcNumber
                        number={data.localAppState.userData.card.cardNumber}/></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
      );
    } else {
      return (
          <div
              className={"desktop-header-round hfd-widget-card pcf pcf-failure"}
          >
            <div className="payment-status-header failure">
              <FontAwesomeIcon icon={faTimesCircle}/>
              <IntlMessages id="makePayment.Failure"/>
            </div>
            <div className="payment-status-body container failure">
              <h2 className="body-title failure">
                <IntlMessages id="makePayment.UhOh"/>
              </h2>
              <h2 className="body-title failure">
                <IntlMessages id="makePayment.ProblemWithPayment"/>
              </h2>
              <div className="body-details">
                <p>
                  <IntlMessages id="makePayment.PleaseSelectAnotherPaymentMethod"/>
                </p>
                <Button className="white-with-blue" onClick={makeAnotherPayment}>
                  <IntlMessages id="makePayment.BackToPaymentMethod"/>
                </Button>
                <div className="spacer"></div>
                <p>
                  <IntlMessages id="makePayment.ProblemContinues"/> <br/>
                  <IntlMessages id="makePayment.PhoneNumber"/>
                </p>
              </div>
            </div>
          </div>
      );
    }
  };

  const backToLogin = () => {
    resetModel({
      variables: {},
      refetchQueries: [{ query: LOCAL_APP_STATE }]
    });

    window.location.href = Config.Integrations.CustomerPortalUrl;
  };

  const makeAnotherPayment = () => {
    resetModel({
      variables: { applicationId: data.localAppState.userData.applicationId, zipOnFile: data.localAppState.userData.zipOnFile },
      refetchQueries: [{ query: LOCAL_APP_STATE }]
    }).then(async () => {
      await sleep(500);
      props.backtoStepTwo();
    });
  };

  return (
      <div>
        {getCard()}

        <div className="d-flex justify-content-center my-4">
          <Button className="makepayment-continue-button" onClick={backToLogin}>
            <IntlMessages id="makePayment.BackToLogin"/>
          </Button>
          <Button className="makepayment-another-button" onClick={makeAnotherPayment}>
            <IntlMessages id="otp.MakeAnotherPayment" />
          </Button>
        </div>
      </div>
  );
};

export default OneTimePaymentResultsMobile;
