import React from "react";
// @ts-ignore
import { FormattedNumber } from "react-intl";

// TODO Hook up to internalization
const FormattedCurrency = (props: any) => {
  return (
    <FormattedNumber
      value={props.amount}
      style="currency"
      currency="USD"
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  );
};

export default FormattedCurrency;
