import React, { useState } from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getCardType } from "@hfd/lib/helpers/cardUtilities";
import AmexLogo from "@hfd/components/Wallet/AmexLogo";
import VisaLogo from "@hfd/components/Wallet/VisaLogo";
import MasterCardLogo from "@hfd/components/Wallet/MasterCardLogo";
import DiscoverLogo from "@hfd/components/Wallet/DiscoverLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import HFDBarLogo from "@hfd/components/utility/HFDBarLoader";

const PaymentMethodWidget = (props: any) => {
  const walletLoading = false;
  const cards = [
    {
      cardNumber: "4111111111111111",
      cardName: "My Primary Card",
      isPrimary: true,
      priority: 1,
      cardId: 1,
    },
    {
      cardNumber: "5111111111111111",
      cardName: "My Secondary Card",
      isPrimary: false,
      priority: 2,

      cardId: 2,
    },
    {
      cardNumber: "347111111111111",
      cardName: "My Amex Card",
      isPrimary: false,
      priority: 3,
      cardId: 3,
    },
    {
      cardNumber: "6011000000000004",
      cardName: "My Discover Card",
      isPrimary: false,
      priority: 3,
      cardId: 4,
    },
  ];

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [cardsExpanded, setCardsExpanded] = useState(false);

  const getCardLogo = (cardType: any) => {
    switch (cardType) {
      case "VISA":
        return <VisaLogo />;
      case "AMEX":
        return <AmexLogo />;
      case "DISCOVERY":
        return <DiscoverLogo />;
      case "MASTER":
        return <MasterCardLogo />;
      default:
        return <div>{cardType}</div>;
    }
  };

  const toggleExpandedCards = () => {
    setCardsExpanded(!cardsExpanded);
  };

  const nextClicked = () => {
    props.onNextClick(cards[selectedCardIndex]);
  };

  const ActiveCards = () => {
    return cards.map((card: any, index: number) => {
      let cardType = getCardType(card.cardNumber);
      if (!cardsExpanded && index > 2) {
        return <div key={card.cardId}></div>;
      }
      return (
        <Row key={card.cardId}>
          <Col md={12}>
            <div
              onClick={() => setSelectedCardIndex(index)}
              className={
                index === selectedCardIndex
                  ? "active payment-wallet-card"
                  : "payment-wallet-card"
              }
            >
              <div className="card-type">{getCardLogo(cardType)}</div>
              <div className="card-entry-details">
                <span> {card.cardName}</span>
                <span>
                  **** **** ****{" "}
                  {card.cardNumber.substring(card.cardNumber.length - 4)}
                </span>
                <span>Exp 02/25</span>
              </div>
              {card.priority === 1 && (
                <div className="primary-indicator">Primary</div>
              )}
            </div>
          </Col>
        </Row>
      );
    });
  };

  if (walletLoading) {
    return <HFDBarLogo size="large" />;
  }

  return (
    <div>
      <Card className="hfd-widget-card padding-1">
        <div className="show-top-right">
          <IntlMessages id="makePayment.Amount" />${props.paymentAmountSelected}
        </div>
        <Row>
          <Col md={12} sm={12}>
            <h6 className="card-widget-title">
              <div className="span blueme">
                <IntlMessages id="makePayment.StepTwo" />
              </div>
              <IntlMessages id="makePayment.SelectPaymentMethod" />
            </h6>
          </Col>
        </Row>
        {ActiveCards()}
        {cards.length > 2 && (
          <Row>
            <Col md={12} sm={12}>
              <span
                className={cardsExpanded ? "caret-down expanded" : "caret-down"}
                onClick={toggleExpandedCards}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Col>
          </Row>
        )}

        <div className="under-widget-link-container">
          <div className="btn btn-grey">
            <FontAwesomeIcon icon={faPlus} />{" "}
            <IntlMessages id="makePayment.AddPaymentMethod" />
          </div>
        </div>
      </Card>

      <div className="buttons-container">
        <Button className="right continue" onClick={() => nextClicked()}>
          <IntlMessages id="generic.Continue" />{" "}
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        <Button
          className="right hollow back"
          onClick={() => props.onBackClick()}
        >
          <IntlMessages id="generic.Back" />
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethodWidget;
