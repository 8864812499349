import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import IntlMessages from "@hfd/components/utility/IntlMessages";

import "./scss/HfdDropdownCard.css";

const HfdDropdownCard = (props: any) => {
  const [isActive, setActive] = useState(props.isActive);

  const handleHeaderClick = () => {
    setActive((prevState:any) => !prevState);
  };

  const headerStatusClass = () => {
    if (props.status === "current") {
      return "ddc-header current";
    } else if (props.status === "pastDueOrange") {
      return "ddc-header past-due-orange";
    } else {
      return "ddc-header past-due-red";
    }
  };

  const renderFooter = () => {
    if (props.footer) {
      if(props.footer.inside){
        return (isActive && <div className="ddc-footer">{props.footer.content}</div>)
      }else{
        return (<div className="ddc-footer">{props.footer.content}</div>);
      }
    }
  };

  return (
    <Card className="drop-down-card">
      <div className={headerStatusClass()} onClick={handleHeaderClick}>
        <Row noGutters>
          <Col xs={2} className="d-flex justify-content-center icon">
            {props.status === "current" ? (
                <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
                <div className={'icon warningWhite'} />
            )}
          </Col>
          <Col xs={8} className="d-flex justify-content-center title">
            <p>
              {props.status === "current" ? (
                <IntlMessages id="otp.YourAccountIsCurrent" />
              ) : (
                <IntlMessages id="makePayment.PaymentStatusPastDue" />
              )}
            </p>
          </Col>
          <Col xs={2} className="d-flex justify-content-center chevron">
            {isActive ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </Col>
        </Row>
      </div>
      {isActive && <div className="ddc-body">{props.children}</div>}
      {renderFooter()}
    </Card>
  );
};

export default HfdDropdownCard;
