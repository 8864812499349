import React from "react";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import OneTimePaymentLoginForm from "./OneTimePaymentLoginForm";

const OneTimePaymentAccountDetailsDesktop = (props: any) => {
  return props.isSubmitting || props.isBypassing ? (
      <div className="loader">
        <br />
        <HFDBarLoader size="full"/>
      </div>
  ) : (
      <>
        <br/>
        <OneTimePaymentLoginForm
            submitClicked={props.submitClicked}
            setSubmitting={props.setSubmitting}
            setBypassing={props.setBypassing}
            handleOpenAppId={props.handleOpenAppId}
            errors={props.errors}
            setErrors={props.setErrors}
        />
      </>
  );
};

export default OneTimePaymentAccountDetailsDesktop;
