import React, { useState, useEffect } from "react";

type StepBarNavigatorProps = {
  steps: StepProps[];
};

type StepProps = {
  completed: boolean;
  isCurrentStep: boolean;
  step: number;
  enabled: boolean;
  onActivateStep: any;
  stepName: string;
};

const StepBarNavigator = (props: StepBarNavigatorProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const attemptStepActivate = (step: StepProps) => {
    if (step.enabled) {
      step.onActivateStep(step.step);
      setCurrentStep(step.step);
    } else {
    }
  };
  const getStepIndicatorCircle = (step: string) => {
    return "stepper step-indicator";
  };
  return (
    <ul className="hfd-step-bar-navigator">
      {props.steps.map((step: StepProps, index: number) => {
        return (
          <li
            key={`step-${index}`}
            //className={`step step-${index}`}
            className={
              step.isCurrentStep
                ? "active"
                : props.steps[index].completed
                ? "completed"
                : ""
            }
            onClick={() => attemptStepActivate(step)}
          >
            <div className="step-name"> {step.stepName} </div>
          </li>
        );
      })}
    </ul>
  );
};

export default StepBarNavigator;
