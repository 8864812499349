import React from "react";
import { Link } from "react-router-dom";
import Button from "@hfd/components/Button/HfdButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";

const PaymentStatusAlert = ({ financials }: any) => {
  if (financials.paymentStatus === "ACT") {
    return (
      <div className="ppd-status-alert green">
        <div className="status-icon d-flex align-items-center">
          <ion-icon name="checkmark-circle-outline" />
          <p>Your account is current!</p>
        </div>
        <Link to="/user/makeapayment">
          <Button label="Make a Payment" />
        </Link>
      </div>
    );
  } else if (financials.paymentStatus === "PCOL") {
    return (
      <div className="ppd-status-alert red">
        <div className="status-icon d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <p>
            Your account is{" "}
            <FormattedCurrency amount={financials.currentAmountPastDue} /> past
            due!
          </p>
        </div>
        <Link to="/user/makeapayment">
          <Button label="Pay Now" />
        </Link>
      </div>
    );
  } else {
    return (
      <div className="ppd-status-alert orange">
        <div className="status-icon d-flex align-items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <p>
            Your account is{" "}
            <FormattedCurrency amount={financials.currentAmountPastDue} /> past
            due!
          </p>
        </div>
        <Link to="/user/makeapayment">
          <Button label="Pay Now" />
        </Link>
      </div>
    );
  }
};

export default PaymentStatusAlert;
