import React from "react";

import { Doughnut } from "react-chartjs-2";
// @ts-ignore
import { Chart } from "react-chartjs-2";

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.defaults.global.legend.display = false;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  },
});

const DashboardDonutChart = (props: any) => {
  const financials = props.financials;

  let paid = financials.totalPaymentsToDate / financials.totalAmountOwed;

  const data = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        data: [paid, 1 - paid],
        backgroundColor: ["#00b189", "#b2e2f5"],
        hoverBackgroundColor: ["#11b189", "#c3e2f5"],
      },
    ],
    text: `${(paid * 100).toFixed(0)}%`,
    options: {
      legend: {
        display: false,
      },
    },
  };

  return <Doughnut data={data} />;
};

export default DashboardDonutChart;
