import styled from "styled-components";

const PageTitle = styled.div`
  .page-title {
    color: #333333;
    font-weight: 300;
    margin: 0;
    font-size: 25pt;
    font-family: 'CircularMedium', 'Open Sans', sans-serif;
    margin-bottom: 20px;
  }
  .page-subtitle {
    font-size: 15pt;
    font-weight: 400;
    line-height: 24px;
    color: #7b868c;
    font-family: 'CircularMedium', 'Open Sans', sans-serif;
  }
`;

export default PageTitle;
