import React, { useEffect, useState } from "react";
import { useAuthDataContext } from "../../../authProvider";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router";

import PaymentAmountWidget from "./Components/PaymentAmountWidget";
import PaymentAmountWidgetMobile from "./Components/PaymentAmountWidgetMobile";
import PaymentMethodWidget from "./Components/PaymentMethodWidget";
import PaymentConfirmationWidget from "./Components/PaymentConfirmationWidget";
import PaymentResultWidget from "./Components/PaymentResultWidget";
import PaymentMethodWidgetMobile from "./Components/PaymentMethodWidgetMobile";
import PaymentConfirmationWidgetMobile from "./Components/PaymentConfirmationWidgetMobile";
import PaymentResultWidgetMobile from "./Components/PaymentResultWidgetMobile";

import { LOCAL_APP_STATE } from "../../../localState/queries";
//import MobileNavBar from "../../../components/mobile/MobileNavBar";

import "./scss/payment.css";
import PaymentDesktop from "./PaymentDesktop";
import PaymentMobile from "./PaymentMobile";

const Payment = (props: any) => {
  const { user } = useAuthDataContext().user;
  const { loading, data } = useQuery(LOCAL_APP_STATE);
  const [choosingCustomAmount, setChoosingCustomAmount] = useState(false);
  const financials = user.financeInfo;

  const [currentStep, setCurrentStep] = useState(0);

  const [selectedCard, setSelectedCard] = useState({
    cardNumber: "4111111111111111",
    cardName: "My Primary Card",
    isPrimary: true,
    priority: 1,
    cardId: 1,
  });

  const [paymentAmountSelected, setPaymentAmountSelected] = useState(0);

  const paymentResults = null;

  const clearResults = async () => {
    setPaymentAmountSelected(0);
    setSelectedCard({
      cardNumber: "4111111111111111",
      cardName: "My Primary Card",
      isPrimary: true,
      priority: 1,
      cardId: 1,
    });
    setCurrentStep(0);
    activateStep(0);
  };

  // Clear Results if user gets here
  // in the same session after having
  // a payment attempt
  useEffect(() => {
    clearResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadMyFinancials() {
      setCurrentStep(0);
    }

    loadMyFinancials();
  }, []);

  const activateStep = (stepNumber: any) => {
    setCurrentStep(stepNumber);
  };

  const paymentAmountNextClicked = (amount: any) => {
    setPaymentAmountSelected(amount);
    activateStep(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const paymentMethodNextClicked = (card: any) => {
    setSelectedCard(card);
    activateStep(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const backClicked = () => {
    activateStep(currentStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const submitClicked = () => {
    activateStep(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const processPayment = async (succeeed: any) => {
    console.log("Hook up apollo for payment processing");
  };

  const getCurrentStep = () => {
    if (paymentResults) {
      return <div></div>;
    }
    if (currentStep === 0) {
      if (data.localAppState.isMobile) {
        return (
          <PaymentAmountWidgetMobile
            financials={financials}
            onNextClick={paymentAmountNextClicked}
            hideHeaderDetails={() => setChoosingCustomAmount(true)}
            showHeaderDetails={() => setChoosingCustomAmount(false)}
            choosingCustomAmount={choosingCustomAmount}
          />
        );
      }
      return (
        <PaymentAmountWidget
          financials={financials}
          onNextClick={paymentAmountNextClicked}
        />
      );
    } else if (currentStep === 1) {
      console.log("Step is now 1");
      if (data.localAppState.isMobile) {
        console.log("And we are mobile");
        return (
          <PaymentMethodWidgetMobile
            onBackClick={backClicked}
            onNextClick={paymentMethodNextClicked}
            paymentAmountSelected={paymentAmountSelected}
          />
        );
      }
      return (
        <PaymentMethodWidget
          onBackClick={backClicked}
          onNextClick={paymentMethodNextClicked}
          paymentAmountSelected={paymentAmountSelected}
        />
      );
    } else if (currentStep === 2) {
      if (data.localAppState.isMobile) {
        return (
          <PaymentConfirmationWidgetMobile submitClicked={submitClicked} />
        );
      }
      return (
        <PaymentConfirmationWidget
          selectedCard={selectedCard}
          paymentAmountSelected={paymentAmountSelected}
          amountPastDue={financials.amountPastDue}
          nextPaymentDate={financials.nextPaymentDate}
          onBackClick={backClicked}
          paymentStatus={financials.paymentStatus}
          processPayment={processPayment}
        />
      );
    } else if (currentStep === 3) {
      if (data.localAppState.isMobile) {
        return <PaymentResultWidgetMobile />;
      }
    }
  };

  const getPaymentSteps = () => {
    return [
      {
        step: 0,
        stepName: "Amount",
        completed: currentStep > 0,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 0,
        isCurrentStep: currentStep === 0,
      },
      {
        step: 1,
        stepName: "Method",
        completed: currentStep > 1,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 1,
        isCurrentStep: currentStep === 1,
      },
      {
        step: 2,
        stepName: "Confirm",
        completed: false,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 2,
        isCurrentStep: currentStep === 2,
      },
    ];
  };

  const getMobileStepText = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="page-description mobile">
            <span className="blue-text">Step 1:</span>&nbsp;Select Amount to Pay
          </div>
        );
      case 1:
        return (
          <div className="page-description mobile">
            <span className="blue-text">Step 2:</span>&nbsp;Select Payment
            Method
          </div>
        );
      case 2:
        return (
          <div className="page-description mobile">
            <span className="blue-text">Step 3:</span>&nbsp;Confirm & Submit
            Payment
          </div>
        );
      default:
        return <div>Hello</div>;
    }
  };

  let history = useHistory();
  const goHome = () => {
    history.push("/user/dashboard");
  };

  const backButtonClicked = () => {
    console.log("Back Clicked");
    if (currentStep === 0 && choosingCustomAmount) {
      setChoosingCustomAmount(false);
    } else if (currentStep === 0) {
      goHome();
    } else if (currentStep === 1) {
      setCurrentStep(0);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    } else if (currentStep === 3) {
      setCurrentStep(2);
    }
  };

  if (loading) {
    return <div>Loading</div>;
  }

  if (paymentResults) {
    return (
      <PaymentResultWidget
        clearResults={clearResults}
        results={paymentResults}
      />
    );
  }

  return (
    <>
      {data.localAppState.isMobile && (
        <PaymentMobile
          backButtonClicked={backButtonClicked}
          choosingCustomAmount={choosingCustomAmount}
          getMobileStepText={getMobileStepText}
          getPaymentSteps={getPaymentSteps}
          getCurrentStep={getCurrentStep}
          currentStep={currentStep}
        />
      )}
      {!data.localAppState.isMobile && (
        <PaymentDesktop
          financials={financials}
          getPaymentSteps={getPaymentSteps}
          getCurrentStep={getCurrentStep}
        />
      )}
    </>
  );
};

export default Payment;
