import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MobileNav from "../../../components/mobile/MobileNavBar";
import IntlMessages from "@hfd/components/utility/IntlMessages";

import SettingsAccount from "./Components/SettingsAccount";
import SettingsPaymentPlan from "./Components/SettingsPaymentPlan";
import SettingsSecurity from "./Components/SettingsSecurity";
import SettingsTOS from "./Components/SettingsTOS";

import "./scss/settingsMobile.css";

const SettingsMobile = ({
  user,
  textEditable,
  changeSetDefault,
  changeEditMode,
  handleSaveValue,
}: any) => {
  return (
    <Container className="settings-mobile-container">
      <MobileNav />
      <Row className="mt-4">
        <Col className="mt-4">
          <h2>
            <IntlMessages id="settings.Title" />
          </h2>
        </Col>
      </Row>
      <Row noGutters xs={1} sm={1} md={1} className="mt-2">
        <Col className="mb-4">
          <SettingsAccount user={user} />
        </Col>
        <Col className="mb-4">
          <SettingsPaymentPlan
            user={user}
            textEditable={textEditable}
            changeSetDefault={changeSetDefault}
            changeEditMode={changeEditMode}
            handleSaveValue={handleSaveValue}
          />
        </Col>
        <Col className="mb-4">
          <SettingsSecurity />
        </Col>
        <Col className="mb-4">
          <SettingsTOS />
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsMobile;
