// @ts-ignore
import React from "react";
// @ts-ignore
import {Button} from "react-bootstrap";

interface IProps {
    label: string;
}

const HfdButton = ({ label }: IProps) => {
  return (
    <>
        <Button variant="outline-primary hfd-settings-button">
            { label }
        </Button>
    </>
  );
};

export default HfdButton;
