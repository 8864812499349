import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Button, Form } from "react-bootstrap";
import ErrorDisplay from "@hfd/components/ErrorDisplay/ErrorDisplay";
import { Formik } from "formik";
import {
  UPDATE_USERZIPCODE,
  UPDATE_USERAPPLICATIONID,
  LOCAL_APP_STATE,
  RESET_MODEL
} from "../../../../localState/queries";
import { Config } from '../../../../ClientConfig';
import { useLocation } from 'react-router';

const OneTimePaymentLoginForm = (props: any) => {
  const { data } = useQuery(LOCAL_APP_STATE);
  const [setApplicationId] = useMutation(UPDATE_USERAPPLICATIONID);
  const [setUserZip] = useMutation(UPDATE_USERZIPCODE);
  const [resetModel] = useMutation(RESET_MODEL);
  const location = useLocation();

  const appIdRegex = /^[a-z0-9_-]{6,8}$/;
  const zipregex = /\d{5}/g;

  const [isAppIdValid, setAppIdValid] = useState(false);
  const [isZipcodeValid, setZipcodeValid] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const appId = params.get("appid") || params.get("appId") || params.get("applicationId");
    const zip = params.get("zip") || params.get("zipCode");
   
    if (zip && appId && !props.errors) {
      setApplicationId({
        variables: { applicationId: appId },
        refetchQueries: [{ query: LOCAL_APP_STATE }],
      });

      setUserZip({
        variables: { zipCode: zip },
        refetchQueries: [{ query: LOCAL_APP_STATE }],
      });

      props.setBypassing(true);
      
      props.submitClicked({ zipcode: zip, applicationId: appId });
      
      props.setBypassing(false);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const appId = data.localAppState.userData.applicationId;
    const zipCode = data.localAppState.userData.zipOnFile;

    if (appId) {
      setAppIdValid(appIdRegex.test(appId));
    }

    if (zipCode) {
      setZipcodeValid(zipregex.test(zipCode));
    }

    // eslint-disable-next-line
  }, [data]);

  const handleApplicationId = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let appId = ev.target.value;
    const isValid = appIdRegex.test(appId);
    setAppIdValid(isValid);

    setFieldValue("applicationId", appId, true);

    setApplicationId({
      variables: { applicationId: appId },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const handleZipcode = (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    let zipcode = ev.target.value;
    if (zipcode.length > 5) zipcode = zipcode.substring(0, 5);
    const isValid = zipregex.test(zipcode);
    setZipcodeValid(isValid);

    setFieldValue("zipcode", zipcode, true);

    setUserZip({
      variables: { zipCode: zipcode },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  };

  const doSubmit = (values: any) => {
    props.setErrors("");
    if (!isAppIdValid || !isZipcodeValid) {
      return props.setErrors("Oops! Missing/Invalid Required fields.");
    }

    props.submitClicked(values);
  };

  const handleCancel = () => {
    resetModel({
      variables: {},
      refetchQueries: [{query: LOCAL_APP_STATE}]
    });

    window.location.href = Config.Integrations.CustomerPortalUrl;
  };

  const openAppId = () => {
    const { handleOpenAppId } = props;
    handleOpenAppId();
  };

  return (
    <Formik
      initialValues={{
        applicationId: data.localAppState.userData.applicationId,
        zipcode: data.localAppState.userData.zipOnFile,
      }}
      validate={async (values) => {}}
      onSubmit={async (values, { resetForm }) => {
        props.setErrors("");
        await doSubmit(values);
      }}
    >
      {({ values, handleSubmit, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="application-form-desktop">
          <Form.Group
            controlId="applicationId"
            className="application-form-field"
          >
            <Form.Label>Application ID</Form.Label>
            <Form.Control
              type="number"
              value={values.applicationId || ''}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleApplicationId(ev, setFieldValue)
              }
              isValid={isAppIdValid}
              isInvalid={!isAppIdValid && values.applicationId > 0}
            />
            <Form.Text className="text-muted" onClick={openAppId}>
              <IntlMessages id="otp.DontKnowAppId" />
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="zipcode" className="application-form-field">
            <Form.Label>
              <IntlMessages id="otp.MainAccHolderZip" />
            </Form.Label>
            <Form.Control
              type="number"
              maxLength={5}
              value={values.zipcode || ''}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleZipcode(ev, setFieldValue)
              }
              isValid={isZipcodeValid}
              isInvalid={!isZipcodeValid && values.zipcode?.length > 0}
            />
          </Form.Group>

          <div className="error-container otp-error">
            {props.errors && (
              <ErrorDisplay errorMessage={props.errors} small={true} />
            )}
          </div>

          <br />
          <br />

          <div className="d-flex justify-content-center my-4">
            <Button
              type="button"
              className="makepayment-cancel-button"
              onClick={handleCancel}
            >
              <IntlMessages id="otp.Cancel" />
            </Button>
            <Button type="submit" className="makepayment-continue-button">
              <IntlMessages id="otp.Continue" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OneTimePaymentLoginForm;
