import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import Card from "@hfd/components/utility/card";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import FormattedIntlDate from "@hfd/components/utility/FormattedIntlDate";
import DashboardAlertIcon from "./DashboardAlertIcon";

const ACTStatus = () => {
  return (
    <Card title="Payment Status">
      <Row noGutters xs={1} sm={1} md={2}>
        <Col>
          <div style={{ textAlign: "center" }}>
            <DashboardAlertIcon pastDue={false} />
            <p className="mb-3 circular-book" style={{ fontSize: "1.2rem" }}>
              No payment is required at this time.
            </p>
          </div>
        </Col>
        <Col className="d-flex justify-content-center">
          <Image
            src="https://global-content-cf.s3-us-west-2.amazonaws.com/HFD.Customer/images/Treating+Patients.png"
            width="198px"
            fluid
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <Button variant="outline-primary hfd-primary-button">
            <IntlMessages id="home.ButtonPayDownPrincipal" />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

const LATEStatus = ({ amountPastDue, nextPaymentDate }: any) => {
  return (
    <div className={"past-due-1"}>
      {/* <ion-icon name="warning" width={70} /> */}
      <Card title="Your account is past due!">
        <Row noGutters xs={1} sm={1} md={2}>
          <Col className="mb-3">
            <div className="text-center">
              <p className="hfd-missed circular-book">
                Amount Due: <FormattedCurrency amount={amountPastDue} />
              </p>
              <p className="circular-book">
                Pay past due amount
                <br />
                before{" "}
                {nextPaymentDate && (
                  <FormattedIntlDate date={nextPaymentDate} />
                )}
                {!nextPaymentDate && (
                  <FormattedIntlDate date={`${new Date()}`} />
                )}{" "}
                to avoid fees.
              </p>
            </div>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="text-center">
              <button className="past-due-button" tabIndex={0}>
                Make a Payment
              </button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const DashboardPaymentWidget: any = ({ financials }: any) => {
  if (financials.paymentStatus !== "ACT") {
    return (
      <LATEStatus
        amountPastDue={financials.currentAmountPastDue}
        nextPaymentDate={financials.nextPaymentDate}
      />
    );
  }
  if (financials.paymentStatus === "ACT") {
    return <ACTStatus />;
  }
};
export default DashboardPaymentWidget;
