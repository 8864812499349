import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import HFDLogoWithText from '@hfd/components/Logos/HFDLogoWithText';
import Styled from './Navigation.styles';

type NavigationProps = {
    children: React.ReactNode;
}

const Navigation = ({ children }: NavigationProps) => {
  return (
    <Styled>
      <Navbar className="fixed-sn">
        <Navbar.Brand href="/">
          <HFDLogoWithText
            color={'white'}
            size={'large'}
          />
        </Navbar.Brand>
        <br />
        <br />
        <Nav className="flex-column">{children}</Nav>
      </Navbar>
    </Styled>
  );
};

export default Navigation;
