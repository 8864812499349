import React from "react";
import { IconProps } from "./IconInterface";
import img from "../../../assets/images/hfdicons/Payoff Amount.svg";
import selectedImage from "../../../assets/images/hfdicons/Payoff Amount - Selected.svg";

const HFDCircleDollarSign = (props: IconProps) => {
  if (props.selected) {
    return (
      <div className="hfd-icon">
        <img src={selectedImage} />
      </div>
    );
  }
  return (
    <div className="hfd-icon">
      <img src={img} />
    </div>
  );
};

export default HFDCircleDollarSign;
