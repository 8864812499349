import React from "react";
import { Form } from "react-bootstrap";
import "./HFDInputEditable.css";

const HFDInputEditable = ({ ...props }) => {
  const renderEditView = () => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Form.Control
          type={props.type}
          as={props.as}
          placeholder={props.textEditable.value}
        >
          {props.as ? props.children : null}
        </Form.Control>
        <button
          type="button"
          onClick={props.changeEditMode}
          className="hfd-text-button ml-2 close-outline"
        >
          <ion-icon name="close"></ion-icon>
        </button>
        <button className="hfd-text-button ml-2 check" type="submit">
          <ion-icon name="checkmark-sharp"></ion-icon>
        </button>
      </div>
    );
  };

  const renderDefaultView = () => {
    return (
      <div className="d-flex align-items-center">
        <p className="mr-3">
          {props.type === "password" ? "*******" : props.textEditable.value}
        </p>
        <button
          type="button"
          onClick={props.changeEditMode}
          className="hfd-text-button edit"
        >
          <ion-icon name="pencil"></ion-icon>
        </button>
      </div>
    );
  };

  return props.textEditable.isInEditMode
    ? renderEditView()
    : renderDefaultView();
};

export default HFDInputEditable;
