import React, { Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "@hfd/components/utility/loader";
import { useAuthDataContext } from "./authProvider";
import SignIn from "./containers/Auth/SignIn/SignIn";
import AuthenticatedUser from "./containers/Dashboard/AuthenticatedUser";
import OneTimePayment from "./containers/Pages/UnauthenticatedOneTimePayment/OneTimePayment";
import Splash from "./containers/Splash/Splash";

import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

type Props = {
  component: any;
  path: string;
};

const PrivateRoute = (props: Props) => {
  const { user } = useAuthDataContext();
  if (!user) {
    return <Route component={SignIn} />;
  } else {
    return <Route component={props.component} />;
  }
};

const Routes = () => (
  <ErrorBoundary>
    <></>
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route path="/signin" component={SignIn} />
          <Route
            path="/makeotp/:lang?/:zip?/:applicationId?"
            component={OneTimePayment}
          />
          <PrivateRoute path="/user" component={AuthenticatedUser} />
        </Switch>
      </Router>
    </Suspense>
  </ErrorBoundary>
);

export default Routes;
