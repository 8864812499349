import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import StepBarNavigator from "@hfd/ui/StepBarNavigator/StepBarNavigator";
import MobileNavBar from "../../../components/mobile/MobileNavBar";

const PaymentMobile = ({
  backButtonClicked,
  choosingCustomAmount,
  getMobileStepText,
  getPaymentSteps,
  getCurrentStep,
  currentStep,
}: any) => {
  console.log(currentStep);
  return (
    <>
      <MobileNavBar customGoBack={backButtonClicked} />
      <Container fluid className="temp-container-height">
        <Row>
          {currentStep === 3 ? null : (
            <Col sm={12} md={12} lg={12} xs={12}>
              <h4 className="page-title mobile">
                <IntlMessages id="makePayment.TitleMobile" />
                {!choosingCustomAmount && getMobileStepText()}
              </h4>
            </Col>
          )}
        </Row>
        <Row>
          {currentStep === 3 ? null : (
            <Col className="px-4" sm={12} md={12} lg={12} xs={12}>
              {!choosingCustomAmount && (
                <StepBarNavigator steps={getPaymentSteps()} />
              )}
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Col sm={12} md={12} lg={12} xs={12}>
            {getCurrentStep()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentMobile;
