import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import StepBarNavigator from "@hfd/ui/StepBarNavigator/StepBarNavigator";
import PaymentStatusWidget from "./Components/PaymentStatusWidget";

const PaymentDesktop = ({
  financials,
  getPaymentSteps,
  getCurrentStep,
}: any) => {
  return (
    <Container fluid>
      <Row>
        <Col md={6} lg={6} sm={12} xs={12}>
          <h4 className="page-title">
            <IntlMessages id="makePayment.Title" />
          </h4>
          <p className="page-description">
            <IntlMessages id="makePayment.TitleDescription" />
          </p>
        </Col>
        <Col md={6} lg={6} sm={12} xs={12}>
          <StepBarNavigator steps={getPaymentSteps()} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={9} className="pr-3">
          {getCurrentStep()}
        </Col>
        <Col md={3} className="pl-3">
          <PaymentStatusWidget financials={financials} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentDesktop;
