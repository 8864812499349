import React from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Container, Row, Col } from "react-bootstrap";
import Title from "@hfd/components/utility/pageTitle";
import PaymentStatusAlert from "./Components/PaymentStatusAlert";
import PaymentSummary from "./Components/PaymentSummary";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const PaymentPlanDesktop = ({
  financials,
  user,
  isScrollDownActive,
  PaymentSchedule,
  handleScroll,
}: any) => {
  return (
    <Container fluid>
      <Row noGutters className="mb-4">
        <Title>
          <h1 className="page-title">
            <IntlMessages id="paymentPlan.PageTitle" />
          </h1>
          <p className="page-subtitle">
            <IntlMessages id="paymentPlan.TitleDescription" />
          </p>
        </Title>
      </Row>
      <Row noGutters md={2} className="mb-4">
        <Col md={4} className="pr-3">
          <PaymentSummary financials={financials} user={user} />
        </Col>
        <Col md={8} className="pl-3">
          <Row>
            <Col md={12}>
              <PaymentStatusAlert financials={financials} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={10}>
              <h4>Payment Schedule</h4>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              className={isScrollDownActive ? "scroll-me active" : "scroll-me"}
            >
              {PaymentSchedule()}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <button
                type="button"
                onClick={handleScroll}
                className="scroll-down-button"
              >
                <FontAwesomeIcon
                  icon={isScrollDownActive ? faChevronUp : faChevronDown}
                />
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPlanDesktop;
