import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Row, Col, Container, Button } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import Sidebar from "@hfd/containers/Navigation/Sidebars/Sidebar";
import IsometricCustomerImage from "@hfd/assets/images/cp-signin-isometric.svg";
import SignInForm from "./Components/SignInForm";
import { LOCAL_APP_STATE } from "../../../localState/queries";
import AnonymoustOTPService from "../../../AnonymousOTP/AnonymoustOTPService";
import {useHistory} from "react-router-dom";

const SignInDesktop = () => {
  const { data } = useQuery(LOCAL_APP_STATE);
  const history = useHistory();
  // Example of using the AnonymousOTPService to Get Basic Account Details
  // With simple validation. Every request to the api should always provider
  // AppId and Zip
  useEffect(() => {
    const appId = data.localAppState.userData.applicationId;
    const zipCode = data.localAppState.userData.zipOnFile;

    const getBasicDetails = async () => {
      var resp = await new AnonymoustOTPService().validateAppAndZipCode(
        appId,
        zipCode
      );
      if (resp.authorized === false) {
        // Failed to Validate
        console.log("tes");
      } else {
        // we did validate!
        // Should have account details
        console.log("tes1");
      }
    };

    if (appId && zipCode) {
      getBasicDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOTPClick = () => {
    history.push("/makeotp");
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4}>
          <Sidebar size="medium" classes="hfd-sidebar customer-side">
            <div className="flex-1">
              <HFDLogoWithText color="white" size="small" />
            </div>
            <div className="flex-3">
              <img src={IsometricCustomerImage} alt="Join us" />
            </div>
            <div className="flex-1 white-text">
              <h4>
                <IntlMessages id="signin.EasyWay" />
              </h4>

              <br />
              <IntlMessages id="signin.MakeAnonymousOtp" />
              <br />
              <br />
              <Button className="pay-now-button" onClick={handleOTPClick}>
                <IntlMessages id="signin.AnonymousOtpMakePaymentButton" />
              </Button>
            </div>
          </Sidebar>
        </Col>
        <Col md={8}>
          <div className="center-flex">
            <div className="hfd-rounded-box">
              <h5>
                <IntlMessages id="signin.Login" />
              </h5>
              <br />
              <SignInForm />
            </div>
            <p className="sign-up-from-sign-in">
              <IntlMessages id="signin.DontHaveAnAccount" /> &nbsp;
              <a href="#!">
                <IntlMessages id="signin.Signup" />
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInDesktop;
