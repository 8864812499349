import React from "react";
import {useQuery} from "@apollo/react-hooks";
import HfdDropdownCard from "@hfd/components/DropdownCard/HfdDropdownCard";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import {useAuthDataContext} from "../../../../authProvider";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import {LOCAL_APP_STATE} from "../../../../localState/queries";

const OneTimePaymentAmountDetails = (props: any) => {
  const {data} = useQuery(LOCAL_APP_STATE);
  const { user } = useAuthDataContext();
  if (!user) {
    props.cancel();
    return (<></>);
  }

  let current = 'current';
  switch (user.paymentStatus) {
    case 'PCOL': current = 'pastDueRed'; break;
    case 'DEL': current = 'pastDueRed'; break;
    case 'LATE': current = 'pastDueOrange'; break;
  }

  return (
      <HfdDropdownCard status={current}>
        <div className="ddc-body-details">
          <ul className={'payment-detail-row'}>
            <li>
              <IntlMessages id="otp.PaymentAmount" /> <FormattedCurrency amount={data.localAppState.userData.paymentAmount}/>
            </li>
          </ul>
        </div>
      </HfdDropdownCard>
  );
};

export default OneTimePaymentAmountDetails;
