import React from "react";
import PageTitle from "./pageTitle.style";

type PageTitleProps = {
    children: React.ReactNode;
}

export default (props: PageTitleProps) => {
  return <PageTitle>{props.children}</PageTitle>;
};
