import React, {useState} from "react";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import OneTimePaymentAddressForm from "./OneTimePaymentAddressForm";

const OneTimePaymentDetailsMobile = (props: any) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return isSubmitting ? (
      <div className="loader">
        <br/>
        <HFDBarLoader size="full"/>
      </div>
  ) : (
      <>
        <br/>
        <OneTimePaymentAddressForm
            setSubmitting={setSubmitting}
            cancelClicked={props.cancelClicked}
            continueClicked={props.continueClicked}
            handleOpenAppId={props.handleOpenAppId}
        />
      </>
  );
};

export default OneTimePaymentDetailsMobile;
