import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DashboardAlertIcon = (props: any) => {
  if (props.pastDue) {
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        style={{ fontSize: "7rem", color: "#FFC557" }}
      />
    );
  }
  return (
    <FontAwesomeIcon
      icon={faCheckCircle}
      style={{ fontSize: "7rem", color: "#FFC557" }}
    />
  );
};

export default DashboardAlertIcon;
