import React from "react";
import { Row, Col } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";

const SettingsAccount = ({ user }: any) => {
  return (
    <Card>
      <Row noGutters xs={2} sm={3} md={4} className="settings-account">
        <Col className="acc-id">
          <p className="hfd-primary1">
            <IntlMessages id="settings.AccountID" />
          </p>
        </Col>
        <Col className="d-flex align-items-center app-id">
          <p>{user.applicationId}</p>
        </Col>
      </Row>
    </Card>
  );
};

export default SettingsAccount;
