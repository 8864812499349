import React from "react";
import "./Ellipsis.css";

const Ellipsis = () => {
  return (
    <>
      <span className="one">.</span>
      <span className="two">.</span>
      <span className="three">.</span>
    </>
  );
};

export default Ellipsis;
