//import { useAuthDataContext } from "../../../authProvider";
import { LOCAL_APP_STATE } from "../../../localState/queries";
import { useQuery } from "@apollo/react-hooks";
import React from "react";
import WalletCard from "@hfd/components/Wallet/WalletCard";
import WalletBankAccount from "@hfd/components/Wallet/WalletBankAccount";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import { Col } from "react-bootstrap";

import WalletMobile from "./WalletMobile";
import WalletDesktop from "./WalletDesktop";

import "./scss/wallet.css";

const Wallet = () => {
  //const { authData } = useAuthDataContext();
  const { data } = useQuery(LOCAL_APP_STATE);

  const walletLoading = false;
  const cards = [
    {
      cardNumber: "4111111111111111",
      cardName: "My Primary Card",
      isPrimary: true,
      priority: 1,
      cardId: 1,
    },
    {
      cardNumber: "5111111111111111",
      cardName: "My Secondary Card",
      isPrimary: false,
      priority: 2,

      cardId: 2,
    },
    {
      cardNumber: "347111111111111",
      cardName: "My Amex Card",
      isPrimary: false,
      priority: 3,
      cardId: 3,
    },
    {
      cardNumber: "6011000000000004",
      cardName: "My Discover Card",
      isPrimary: false,
      priority: 3,
      cardId: 4,
    },
  ];
  const ach = [
    {
      accountName: "TEST ACH",
      accountNumber: "129334932",
      priority: 3,
      achId: 1,
      bankName: "My Bank",
    },
  ];

  const DisplayCards = () => {
    if (walletLoading) {
      return (
        <Col md={12}>
          <HFDBarLoader size="large" />
        </Col>
      );
    }
    if (!cards) {
      return <div></div>;
    }
    return cards.map((card) => {
      return (
        <Col key={card.cardId} md={4} sm={12}>
          <WalletCard card={card} />
        </Col>
      );
    });
  };

  const DisplayAch = () => {
    if (walletLoading) {
      return (
        <Col md={12}>
          <HFDBarLoader size="large" />
        </Col>
      );
    }
    if (!ach) {
      return <div></div>;
    }
    return ach.map((ach) => {
      return (
        <Col key={ach.achId} md={4} sm={12}>
          <WalletBankAccount ach={ach} />
        </Col>
      );
    });
  };

  return data.localAppState.isMobile ? (
    <WalletMobile DisplayCards={DisplayCards} />
  ) : (
    <WalletDesktop DisplayCards={DisplayCards} DisplayAch={DisplayAch} />
  );
};

export default Wallet;
