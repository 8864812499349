import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAuthDataContext } from "../../../../authProvider";
import MobileNavBar from "../../../../components/mobile/MobileNavBar";

import "../scss/PaymentPlanMobile.css";

const PaymentEditPlanMobile = () => {
  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;
  return (
    <>
      <MobileNavBar />
      <Container className="payment-plan-mobile-container2">
        <Row className="py-3">
          <Col>
            <h1>Edit Plan</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{financials.paymentPlanName}</h2>
            <p className="app-id">{user.applicationId}</p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="outline-link-button justify-content-center">
              <div className="olb-title">Reduce Term</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="outline-link-button justify-content-center">
              <div className="olb-title">Change Payment Date</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="outline-link-button justify-content-center">
              <div className="olb-title">Request Cancellation</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="outline-link-button justify-content-center">
              <div className="olb-title">Change Plan Name</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="outline-link-button justify-content-center">
              <div className="olb-title">Contact Us</div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentEditPlanMobile;
