import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";
import ToggleSwitch from "@hfd/components/ToggleSwitch/ToggleSwitch";

const ProfileNotificationPreferences = () => {
  const [toggleSwitch, setToggle] = useState(false);

  const onToggleChange = () => {
    setToggle((prevState) => !prevState);
  };

  return (
    <Card title="Notification Preferences">
      <Row noGutters md={2} className="mb-2">
        <Col>
          <p className="hfd-primary2">
            <IntlMessages id="profile.SMSAlerts" />
          </p>
        </Col>
        <Col>
          <ToggleSwitch
            id="toggleSwitch"
            name="profileSwitch"
            checked={toggleSwitch}
            onToggleChange={onToggleChange}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileNotificationPreferences;
