import { gql } from "@apollo/client";

export const GET_USER_QUERY = gql`
  query GetUserData($appId: String!) {
    user(appId: $appId) {
      applicationId
      country
      email
      financeInfo {
        currentInterestRate
        dayOfPayment
        feeBalance
        minPaymentAllowed
        nextPayment
        nextPaymentDate
        paymentPlanName
        paymentStatus
        payoffAmount
        principalBalance
        providerID
        providerName
        totalAmountOwed
        totalPaymentsToDate
        currentAmountPastDue
        amortizationActivity {
          amortizationID
          balance
          createdOn
          interest
          ledgerActivity
          payment
          principal
          eventDate
        }
        ledgerActivity {
          accountingCode
          distroDate
          feeCode
          interestAmount
          ledgerDetailid
          principal
          totalAmount
        }
      }
      firstName
      languagePreference
      lastName
      mailingCity
      mailingCountry
      mailingState
      mailingStreet
      mailingZip
      primaryPhone
    }
  }
`;
