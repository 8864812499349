import React from "react";
import Card from "@hfd/components/utility/card";
import { Container, Row, Col } from "react-bootstrap";
//import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import DashboardPaymentStatus from "./Components/DashboardPaymentStatus";
import DashboardPaymentWidget from "./Components/DashboardPaymentWidget";
import DashboardDonutChart from "./Components/DashboardDonutChart";
import DashboardOverviewInformation from "./Components/DashboardOverviewInformation";
import DashboardQuickActions from "./Components/DashboardQuickActions";
import "./scss/dashboard.css";
import { useAuthDataContext } from "../../authProvider";

export default function DashboardDesktop() {
  const { user } = useAuthDataContext().user;
  const financials = user.financeInfo;

  return (
    <Container fluid className="no-padding px-5">
      <Row noGutters xs={1} sm={1} md={2} className="mb-4  d-flex">
        <Col md={6} className="pr-3">
          <Card title="Overview">
            <Row noGutters md={2}>
              <Col className="d-flex align-items-center justify-content-center">
                <DashboardDonutChart financials={financials} />
              </Col>
              <Col>
                <DashboardOverviewInformation financials={financials} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6} className="pl-3">
          <DashboardPaymentWidget financials={financials} />
        </Col>
      </Row>
      <Row noGutters md={2} className="d-flex">
        <Col md={8} className="pr-3">
          <Card title="Payments">
            <DashboardPaymentStatus financials={financials} />
          </Card>
        </Col>
        <Col md={4} className="pl-3">
          <DashboardQuickActions />
        </Col>
      </Row>
    </Container>
  );
}
