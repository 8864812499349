import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "@hfd/components/utility/pageTitle";
import IntlMessages from "@hfd/components/utility/IntlMessages";

import ProfileBasicInfo from "./Components/ProfileBasicInfo";
import ProfileNotificationPreferences from "./Components/ProfileNotifiicationPreferences";
import ProfileNewPasswordForm from "./Components/ProfileNewPasswordForm";

import "./scss/Profile.css";
import MobileNavBar from "../../../components/mobile/MobileNavBar";
import Card from "@hfd/components/utility/card";

const ProfileMobile = ({ user, backButtonClicked }: any) => {
  const [isPasswordDrawerOpen, setPasswordDrawer] = useState(false);
  const [hasNewPassword, setHasNewPassword] = useState(false);
  const handleDrawer = () => {
    setPasswordDrawer((prevState) => !prevState);
  };
  console.log(setHasNewPassword);
  if (hasNewPassword) {
    return <ProfileNewPasswordForm />;
  }

  return (
    <>
      <MobileNavBar customGoBack={backButtonClicked} />
      <Container fluid className="no-padding px-5">
        <Row noGutters className="mb-4">
          <Col>
            <Title>
              <h1 className="page-title">
                <IntlMessages id="profile.Title" />
              </h1>
            </Title>
          </Col>
        </Row>
        <Card title="Personal Settings">
          <Row noGutters xs={1} sm={1} md={2}>
            <Col className="mb-4">
              <ProfileBasicInfo
                userInfo={user}
                handleDrawer={handleDrawer}
                isDrawerOpen={isPasswordDrawerOpen}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Notification Settings">
          <Row noGutters xs={1} sm={1} md={2}>
            <Col className="mb-4">
              <ProfileNotificationPreferences />
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default ProfileMobile;
