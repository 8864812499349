import React from "react";
//import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import "./scss/dashboard.css";
import { useQuery } from "@apollo/react-hooks";
import { LOCAL_APP_STATE } from "../../localState/queries";
import DashboardMobile from "./DashboardMobile";
import DashboardDesktop from "./DashboardDesktop";

export default function Dashboard() {
  const { data } = useQuery(LOCAL_APP_STATE);

  return (
    <>
      {data.localAppState.isMobile && <DashboardMobile />}
      {!data.localAppState.isMobile && <DashboardDesktop />}
    </>
  );
}
