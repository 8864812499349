const INITIAL_STATE = {
  __typename: "LOCAL_APP_STATE",
  id: 1,
  navigationToggled: false,
  isMobile: false,
  cardType: '',
  userData: {
    //applicationId: 750516,
    applicationId: 0,
    paymentAmount: '',
    //zipOnFile: '93304',
    zipOnFile: '',
    address: {
      street: '',
      city: '',
      statoid: '',
      zip: '',
      countryCode: 'US'
    },
    card: {
      nameOnCard: '',
      cardNumber: '',
      expirationMonth: 0,
      expirationYear: '',
      securityCode: '',
      zip: ''
    },
    phone: '',
    email: '',
    savePayment: false
  }
};

const getLocalAppState = () => {
  let appStateFromLocalStorage = sessionStorage.getItem("appState");

  if (!appStateFromLocalStorage) {
    const newAppState = {...INITIAL_STATE};
    setLocalAppState(newAppState);
    return newAppState;
  } else {
    return JSON.parse(appStateFromLocalStorage);
  }
};

const getCleanAppState = () => {
  return {...INITIAL_STATE};
};

const setLocalAppState = (newAppState: any) => {
  sessionStorage.setItem("appState", JSON.stringify(newAppState));
};

const resolvers = {
  Query: {
    localAppState: () => {
      return getLocalAppState();
    },
  },
  Mutation: {
    resetModel: (_: any, data: any) => {
      const appState = getCleanAppState();
      appState.userData.applicationId = parseInt(data.applicationId) || 0;
      appState.userData.zipOnFile = data.zipOnFile;
      setLocalAppState(appState);

      if ((appState.userData.applicationId > 0) !== true) {
        sessionStorage.removeItem("user");
      }

      return appState;
    },
    updateNavigationToggled: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.navigationToggled = data.navigationToggled;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateIsMobileProperty: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.isMobile = data.isMobile;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserApplicationId: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.applicationId = parseInt(data.applicationId) || 0;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserZipCode: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.zipOnFile = data.zipCode;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentAmount: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.paymentAmount = data.paymentAmount;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentType: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.cardType = data.cardType;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserNameOnCard: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.card.nameOnCard = data.nameOnCard;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardNumber: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.card.cardNumber = data.cardNumber;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardMonth: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.card.expirationMonth = data.expirationMonth;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardYear: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.card.expirationYear = data.expirationYear;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardCode: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.card.securityCode = data.securityCode;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardAddressOne: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.street = data.addressOne;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardAddressTwo: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.street += data.addressTwo;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardCity: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.city = data.cardCity;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardState: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.statoid = data.cardState;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardCountry: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.countryCode = data.cardCountry;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateUserPaymentCardZip: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.address.zip = data.cardZip;
      appStateFromLocalStorage.userData.card.zip = data.cardZip;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    },
    updateSavePayment: (_: any, data: any) => {
      let appStateFromLocalStorage = getLocalAppState();
      appStateFromLocalStorage.userData.savePayment = data.savePayment;
      setLocalAppState(appStateFromLocalStorage);
      return appStateFromLocalStorage;
    }
  }
};

export default resolvers;
