// @ts-ignore
import React from "react";
import Cardstyle from "./card.style";

type CardProps = {
  title?: string;
  children: React.ReactNode;
};

export default ({ title, children }: CardProps) => (
  <Cardstyle>
    <div className="card">
      {title ? (
        <div className="card-head">
          <p className="card-title hfd-primary2">{title}</p>
        </div>
      ) : null}
      <div className="hfd-card-body">
        <div className="hfd-card-body-child">{children ? children : null}</div>
      </div>
    </div>
  </Cardstyle>
);
