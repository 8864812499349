import axios from 'axios';
import { Config } from '../ClientConfig';

interface BinValidation {
	valid: boolean;
	message: string;
}

const serviceUrl = `${Config.Integrations.StandardizedAPI}payment`;

class ClientApiService {

	validateCardBin = async (appId: string, cardNumber: string): Promise<BinValidation> => {

        const bin = cardNumber.replace(" ", "").slice(0, 6);
		try {
			var response = await axios.get<BinValidation>(`${serviceUrl}/bin-lookup`, { params: { appId: appId, bin: bin } });
			return response.data;
		} catch (err) {
			const errorResponse = err.response;
			if (!errorResponse) {
				throw err;
			}
			var returnObject = {
				valid: false,
				message: '',
			};
			if (errorResponse.status >= 400 && errorResponse.status < 500) {
				returnObject.message = errorResponse.data.message;
			} else if (errorResponse.status >= 500) {
				returnObject.message = 'Internal Server Error has been logged. Please try again later';
			}
			return returnObject;
		}
	};
}

export default ClientApiService;
