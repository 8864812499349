import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCheck } from "@fortawesome/free-solid-svg-icons";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import FormattedIntlDate from "@hfd/components/utility/FormattedIntlDate";

const PaymentScheduleEntry = (props: any) => {
  return (
    <div className="payment-schedule-entry">
      <div className={`pse-icon ${props.index === 0 ? "no-line" : ""}`}>
        {props.index === 0 && (
          <div className="circle-wrapper scheduled">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faClock} />
            </div>
          </div>
        )}
        {props.index !== 0 && (
          <div className="circle-wrapper paid">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </div>
        )}
      </div>
      <div className="pse-entry">
        <div className="pse-left">
          {props.index === 0 ? (
            <p className="pse-title scheduled">Scheduled</p>
          ) : (
            <p className="pse-title">Automatic Payment</p>
          )}
          <p className="subtext">
            <FormattedIntlDate date={props.entry.eventDate} />
          </p>
        </div>
        <div
          className={props.index === 0 ? "pse-right scheduled" : "pse-right"}
        >
          <FormattedCurrency
            amount={props.entry.payment ? props.entry.payment : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentScheduleEntry;
