import React from "react";
import { IconProps } from "./IconInterface";
import img from "../../../assets/images/hfdicons/Past Due.svg";

const HFDPastDueWarning = (props: IconProps) => {
  return (
    <div className="hfd-icon">
      <img src={img} />
    </div>
  );
};

export default HFDPastDueWarning;
