import React from "react";
import Moment from "react-moment";
import { useAuthDataContext } from "../../../../authProvider";

function OneTimePaymentPromoBanner() {
  const { user } = useAuthDataContext();

  if (user.promotionPlanDate) {
    const formattedAmount = formatAmount(user.promotionPayOffAmount);
    const formattedFees = formatAmount(user.promotionFees);

    return (
      <div id="promoBanner" className="promo-banner bottom">
        Special promotional offer <br /> no interest if {formattedAmount[0]}
        <span className="decimal">.{formattedAmount[1]}</span>
        {user.promotionFees > 0 && (
          <>
            {" + "}
            {formattedFees[0]}
            <span className="decimal">.{formattedFees[1]}</span>
            {" fee(s) "}
          </>
        )}
        paid in full by{" "}
        <Moment format="M/DD/YYYY">{user.promotionPlanDate}</Moment>
        <br />
        <span className="disclaimer">
          * Please allow 48 hours for your payment to be
          <br />
          processed and your account to be updated.
        </span>
      </div>
    );
  } else {
    return <></>;
  }
}

function formatAmount(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(amount)
    .split(".");
}

export default OneTimePaymentPromoBanner;
