import React from "react";
import { Row, Col } from "react-bootstrap";
import FormattedCurrency from "@hfd/components/utility/FormattedCurrency";
import OrdinalSuffix from "@hfd/components/utility/OrdinalSuffix";

const DashboardOverviewInformation = (props: any) => {
  const financials = props.financials;

  const getNumberOfPaymentsRemaining = () => {
    let remaining = 0;
    for (
      let index = 0;
      index < financials.amortizationActivity.length;
      index++
    ) {
      if (financials.amortizationActivity[index].ledgerActivity === "") {
        remaining = remaining + 1;
      }
    }
    return remaining;
  };

  return (
    <div>
      <p className="mb-2 px-2 py-1 overview-title">
        {financials.paymentPlanName}
      </p>
      <div style={{ fontSize: "0.9rem" }}>
        <Row noGutters md={2} className="mb-2 px-2">
          <Col>
            <p className="overview-category-name">Monthly Payment</p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>
              <FormattedCurrency amount={financials.nextPayment} />
            </p>
          </Col>
        </Row>
        <Row noGutters md={2} className="mb-2 px-2 paid-to-date">
          <Col>
            <p>Paid To Date</p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>
              <FormattedCurrency amount={financials.totalPaymentsToDate} />
            </p>
          </Col>
        </Row>
        <Row noGutters md={2} className="mb-2 px-2 payoff-amount">
          <Col>
            <p>Amount Financed</p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>
              <FormattedCurrency amount={financials.totalAmountOwed} />
            </p>
          </Col>
        </Row>
        <Row noGutters md={2} className="mb-2 px-2">
          <Col>
            <p className="overview-category-name">Payment Date</p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>
              <OrdinalSuffix numberToFormat={financials.dayOfPayment} />
            </p>
          </Col>
        </Row>
        <Row noGutters md={2} className="mb-2 px-2">
          <Col>
            <p className="overview-category-name">Payments Remaining</p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>{getNumberOfPaymentsRemaining()}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardOverviewInformation;
