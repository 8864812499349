import React from "react";

interface IProps {
  long: Boolean;
  number: Boolean;
  capital: Boolean;
  symbols: Boolean;
}

interface reqProps {
  htmlFor: string;
  isValid: Boolean;
  message: String;
}

const Requirement = ({ htmlFor, isValid, message }: reqProps) => (
  <label
    htmlFor={htmlFor}
    className={
      isValid
        ? "requirement requirement-valid"
        : "requirement requirement-invalid"
    }
  >
    {isValid ? (
      <ion-icon name="checkmark"></ion-icon>
    ) : (
      <ion-icon name="close"></ion-icon>
    )}
    <span className="requirement-message">{message}</span>
  </label>
);

const ProfilePasswordRequirement = ({
  long,
  number,
  capital,
  symbols,
}: IProps) => {
  return (
    <section className="requirement-section">
      <h6>Your new password must have:</h6>
      <div className="d-flex flex-column">
        <Requirement
          htmlFor="newPassword"
          isValid={long}
          message="8 to 25 characters"
        />
        <Requirement
          htmlFor="newPassword"
          isValid={capital}
          message="Atleast 1 capitalized letter"
        />
        <Requirement
          htmlFor="newPassword"
          isValid={number}
          message="Atleast one number"
        />
        <Requirement
          htmlFor="newPassword"
          isValid={symbols}
          message="Atleast one symbol (!#$%&*?)"
        />
      </div>
    </section>
  );
};

export default ProfilePasswordRequirement;
