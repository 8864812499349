// @ts-ignore
import React, { MouseEventHandler } from "react";
import "./ToggleSwitch.css";

interface Props {
  id: string;
  name: string;
  checked: boolean;
  onToggleChange: MouseEventHandler;
}

const ToggleSwitch = ({ id, name, checked, onToggleChange }: Props) => {
  return (
    <div className="toggle-switch" onClick={(evt: any) => onToggleChange(evt)}>
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={() => {}}
      />
      <label className="toggle-switch-label" htmlFor={name}>
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

export default ToggleSwitch;
