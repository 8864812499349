import React from "react";
import { Card, Button } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";

const PaymentResultWidgetMobile = (props: any) => {
  const success = true;

  const getCardBody = () => {
    if (success) {
      return (
        <>
          <div className="payment-status-header">
            <FontAwesomeIcon icon={faCheckCircle} />
            <IntlMessages id="makePayment.Success" />
          </div>
          <div className="payment-status-body container">
            <h2 className="body-title success">
              <IntlMessages id="makePayment.ThanksForPayment" />
            </h2>
            <p className="body-subtitle">
              <IntlMessages id="makePayment.Receipt" />
            </p>
            <div className="body-image">
              <HFDLogoWithText color="black" size="medium" />
            </div>
          </div>
          <div className="body-details">
            <ul>
              <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.HFDAccount" />
                </span>
                <span className="right-side">Mikasa Ackerman</span>
              </li>
              <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.IDNumber" />
                </span>
                <span className="right-side">3085598</span>
              </li>
              <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.Date" />
                </span>
                <span className="right-side">Dec 19th, 2021</span>
              </li>
              <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.TotalPayment" />
                </span>
                <span className="right-side">$80</span>
              </li>
              <li>
                <span className="left-side">
                  <IntlMessages id="makePayment.PaymentMethod" />
                </span>
                <span className="right-side">Amex ending in 7777</span>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="payment-status-header failure">
            <FontAwesomeIcon icon={faTimesCircle} />
            <IntlMessages id="makePayment.Failure" />
          </div>
          <div className="payment-status-body container failure">
            <h2 className="body-title failure">
              <IntlMessages id="makePayment.UhOh" />
            </h2>
            <h2 className="body-title failure">
              <IntlMessages id="makePayment.ProblemWithPayment" />
            </h2>
            <div className="body-details">
              <p>
                <IntlMessages id="makePayment.PleaseSelectAnotherPaymentMethod" />
              </p>
              <Button className="white-with-blue">
                <IntlMessages id="makePayment.BackToPaymentMethod" />
              </Button>
              <div className="spacer"></div>
              <p>
                <IntlMessages id="makePayment.ProblemContinues" /> <br />
                <IntlMessages id="makePayment.PhoneNumber" />
              </p>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Card
        className={
          success
            ? "hfd-widget-card pcf pcf-success"
            : "hfd-widget-card pcf pcf-failure"
        }
      >
        {getCardBody()}
      </Card>
    </>
  );
};

export default PaymentResultWidgetMobile;
