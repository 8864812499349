import React, { useState } from "react";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import OneTimePaymentInfo from "./OneTimePaymentInfo";
import OneTimePaymentAccountDetails from "./OneTimePaymentAccountDetails";
import OneTimePaymentCreditCardForm from "./OneTimePaymentCreditCardForm";

const OneTimePaymentMethodMobile = (props: any) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return isSubmitting ? (
    <div className="loader">
      <br/>
      <HFDBarLoader size="full" />
    </div>
  ) : (
    <div className="my-3">
      <br/>
      <div className="px-2">
        <OneTimePaymentAccountDetails cancel={props.cancelClicked} />
      </div>

      <OneTimePaymentCreditCardForm
        setSubmitting={setSubmitting}
        continueClicked={props.continueClicked}
        cancelClearClicked={props.cancelClearClicked}
      />
      <OneTimePaymentInfo current={true} makeOTP={true} contact={true} />
    </div>
  );
};

export default OneTimePaymentMethodMobile;
