import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";

const Splash = () => {
  const [loading, setLoading] = useState(true);
  const user = { email: "bryan@bryan.com" };

  useEffect(() => {
    async function checkMyAuthStatus() {
      setLoading(false);
    }

    checkMyAuthStatus();
  }, []);
  if (loading) {
    return <div>Loading</div>;
  }

  if (user) {
    return (
      <Redirect
        to={{
          pathname: `/user/dashboard`,
          state: { from: "Splash" },
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: "Splash" },
        }}
      />
    );
  }
};

export default Splash;
