import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import React from "react";
import {useHistory} from "react-router";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import "../styles/navigation/mobileNavBar.css";
import { Config } from '../../ClientConfig';

const OTPMobileNavBar = (props: any) => {
  let history = useHistory();

  const goBack = () => {
    if (props.currentStep === 0) {
      if (props.customGoBack) {
        props.customGoBack();
      } else {
        history.goBack();
      }
    }
    else {
      props.cancelClicked();
    }
  };
  const goHome = () => {
    window.location.href = Config.Integrations.CustomerPortalUrl;
  };

  return (
      <div className="mobile-box mobile-nav">
        <div className="mobile-box mobile-back" onClick={goBack}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </div>
        <div className="mobile-logo" onClick={goHome}>
          <HFDLogoWithText color="white" size="small"/>
        </div>

        <div className="mobile-box mobile-menu-toggle"/>
      </div>
  );
};

export default OTPMobileNavBar;
