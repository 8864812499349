import React from "react";
// @ts-ignore
import { FormattedDate } from "react-intl";

interface FormattedIntlDate {
  date: string;
}

const FormattedIntlDate = (props: FormattedIntlDate) => {
  return (
    <FormattedDate
      value={props.date}
      day="numeric"
      month="short"
      year="numeric"
    />
  );
};

export default FormattedIntlDate;
