/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { MouseEventHandler, useState } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import ProfilePasswordRequirement from "./ProfilePasswordRequirements";
import ProfilePasswordError from "./ProfilePassworError";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";

interface IProps {
  handleDrawer: MouseEventHandler;
  setHasNewPassword: Function;
}

const sleep = (ms: Number) => new Promise((r) => setTimeout(r, ms));

const ProfileUpdatePassword = ({ handleDrawer, setHasNewPassword }: IProps) => {
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);
  const [capital, hasCapital] = useState(false);
  const [symbols, hasSymbols] = useState(false);
  const [isCurrentPasswordValid, setCurrentPassword] = useState(false);
  const [isConfirmPasswordValid, setConfirmPassword] = useState(false);
  const [isNewPasswordValid, setNewPassword] = useState(false);
  const [errors, setErrors] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const doSubmit = async ({ values }: any) => {
    setErrors("");
    if (!isConfirmPasswordValid) {
      return setErrors("Please confirm New Password.");
    }
    if (!isNewPasswordValid) {
      return setErrors("Oops! New password is missing requirements.");
    }
    if (!isCurrentPasswordValid) {
      return setErrors("Oops! Current password is incorrect.");
    }
    if (!isNewPasswordValid && !isCurrentPasswordValid) {
      return setErrors(
        "Oops! Current password is incorrect and New password is missing requirements."
      );
    }

    if (errors.length === 0) {
      setSubmitting(true);
      await sleep(500);
      setSubmitting(false);
      return setHasNewPassword(true);
    }
  };

  const checkLength = (newPasswordLength: Number) => {
    return newPasswordLength >= 8;
  };

  const checkHasNumber = (newPassword: string) => {
    return /\d/.test(newPassword);
  };

  const checkHasCapital = (newPassword: string) => {
    return /[A-Z]/g.test(newPassword);
  };

  const checkHasSymbol = (newPassword: string) => {
    return /[!#$%&*?]/.test(newPassword);
  };

  const checkCurrentPassword = (currentPassword: String) => {
    const currentPasswordMock = "test123";
    return currentPassword === currentPasswordMock;
  };

  const checkNewPassword = (values: any) => {
    const requirements =
      checkLength(values.newPassword.length) &&
      checkHasNumber(values.newPassword) &&
      checkHasCapital(values.newPassword) &&
      checkHasSymbol(values.newPassword);
    setNewPassword(requirements);
  };
  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validate={async (values) => {
        // current password
        checkCurrentPassword(values.currentPassword)
          ? setCurrentPassword(true)
          : setCurrentPassword(false);
        // new password
        checkLength(values.newPassword.length)
          ? longEnough(true)
          : longEnough(false);
        checkHasNumber(values.newPassword) ? hasNumber(true) : hasNumber(false);
        checkHasCapital(values.newPassword)
          ? hasCapital(true)
          : hasCapital(false);
        checkHasSymbol(values.newPassword)
          ? hasSymbols(true)
          : hasSymbols(false);
        // confirm new password
        values.confirmNewPassword.length > 0 &&
        values.confirmNewPassword === values.newPassword
          ? setConfirmPassword(true)
          : setConfirmPassword(false);
        checkNewPassword(values);
      }}
      onSubmit={async (values, { resetForm }) => {
        await doSubmit(values);
      }}
    >
      {({ values, handleSubmit, handleChange }) =>
        isSubmitting ? (
          <div className="loader">
            <HFDBarLoader size="full" />
          </div>
        ) : (
          <Form
            noValidate
            id="updatePassword"
            className="px-3 pt-3"
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="currentPassword">
              <Form.Label className="profile-password-label">
                Current Password
              </Form.Label>
              <Form.Control
                type="password"
                className="profile-password"
                onChange={handleChange}
                value={values.currentPassword}
                placeholder="Enter Current Password"
                isValid={isCurrentPasswordValid}
                isInvalid={!isCurrentPasswordValid}
                required
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label className="profile-password-label">
                New Password
              </Form.Label>
              <Form.Control
                type="password"
                className="profile-password"
                onChange={handleChange}
                value={values.newPassword}
                placeholder="Enter New Password"
                required
                isValid={isNewPasswordValid}
                isInvalid={!isNewPasswordValid}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label className="profile-password-label">
                Confirm New Password
              </Form.Label>
              <Form.Control
                type="password"
                className="profile-password"
                onChange={handleChange}
                value={values.confirmNewPassword}
                placeholder="Let's make sure we got it right"
                required
                isValid={isConfirmPasswordValid}
                isInvalid={!isConfirmPasswordValid}
              />
            </Form.Group>
            <ProfilePasswordRequirement
              long={long}
              number={number}
              capital={capital}
              symbols={symbols}
            />
            {errors && (
              <ProfilePasswordError errorMessage={errors} small={false} />
            )}
            <button
              type="submit"
              value="Save"
              disabled={isSubmitting}
              className="profile-save-password"
            >
              Save
            </button>
            <button
              type="button"
              value="Cancel"
              className="profile-cancel-password"
              onClick={handleDrawer}
            >
              Cancel
            </button>
          </Form>
        )
      }
    </Formik>
  );
};

export default ProfileUpdatePassword;
