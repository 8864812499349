import React from "react";
import Root from "./root";
import Routes from "./router";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

const App = () => (
  <Root>
    <>
      <Routes />
    </>
  </Root>
);

export default App;
