import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import HFDBarLoader from "@hfd/components/utility/HFDBarLoader";
import OneTimePaymentInfo from "./OneTimePaymentInfo";
import HfdDropdownCard from "@hfd/components/DropdownCard/HfdDropdownCard";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import OneTimePaymentAddressForm from "./OneTimePaymentAddressForm";
import {LOCAL_APP_STATE} from "../../../../localState/queries";

const OneTimePaymentDetailsMobile = (props: any) => {
  const {data} = useQuery(LOCAL_APP_STATE);

  const [isSubmitting, setSubmitting] = useState(false);

  return isSubmitting ? (
      <div className="loader">
        <br/>
        <HFDBarLoader size="full"/>
      </div>
  ) : (
      <>
        <br/>
        <div className="mt-3 px-2">
          <HfdDropdownCard status={"current"}>
            <div className="ddc-body-details">
              <ul>
                <li>
                <span className="left-side">
                  <IntlMessages id="otp.PaymentAmount"/>
                </span>
                  <span className="right-side success-right">${data.localAppState.userData.paymentAmount}</span>
                </li>
              </ul>
            </div>
          </HfdDropdownCard>
        </div>
        <OneTimePaymentAddressForm
          setSubmitting={setSubmitting}
          cancelClicked={props.cancelClicked}
          continueClicked={props.continueClicked}
        />
        <OneTimePaymentInfo current={true} makeOTP={true} contact={true}/>
      </>
  );
};

export default OneTimePaymentDetailsMobile;
