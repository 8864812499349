import React, { useState } from "react";
import PaymentPlanDetailsMobile from "./Components/PaymentPlanDetailsMobile";
import MobileHeader from "../../../components/mobile/MobileHeader";
import {
  faDollarSign,
  faChartLine,
  faFileAlt,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";

import "./scss/PaymentPlanMobile.css";

const PaymentPlanMobile = ({ financials, user }: any) => {
  const [dataScroll, setDataScroll] = useState(0);
  document.addEventListener("scroll", () => setDataScroll(window.scrollY));

  const links = [
    {
      icon: faDollarSign,
      iconLabel: "Make Payment",
      link: "/user/makeapayment",
    },
    {
      icon: faChartLine,
      iconLabel: "Payment Activity",
      link: "/user/paymentplan/activity",
    },
    {
      icon: faFileAlt,
      iconLabel: "View Documents",
      link: "/user/paymentplan/document",
    },
    {
      icon: faPencilAlt,
      iconLabel: "Edit Plan",
      link: "/user/paymentplan/edit",
    },
  ];

  return (
    <>
      <MobileHeader
        link={links}
        title={"paymentPlanMobile.Details"}
        titleDescription={"paymentPlanMobile.Subtitle"}
      />
      <div
        className={
          dataScroll
            ? "payment-plan-mobile-container scrolled"
            : "payment-plan-mobile-container"
        }
      >
        <PaymentPlanDetailsMobile financials={financials} user={user} />
      </div>
    </>
  );
};

export default PaymentPlanMobile;
