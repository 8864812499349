import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import Card from "@hfd/components/utility/card";

const SettingsSecurity = () => {
  return (
    <Card>
      <Row noGutters md={3}>
        <Col
          md={2}
          className="d-flex align-items-center justify-content-center"
        >
          <Image
            src="https://global-content-cf.s3-us-west-2.amazonaws.com/HFD.Customer/images/Badge.png"
            width="60px"
            fluid
          />
        </Col>
        <Col md={8}>
          <p className="hfd-primary2 mb-2">
            <IntlMessages id="settings.Security" />
          </p>
          <p className="mb-2">
            <IntlMessages id="settings.SecurityDescription" />
          </p>
          <button type="button" className="hfd-text-button">
            <IntlMessages id="settings.LearnMore" />
          </button>
        </Col>
      </Row>
    </Card>
  );
};

export default SettingsSecurity;
