import { useQuery, useMutation } from "@apollo/react-hooks";
import React, { useEffect } from "react";
import {
  LOCAL_APP_STATE,
  UPDATE_ISMOBILEPROPERTY,
} from "../localState/queries";
import MobileFooter from "../components/mobile/MobileFooter";

export default (props: any) => {
  const { loading, data } = useQuery(LOCAL_APP_STATE);
  const [toggleIsMobile] = useMutation(UPDATE_ISMOBILEPROPERTY);

  const breakpoint = 620;

  useEffect(() => {
    // initial load
    const windowWidth = window.innerWidth;
    toggleIsMobile({
      variables: { isMobile: windowWidth <= breakpoint },
      refetchQueries: [{ query: LOCAL_APP_STATE }],
    });
  }, [toggleIsMobile, data]);

  if (loading) {
    return <div>Calculating Browser Size</div>;
  }

  return (
      <>
        <div
            className={
              data.localAppState.isMobile ? "mobile-wrapper" : "desktop-wrapper"
            }
        >
          {props.children}
        </div>
        {data.localAppState.isMobile ? <MobileFooter /> : null}
      </>
  );
};
