import React from "react";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import { FormattedNumber, FormattedDate } from "react-intl";

const PaymentStatusWidget = (props: any) => {
  const { financials } = props;
  console.log(financials);
  if (!financials) {
    return <div></div>;
  }
  const PaymentStatusCardHeader = ({ ...options }) => {
    if (financials.paymentStatus !== "ACT") {
      return (
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <IntlMessages id="makePayment.PaymentStatusPastDue" />
        </div>
      );
    } else if (financials.paymentStatus === "PCOL") {
      return (
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <IntlMessages id="makePayment.PaymentStatusActive" />
        </div>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={faCheckCircle} />
          <IntlMessages id="makePayment.PaymentStatusActive" />
        </div>
      );
    }
  };

  return (
    <Card
      className={
        financials.paymentStatus !== "ACT"
          ? financials.paymentStatus === "PCOL"
            ? "hfd-widget-card past-due danger"
            : "hfd-widget-card past-due"
          : "hfd-widget-card current"
      }
    >
      <div className="payment-status-header">
        <PaymentStatusCardHeader />
      </div>
      <div className="payment-status-body">
        <ul className="payment-status-listing">
          {financials.paymentStatus !== "ACT" && (
            <>
              <li className="warning">
                <span className="listing-title">Past Due Amount</span>
                <span className="listing-value">
                  $
                  <FormattedNumber
                    value={financials.currentAmountPastDue}
                    //style={"currency"}
                    currency="USD"
                  />
                </span>
              </li>
              <li className="warning">
                <span className="listing-title">Additional Fees</span>
                <span>
                  {" "}
                  <FormattedNumber
                    value={financials.feeBalance}
                    //style={"currency"}
                    currency="USD"
                  />
                </span>
              </li>
              <li className="danger">
                <span className="listing-title">Total Amount Due</span>
                <span className="listing-value">
                  {" "}
                  <FormattedNumber
                    value={financials.currentAmountPastDue}
                    //style={"currency"}
                    currency="USD"
                  />
                </span>
              </li>
              <li className="divider"></li>
            </>
          )}
          {financials.paymentStatus === "ACT" && (
            <>
              <li>
                <span className="listing-title">Total Amount Due</span>
                <span className="listing-value">
                  $
                  <FormattedNumber
                    value={0}
                    //style={"currency"}
                    currency="USD"
                  />
                </span>
              </li>
            </>
          )}
          <li>
            <span className="listing-title">Next Payment Due</span>
            <span className="listing-value">Jan 15th</span>
          </li>
          <li>
            <span className="listing-title">Monthly Payment</span>
            <span className="listing-value">
              <FormattedDate value={80} day="numeric" month="short" />
            </span>
          </li>
          <li className="divider"></li>
          <li>
            <span className="listing-title">Amount Financed</span>
            <span className="listing-value">
              $
              <FormattedNumber
                value={financials.totalAmountOwed}
                //style={"currency"}
                currency="USD"
              />
            </span>
          </li>
          <li>
            <span className="listing-title">Paid To Date</span>
            <span className="listing-value">
              $
              <FormattedNumber
                value={financials.totalPaymentsToDate}
                //style={"currency"}
                currency="USD"
              />
            </span>
          </li>
          <li>
            <span className="listing-title">Total Payoff Amount</span>
            <span className="listing-value">
              $
              <FormattedNumber
                value={financials.payoffAmount}
                //style={"currency"}
                currency="USD"
              />
            </span>
          </li>
          <li>
            <span className="listing-title">Principal Balance</span>
            <span className="listing-value">
              $
              <FormattedNumber
                value={financials.principalBalance}
                //style={"currency"}
                currency="USD"
              />
            </span>
          </li>
          <li>
            <span className="listing-title">Payments Remaining</span>
            <span className="listing-value">9</span>
          </li>
          <li>
            <span className="listing-title">Estimated Payoff Date</span>
            <span className="listing-value">07/15/2021</span>
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default PaymentStatusWidget;
