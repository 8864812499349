import React from "react";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";

import "./scss/Profile.css";
import { useAuthDataContext } from "../../../authProvider";
import {LOCAL_APP_STATE} from "../../../localState/queries";

import ProfileMobile from "./ProfileMobile";
import ProfileDesktop from "./ProfileDesktop";

const Profile = () => {
  const { data } = useQuery(LOCAL_APP_STATE);
  const { user } = useAuthDataContext().user;

  let history = useHistory();
  const goHome = () => {
    history.push("/user/dashboard");
  };

  const backButtonClicked = () => {
    goHome();
  };

  return data.localAppState.isMobile ? (
      <ProfileMobile user={user} backButtonClicked={backButtonClicked} />
  ) : (
      <ProfileDesktop user={user} />
  );
};

export default Profile;
