import React, {ChangeEvent, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import OneTimePaymentMobile from "./OneTimePaymentMobile";
import OneTimePaymentDesktop from "./OneTimePaymentDesktop";
import {useQuery, useMutation} from "@apollo/react-hooks";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import {LOCAL_APP_STATE, RESET_MODEL} from "../../../localState/queries";

import OneTimePaymentMethodMobile from "./components/OneTimePaymentMethodMobile";
import OneTimePaymentMethodDesktop from "./components/OneTimePaymentMethodDesktop";

import OneTimePaymentDetailsMobile from "./components/OneTimePaymentDetailsMobile";
import OneTimePaymentDetailsDesktop from "./components/OneTimePaymentDetailsDesktop";

import OneTimePaymentConfirmMobile from "./components/OneTimePaymentConfirmMobile";
import OneTimePaymentConfirmDesktop from "./components/OneTimePaymentConfirmDesktop";

import OneTimePaymentResultsMobile from "./components/OneTimePaymentResultsMobile";
import OneTimePaymentResultsDesktop from "./components/OneTimePaymentResultsDesktop";

import OneTimePaymentAccountDetailsMobile from "./components/OneTimePaymentAccountDetailsMobile";
import OneTimePaymentAccountDetailsDesktop from "./components/OneTimePaymentAccountDetailsDesktop";
import {useAuthDataContext} from "../../../authProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const OneTimePayment = () => {
  const { user, onOtpLogin, onForgotAppId, onSendPayment } = useAuthDataContext();
  const { data } = useQuery(LOCAL_APP_STATE);
  const [resetModel] = useMutation(RESET_MODEL);
  
  const isMobile = data.localAppState.isMobile;
  const [currentStep, setCurrentStep] = useState(0);
  const [showAppIdModal, setShowAppIdModal] = useState(false);
  const [showAppIdConfirmModal, setShowAppIdConfirmModal] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [getAppIdPhone, setGetAppIdPhone] = useState("");
  const [getAppIdEmail, setGetAppIdEmail] = useState("");
  const [showAppIdError, setShowAppIdError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isBypassing, setBypassing] = useState(false);
  const [errors, setErrors] = useState("");
  const [paymentResponse, setPaymentResponse] = useState({});

  const activateStep = (stepNumber: any) => {
    setCurrentStep(stepNumber);
  };

  const cancelClearClicked = async () => {
    resetModel({
      variables: {},
      refetchQueries: [{query: LOCAL_APP_STATE}]
    });

    await sleep(300);

    cancelClicked();
  };

  const cancelClicked = () => {
    activateStep(currentStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const submitClicked = async (values: any) => {
    setSubmitting(true);
    onOtpLogin(
        {
          appId: values.applicationId,
          zipCode: values.zipcode,
        },
        (success: boolean) => {
          if (success) {
            activateStep(currentStep + 1);
            setCurrentStep(currentStep + 1);
          } else {
            setBypassing(false);
            setErrors("Oops! Application ID and Zip Code do not match");
          }
          setSubmitting(false);
        }
    );
  };

  const sendPayment = async () => {
    setSubmitting(true);
    const paymentData = {...data.localAppState.userData};
    paymentData.paymentAmount = parseFloat(paymentData.paymentAmount);

    paymentData.email = user.email;

    const cardData = {...paymentData.card};
    cardData.expirationMonth = parseInt(paymentData.card.expirationMonth);
    cardData.expirationYear = parseInt(paymentData.card.expirationYear);

    paymentData.card = cardData;

    onSendPayment(paymentData, (response: any) => {
      setPaymentResponse(response);
      activateStep(currentStep + 1);
      setCurrentStep(currentStep + 1);
      setSubmitting(false);
    });
  };

  const continueClicked = (values: any) => {
    activateStep(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const backtoStepOne = () => {
    activateStep(0);
    setCurrentStep(0);
  };

  const backtoStepTwo = () => {
    activateStep(1);
    setCurrentStep(1);
  };

  const getCurrentStep = () => {
    if (currentStep === 0) {
      if (isMobile) {
        return (
            <OneTimePaymentAccountDetailsMobile
                submitClicked={submitClicked}
                cancelClicked={cancelClicked}
                handleOpenAppId={handleOpenAppId}
                errors={errors}
                setErrors={setErrors}
                setBypassing={setBypassing}
                isSubmitting={isSubmitting}
                isBypassing={isBypassing}
            />
        );
      } else {
        return (
            <OneTimePaymentAccountDetailsDesktop
                submitClicked={submitClicked}
                cancelClicked={cancelClicked}
                handleOpenAppId={handleOpenAppId}
                errors={errors}
                setErrors={setErrors}
                setBypassing={setBypassing}
                isSubmitting={isSubmitting}
                isBypassing={isBypassing}
            />
        );
      }
    } else if (currentStep === 1) {
      if (isMobile) {
        return (
            <OneTimePaymentMethodMobile
                continueClicked={continueClicked}
                cancelClearClicked={cancelClearClicked}
            />
        );
      } else {
        return (
            <OneTimePaymentMethodDesktop
                continueClicked={continueClicked}
                cancelClearClicked={cancelClearClicked}
            />
        );
      }
    } else if (currentStep === 2) {
      if (isMobile) {
        return (
            <OneTimePaymentDetailsMobile
                continueClicked={continueClicked}
                cancelClicked={cancelClicked}
            />
        );
      } else {
        return (
            <OneTimePaymentDetailsDesktop
                continueClicked={continueClicked}
                cancelClicked={cancelClicked}
            />
        );
      }
    } else if (currentStep === 3) {
      if (isMobile) {
        return (
            <OneTimePaymentConfirmMobile
                sendPayment={sendPayment}
                cancelClicked={cancelClicked}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
            />
        );
      } else {
        return (
            <OneTimePaymentConfirmDesktop
                sendPayment={sendPayment}
                cancelClicked={cancelClicked}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
            />
        );
      }
    } else if (currentStep === 4) {
      if (isMobile) {
        return (
            <OneTimePaymentResultsMobile
                continueClicked={backtoStepOne}
                cancelClicked={cancelClicked}
                backtoStepTwo={backtoStepTwo}
                paymentResponse={paymentResponse}
            />
        );
      } else {
        return (
            <OneTimePaymentResultsDesktop
                continueClicked={backtoStepOne}
                cancelClicked={cancelClicked}
                backtoStepTwo={backtoStepTwo}
                paymentResponse={paymentResponse}
            />
        );
      }
    }
  };

  const getPaymentSteps = () => {
    return [
      {
        step: 0,
        stepName: (
            <>
              <IntlMessages id="stepBar.EnterCredentials1"/>
              <IntlMessages id="stepBar.EnterCredentials2"/>
            </>
        ),
        completed: currentStep > 0,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 0,
        isCurrentStep: currentStep === 0,
      },
      {
        step: 1,
        stepName: <IntlMessages id="otp.AmountAndPaymentDetails"/>,
        completed: currentStep > 1,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 1,
        isCurrentStep: currentStep === 1,
      },
      {
        step: 2,
        stepName: <IntlMessages id="otp.PaymentMethodAddress"/>,
        completed: currentStep > 2,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 2,
        isCurrentStep: currentStep === 2,
      },
      {
        step: 3,
        stepName: <IntlMessages id="stepBar.Confirm"/>,
        completed: false,
        isActive: true,
        onActivateStep: activateStep,
        enabled: currentStep > 3,
        isCurrentStep: currentStep === 3,
      },
    ];
  };

  const getMobileStepText = () => {
    switch (currentStep) {
      case 0:
        return (
            <div className="page-description mobile">
              <span className="blue-text step-text">Step 1</span>
              <IntlMessages id="otp.StepOneDescription"/>
            </div>
        );
      case 1:
        return (
            <div className="page-description mobile">
              <span className="blue-text step-text">Step 2</span>
              <IntlMessages id="otp.EnterAmountPaymentDetails"/>
            </div>
        );
      case 2:
        return (
            <div className="page-description mobile">
              <span className="blue-text step-text">Step 3</span>
              <IntlMessages id="otp.EnterPaymentMethodAddress"/>
            </div>
        );
      case 3:
        return (
            <div className="page-description mobile">
              <span className="blue-text step-text">Step 4</span>
              <IntlMessages id="otp.StepFourDescription"/>
            </div>
        );
      default:
        return <div>Hello</div>;
    }
  };

  const handleCancelAppId = () => {
    setShowAppIdModal(false);
  };

  const handleCloseAppId = () => {
    if (showEmail && getAppIdEmail) {
      onForgotAppId(
          {
            contact: getAppIdEmail,
            type: 1,
          },
          () => {
            setShowAppIdModal(false);
            setShowAppIdConfirmModal(true);
          }
      );
    }

    if (showPhone && getAppIdPhone) {
      onForgotAppId(
          {
            contact: getAppIdPhone,
            type: 2,
          },
          () => {
            setShowAppIdModal(false);
            setShowAppIdConfirmModal(true);
          }
      );
    }

    if ((showEmail && !getAppIdEmail) || (showPhone && !getAppIdPhone)) {
      setShowAppIdError(true);
    }
  };

  const handleCloseAppIdConfirm = () => {
    setShowAppIdConfirmModal(false);
  };

  const handleOpenAppId = () => {
    setShowAppIdModal(true);
    setGetAppIdPhone("");
    setGetAppIdEmail("");
    setShowEmail(false);
    setShowPhone(false);
    setShowAppIdError(false);
  };

  const handleShowEmail = () => {
    setShowEmail(true);
    setShowPhone(false);
  };

  const handleShowText = () => {
    setShowEmail(false);
    setShowPhone(true);
  };

  const handleGetAppIdPhone = (event: ChangeEvent<HTMLInputElement>) => {
    setGetAppIdPhone(event.target.value);
  };

  const handleGetAppIdEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setGetAppIdEmail(event.target.value);
  };

  const renderGetAppId = () => {
    return (
        <Modal show={showAppIdModal} onHide={handleCloseAppId}>
          <Modal.Header>
            <IntlMessages id="otp.RequiredInfo"/>
          </Modal.Header>
          <Modal.Body>
            <div>
              <IntlMessages id="opt.ChooseaMethod"/>
            </div>
            <div className={"makepayment-getappid-container"}>
              <Button
                  className="makepayment-getappid-button"
                  onClick={handleShowText}
              >
                <div className={"icon mobilephone"}/>
                Text
              </Button>
              <Button
                  className="makepayment-getappid-button"
                  onClick={handleShowEmail}
              >
                <FontAwesomeIcon icon={faEnvelope}/>
                <br/>
                Email
              </Button>
            </div>

            {showAppIdError && (
                <div className={"getappid-error"}>
                  <IntlMessages id="opt.PhoneOrEmailRequired"/>
                </div>
            )}

            {showPhone && (
                <div className={"getappid-text"}>
                  <div>
                    <IntlMessages id="opt.EnterYourPhone"/>
                  </div>
                  <div>
                    <input
                        type="text"
                        value={getAppIdPhone}
                        placeholder={"(xxx) xxx-xxxx"}
                        onChange={handleGetAppIdPhone}
                    />
                  </div>
                </div>
            )}

            {showEmail && (
                <div className={"getappid-text"}>
                  <div>
                    <IntlMessages id="opt.EnterYourEmail"/>
                  </div>
                  <div>
                    <input
                        type="text"
                        value={getAppIdEmail}
                        placeholder={"example@domain.com"}
                        onChange={handleGetAppIdEmail}
                    />
                  </div>
                </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
                className="makepayment-cancel-button"
                onClick={handleCancelAppId}
            >
              <IntlMessages id="otp.Close"/>
            </Button>
            <Button
                className="makepayment-continue-button"
                onClick={handleCloseAppId}
            >
              <IntlMessages id="otp.GetMyApp"/>
            </Button>
          </Modal.Footer>
        </Modal>
    );
  };

  const renderGetAppIdConfirm = () => {
    return (
        <Modal show={showAppIdConfirmModal} onHide={handleCloseAppIdConfirm}>
          <Modal.Header>
            <IntlMessages id="payment.ResultsTextMessageSuccess"/>
          </Modal.Header>
          <Modal.Body>
            <div>
              <IntlMessages id="otp.Contacted"/>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
                className="makepayment-continue-button"
                onClick={handleCloseAppIdConfirm}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  };

  return isMobile ? (
      <>
        <OneTimePaymentMobile
            getPaymentSteps={getPaymentSteps}
            getMobileStepText={getMobileStepText}
            currentStep={currentStep}
            getCurrentStep={getCurrentStep}
            cancelClicked={cancelClicked}
            setSubmitting={setSubmitting}
            setBypassing={setBypassing}
            isSubmitting={isSubmitting}
            isBypassing={isBypassing}
        />
        {renderGetAppId()}
        {renderGetAppIdConfirm()}
      </>
  ) : (
      <>
        <OneTimePaymentDesktop
            getPaymentSteps={getPaymentSteps}
            getMobileStepText={getMobileStepText}
            currentStep={currentStep}
            getCurrentStep={getCurrentStep}
            cancelClicked={cancelClicked}
            setSubmitting={setSubmitting}
            setBypassing={setBypassing}
            isSubmitting={isSubmitting}
            isBypassing={isBypassing}
        />
        {renderGetAppId()}
        {renderGetAppIdConfirm()}
      </>
  );
};

export default OneTimePayment;
