import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import HFDLogoWithText from "@hfd/components/Logos/HFDLogoWithText";
import IntlMessages from "@hfd/components/utility/IntlMessages";
import HFDMobileButtonLink from "@hfd/components/Button/HfdMobileButtonLink";
import {
  faArrowRight,
  faDollarSign,
  faFileAlt,
  faComments,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SignInFormMobile from "./Components/SignInFormMobile";
import "./scss/SignInMobile.css";

const SignInMobile = () => {
  const [isFormActive, setFormActive] = useState(false);
  const history = useHistory();

  const toggleFormActive = () => {
    setFormActive((prevState) => !prevState);
  };

  const handleOTPClick = () => {
    history.push("/makeotp");
  };

  return (
    <Container className="sign-in-mobile-container">
      <Row>
        <Col className="mt-2 d-flex justify-content-center">
          {isFormActive ? (
            <button className="sign-in-back-button" onClick={toggleFormActive}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          ) : null}
          <HFDLogoWithText color={"white"} size={"large"} />
        </Col>
      </Row>
      {isFormActive ? (
        <SignInFormMobile />
      ) : (
        <>
          <Row>
            <Col className="mt-2">
              <h1>
                <IntlMessages id="signinMobile.Title" />
              </h1>
              <p>
                <IntlMessages id="signinMobile.SubTitle" />
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <HFDMobileButtonLink
                icon={faArrowRight}
                text="signinMobile.SignInButton"
                onClick={toggleFormActive}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <HFDMobileButtonLink
                icon={faDollarSign}
                text="signinMobile.MakeOTP"
                onClick={handleOTPClick}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <HFDMobileButtonLink
                icon={faFileAlt}
                text="signinMobile.CreateAccount"
                onClick={() => console.log("click")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <HFDMobileButtonLink
                icon={faComments}
                text="signinMobile.Support"
                onClick={() => console.log("click")}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default SignInMobile;
